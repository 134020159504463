import React, { FC } from 'react';
import classNames from 'classnames';
import { Officer, User } from 'src/types';
import { Tooltip } from '@tg/core/components';

interface Props {
  user: User | Officer;
  size?: 'small' | 'medium';
  subHeading?: string;
  email?: boolean;
  showName?: boolean;
}

const Avatar: FC<Props> = ({
  user,
  size = 'medium',
  email,
  subHeading,
  showName = true,
  roleValue,
}) => {
  const getName = () => {
    let sub = roleValue || user?.job_title || user?.email;
    if (subHeading) sub = subHeading;
    return (
      <div className='ml-3.5 min-w-0 leading-none'>
        <div className='whitespace-nowrap font-medium text-sm text-gray-900 truncate'>
          {email ? user.email : user?.full_name}
        </div>
        {sub && <div className='truncate text-sm text-gray-500'>{sub}</div>}
      </div>
    );
  };

  return (
    <div className='flex items-center'>
      <Tooltip content={user?.email}>
        <div
          className={classNames(
            'font-bold text-dark-blue bg-gray-200 rounded-full flex items-center justify-center uppercase flex-shrink-0',
            {
              'text-base w-8.5 h-8.5': size === 'small',
              'text-lg w-12 h-12': size === 'medium',
            },
          )}
        >
          {user?.initials}
        </div>
      </Tooltip>
      {showName && getName()}
    </div>
  );
};

export default Avatar;
