import React, { FC, ReactElement, CSSProperties } from 'react';
import { Heading } from '@tg/core/components';
import classNames from 'classnames';

interface Props {
  flush?: boolean;
  size?: 'md' | 'lg';
  heading?: string;
  action?: ReactElement;
  shadowLevel?: 'sm' | 'md' | 'lg' | 'none';
  className?: string;
  style?: CSSProperties;
}

/**
 * A white box with shadow
 * Styles are taken from semantic-ui, the original
 * component has a lot of props and CSS that we never used
 */
const Segment: FC<Props> = ({
  children,
  flush,
  size = 'md',
  heading,
  action,
  shadowLevel = 'sm',
  className,
  style,
}) => {
  const shadowLevelMap = {
    sm: 'sm:shadow-sm',
    md: 'sm:shadow-md',
    lg: 'sm:shadow-lg',
    none: '',
  };

  const mediumClasses = classNames(
    {
      'sm:rounded-md': true,
      'sm:p-5 md:p-6': !flush,
    },
    shadowLevelMap[shadowLevel],
  );
  const largeClasses = classNames({
    'sm:rounded-3xl': true,
    'sm:p-9 md:px-14 md:py-12 lg:px-24 lg:py-20': !flush,
    [shadowLevelMap.lg]: shadowLevel !== 'none',
  });

  const wrapperClasses = classNames(
    'relative bg-white border-gray-200 sm:border',
    className,
    {
      [largeClasses]: size === 'lg',
      [mediumClasses]: size === 'md',
    },
  );

  return (
    <div className={wrapperClasses} style={style}>
      {heading && (
        <div
          className={classNames('flex mb-5 justify-between', {
            'p-4 pb-0': flush,
          })}
        >
          <Heading level='h4' zeropad>
            {heading}
          </Heading>
          {action}
        </div>
      )}
      {children}
    </div>
  );
};

export default Segment;
