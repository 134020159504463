import React, { FC, useEffect, useReducer, useState, useMemo } from 'react';
import { useResource } from '@tg/core/hooks';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Button,
  Modal,
  ButtonBar,
  Message,
  InputFile,
  List,
  StaticAssetActions,
} from '@tg/core/components';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { handleTimeZone } from '@tg/core/utils/datetimeHelpers';
import { capitalise } from '@tg/core/utils/stringHelpers';
import { PlannerEvent, PlannerEventTypes } from '../../types';
import formValidation from './formValidation';

interface AddEditBookingProps {
  isOpen: boolean;
  contractId: string;
  eventType: Omit<PlannerEventTypes, 'unpaid'>;
  onSuccess: () => void;
  onClose: () => void;
  year: number;
  existingEvent?: PlannerEvent;
  event_types?: any;
  getCalendarData?: () => void;
  startDate: {
    month: number;
    date: number;
  };
  endDate: {
    month: number;
    date: number;
  };
  calendarYearsData: any;
  maxYear: number;
}

const NewBooking: FC<AddEditBookingProps> = ({
  contractId,
  onClose,
  onSuccess,
  year,
  isOpen,
  existingEvent,
  event_types,
  getCalendarData,
  startDate,
  endDate,
  calendarYearsData,
}) => {
  const { t } = useTranslation(['time_off', 'forms']);

  const { ROOT_API } = process.env;
  const token = useSelector(s => s.session.aut);

  const [formDataYear, setFormData] = useState(null);
  const [error, setError] = useState(null);
  const [ValidateLeave, setValidation] = useState(null);
  const [employeeFiles, setEmployeeFiles] = useState([]);
  const [filesToShow, setFilesToShow] = useState([]);
  const [endYear, setEndYear] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [warningMsg, setWarningMsg] = useState(null);
  const [maxYear, setMaxYear] = useState(null);
  const [calendarYearsWithData, setCalendarYearsWithData] = useState([]);
  const [setPlannerYear, setYear] = useState(null);
  const [isValidFormat, setIsValidFormat] = useState({
    message: null,
    isValid: true,
  });

  const { deleteResource, isFetching: editLoading } = useResource(
    {
      url: `employees/contracts/${contractId}/planner/${setPlannerYear}/${
        existingEvent && existingEvent.id
      }`,
    },
    false,
  );

  const { deleteResource: deleteAttachment } = useResource(
    {
      url: `employees/contracts/${contractId}/planner/${setPlannerYear}/${
        existingEvent && existingEvent.id
      }`,
    },
    false,
  );

  const newEventDefaults = useMemo(
    () => ({
      start_date: '' as string,
      first_day_half: false,
      end_date: '' as string,
      last_day_half: false,
      take_unpaid: false,
    }),
    [],
  );

  const [defaultValues, setDefaultValues] = useState(newEventDefaults);

  useEffect(() => {
    if (calendarYearsData?.length) {
      setCalendarYearsWithData(calendarYearsData);
      const maxYr = Math.max(
        ...calendarYearsData.map(singleYearData => singleYearData.year),
      );
      const maxYearData = calendarYearsData.find(
        calYear => calYear.year === maxYr,
      );
      setMaxYear(maxYr);
      setEndYear(maxYearData.year);
    }
  }, [calendarYearsData]);

  useEffect(() => {
    document.addEventListener('click', e => {
      if (e.target.classList.contains('react-datepicker__day--disabled')) {
        setShowPopup(true);
      }
    });
  }, []);

  const getPlanner = startDt => {
    let planner;
    calendarYearsWithData.forEach(planr => {
      if (
        handleTimeZone(new Date(startDt)) >=
          handleTimeZone(new Date(planr.start_date)) &&
        handleTimeZone(new Date(startDt)) <=
          handleTimeZone(new Date(planr.end_date))
      ) {
        planner = planr;
      }
    });
    return planner;
  };

  useEffect(() => {
    if (existingEvent) {
      const { start_date, end_date, start_perc, end_perc } = existingEvent;
      const firstDayHalf =
        start_date === end_date ? end_perc === 50 : start_perc === 50;
      const lastDayHalf =
        start_date === end_date ? start_perc === 50 : end_perc === 50;
      setDefaultValues({
        start_date: existingEvent.start_date || '',
        end_date: existingEvent.end_date || '',
        first_day_half: firstDayHalf,
        last_day_half: lastDayHalf,
        take_unpaid: existingEvent.event_type === 'unpaid',
      });
      const { documents = [] } = existingEvent;
      setFilesToShow(
        documents?.map(doc => {
          return { ...doc, name: doc.filename };
        }),
      );
      const planner = getPlanner(start_date);
      setYear(planner.year);
    } else {
      setDefaultValues(newEventDefaults);
    }
  }, [existingEvent, newEventDefaults]);

  const { getFieldProps, handleSubmit, resetForm, control, watch } =
    Form.useForm({
      validationSchema: formValidation,
    });

  // Set up for 'range' UI on the date picker so the user can't pick a end date before the start
  // The date picker emits dates as strings but want's these props back as Date objects.
  const watchStartDate = watch('new_time_off.start_date', '');
  const watchEndDate = watch('new_time_off.end_date', '');
  interface DateRangeState {
    start: Date | null;
    end: Date | null;
  }
  const dateRangeReducer = (
    state: DateRangeState,
    { start = null, end = null }: { start: string; end: string },
  ) => {
    return {
      start: start ? new Date(start) : start,
      end: end ? new Date(end) : end,
    };
  };
  const [selectedRange, dispatchSelectedRange] = useReducer(dateRangeReducer, {
    start: null,
    end: null,
  } as DateRangeState);
  const leaveTypeOptions = event_types?.filter(
    val => val.leave_type !== 'public-holidays',
  );
  useEffect(() => {
    dispatchSelectedRange({ start: watchStartDate, end: watchEndDate });
  }, [watchStartDate, watchEndDate]);

  /**
   * Reset the form every time the modal is closed
   */
  useEffect(() => {
    if (isOpen === false) {
      resetForm();
      setError(null);
      dispatchSelectedRange({ start: null, end: null });
    }
  }, [isOpen, resetForm]);

  useEffect(() => {
    if (maxYear && Object.keys(startDate).length) {
      if (startDate.date !== 1 || startDate.month !== 0) {
        setEndYear(maxYear + 1);
      }
    }
  }, [maxYear, startDate]);

  const onClosePopup = () => {
    setEmployeeFiles([]);
    setFilesToShow([]);
    onClose();
  };
  const onAPISuccess = () => {
    onClosePopup();
    onSuccess();
  };

  const onError = res => {
    if (res?.response?.data?.error?.includes('invalid file type')) {
      setIsValidFormat({ message: res?.response?.data?.error, isValid: false });
    } else {
      setError(t('time_off:new_event.errors.date_clash'));
    }
  };

  const callApi = async (fData, yr, reqType) => {
    let url = `${ROOT_API}/employees/contracts/${contractId}/planner/${yr}`;
    if (reqType === 'patch') {
      url = `${ROOT_API}/employees/contracts/${contractId}/planner/${yr}/${
        existingEvent && existingEvent.id
      }`;
    }
    const option = {
      method: reqType,
      url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: fData,
    };
    try {
      const res = await axios(option);
      onAPISuccess();
    } catch (err) {
      onError(err);
    }
  };

  const sendApi = (formsData: FormData, yr: any) => {
    setShowPopup(false);
    setWarningMsg(null);

    const fData = formsData || formDataYear.formDataValues;
    const yrData = yr || setPlannerYear;

    if (existingEvent) {
      callApi(fData, yrData, 'patch');
    } else {
      callApi(fData, yrData, 'post');
    }
  };

  const isEndDateBeforeOrEqual = (planr, enddt) => {
    let retValue = false;
    if (enddt <= planr.end_date) {
      retValue = true;
    }
    return retValue;
  };

  const onSubmit = values => {
    const { start_date, end_date, note, first_day_half, last_day_half } =
      values.new_time_off;
    if (end_date === start_date && first_day_half && last_day_half) {
      setError(t('time_off:new_event.errors.no_two_halfs_for_same_day'));
      return;
    }
    setError(null);
    const planner = getPlanner(start_date);
    const totalLeavesAvailable = planner?.leave_available;
    const entitlementUsed = planner?.entitlement_used;
    setYear(planner.year);
    if (values.leave_type) {
      if (isEndDateBeforeOrEqual(planner, end_date)) {
        const formData = new FormData();
        formData.append('start_date', start_date);
        formData.append('end_date', end_date);
        if (employeeFiles?.length) {
          employeeFiles.forEach(employeeFile => {
            if (employeeFile instanceof File) {
              formData.append('files[]', employeeFile);
            }
          });
        }
        formData.append('note', note);
        formData.append('leave_type_id', values.leave_type);
        if (end_date && end_date !== start_date) {
          formData.append('start_perc', first_day_half ? 50 : 100);
          formData.append('end_perc', last_day_half ? 50 : 100);
        } else {
          formData.append('start_perc', last_day_half ? 50 : 100);
          formData.append('end_perc', first_day_half ? 50 : 100);
        }
        const leaveTypeName = leaveTypeOptions.find(
          item => item.leave_type_id === values.leave_type,
        )?.leave_type;

        const startDt = new Date(start_date);
        const endDt = new Date(end_date);
        const diff = endDt.getTime() - startDt.getTime();
        let totalDays = Math.ceil(diff / (1000 * 3600 * 24)) + 1;
        if (first_day_half) {
          totalDays -= 0.5;
        }
        if (last_day_half) {
          totalDays -= 0.5;
        }
        if (
          leaveTypeName === 'holiday' &&
          Number(totalLeavesAvailable[leaveTypeName]) -
            Number(entitlementUsed[leaveTypeName]) <
            totalDays
        ) {
          setFormData({ formDataValues: formData });
          setShowPopup(true);
          setWarningMsg(t('time_off:new_event.errors.more_days_error'));
        } else {
          sendApi(formData, planner.year);
        }

        setValidation(null);
      } else {
        setError(t('time_off:new_event.errors.split_leave_msg'));
      }
    } else setValidation(t('time_off:leave_type_validate'));
  };

  const fileToDataUri = file =>
    new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = event => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });

  const onEmployeeFileDrop = dropped => {
    setEmployeeFiles([...employeeFiles, ...dropped]);
    const droppedFiles = [...dropped];
    const filesToShowObj = filesToShow?.length ? [...filesToShow] : [];

    droppedFiles.forEach(droppedFile => {
      fileToDataUri(droppedFile).then(convertedBlob => {
        const convertedBlobObj = {
          name: droppedFile.name,
          blob: convertedBlob,
        };
        setFilesToShow([...filesToShowObj, convertedBlobObj]);
      });
    });
  };

  const deleteEvent = () => {
    deleteResource({
      onSuccess: () => {
        onClosePopup();
        onSuccess();
      },
    });
  };

  const modalTitle = t(`time_off:new_event.modal_title.holiday`);

  const onModalClose = () => {
    setValidation(null);
    onClosePopup();
  };

  const onDelete = fileValue => {
    let filesToShowObj = [...filesToShow];
    filesToShowObj = filesToShowObj.filter(
      fileToShow => fileToShow.file_id !== fileValue.file_id,
    );
    let employeeFilesObj = [...employeeFiles];
    employeeFilesObj = employeeFilesObj.filter(
      employeeFile => employeeFile.file_id !== fileValue.file_id,
    );
    deleteAttachment({
      id: fileValue.file_id,
      onSuccess: () => {
        getCalendarData();
        setFilesToShow(filesToShowObj);
        setEmployeeFiles(employeeFilesObj);
      },
    });
  };

  return (
    <>
      <Modal
        isOpen={showPopup}
        title='Warning'
        width='xs'
        onClose={() => {
          setShowPopup(false);
          setWarningMsg(null);
        }}
      >
        <Modal.Content>
          {warningMsg || t('time_off:new_event.errors.date_outside_range')}
        </Modal.Content>
        <ButtonBar>
          {warningMsg ? (
            <>
              <div className='mr-2'>
                <Button
                  onClick={() => {
                    setShowPopup(false);
                    setWarningMsg(null);
                  }}
                  color='secondary'
                >
                  No
                </Button>
              </div>
              <Button onClick={() => sendApi()} color='primary'>
                Yes
              </Button>
            </>
          ) : (
            <Button
              onClick={() => {
                setShowPopup(false);
                setWarningMsg(null);
              }}
              color='secondary'
            >
              OK
            </Button>
          )}
        </ButtonBar>
      </Modal>
      <div className={showPopup ? 'invisible' : 'visible'}>
        <Modal
          isOpen={isOpen}
          onClose={onModalClose}
          title={modalTitle}
          width='xs'
        >
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Content>
              {error && (
                <div className='mb-4'>
                  <Message
                    type='error'
                    title={t('time_off:new_event.errors.title')}
                  >
                    {error}
                  </Message>
                </div>
              )}
              <div className='grid gap-4 grid-cols-2 mb-8'>
                <div>
                  <div className='mb-4'>
                    <Form.DateField
                      {...getFieldProps({
                        id: 'new_time_off',
                        name: 'start_date',
                        control: true,
                      })}
                      selectsStart
                      startDate={selectedRange.start}
                      endDate={selectedRange.end}
                      minDate={new Date(year, startDate.month, startDate.date)}
                      maxDate={new Date(endYear, endDate.month, endDate.date)}
                      required
                      defaultValue={defaultValues.start_date}
                      autoComplete='off'
                    />
                  </div>
                  <Form.ToggleField
                    {...getFieldProps({
                      id: 'new_time_off',
                      name: 'first_day_half',
                    })}
                    control={control}
                    value='first_day_half'
                    defaultChecked={defaultValues.first_day_half}
                  />
                </div>
                <div>
                  <div className='mb-4'>
                    <Form.DateField
                      {...getFieldProps({
                        id: 'new_time_off',
                        name: 'end_date',
                      })}
                      selectsEnd
                      startDate={selectedRange.start}
                      endDate={selectedRange.end}
                      minDate={
                        selectedRange.start
                          ? selectedRange.start
                          : new Date(year, startDate.month, startDate.date)
                      }
                      maxDate={new Date(endYear, endDate.month, endDate.date)}
                      defaultValue={defaultValues.end_date}
                      autoComplete='off'
                    />
                  </div>
                  <Form.ToggleField
                    {...getFieldProps({
                      id: 'new_time_off',
                      name: 'last_day_half',
                    })}
                    control={control}
                    value='last_day_half'
                    defaultChecked={defaultValues.last_day_half}
                  />
                </div>
                <div style={{ width: '480px' }}>
                  <Form.SelectField
                    {...getFieldProps({
                      id: 'new_time_off',
                      name: 'leave_type',
                      control: true,
                    })}
                    required
                    options={leaveTypeOptions?.map(item => ({
                      text: capitalise(item.leave_type),
                      value: item.leave_type_id,
                    }))}
                    // control={control}
                    defaultValue={existingEvent?.leave_type_id}
                  />
                </div>
              </div>
              {ValidateLeave && (
                <div style={{ color: 'red', marginTop: '-20px' }}>
                  {ValidateLeave}
                </div>
              )}

              <div style={{ marginTop: '20px' }}>
                <InputFile multiple onDrop={onEmployeeFileDrop} />
              </div>
              {filesToShow?.length ? (
                <List
                  items={filesToShow.map(f => {
                    const columnObj = [<span>{f.name}</span>];
                    if (f.url) {
                      columnObj.push(
                        <StaticAssetActions
                          url={f.url}
                          file={f}
                          onDelete={fileValue => onDelete(fileValue)}
                          type='request_leave'
                        />,
                      );
                    }
                    return {
                      id: f.id,
                      name: f.name,
                      columns: columnObj,
                    };
                  })}
                  type='popup'
                />
              ) : (
                []
              )}

              <div style={{ marginTop: '15px' }}>
                <Form.TextAreaField
                  {...getFieldProps({
                    id: 'new_time_off',
                    name: 'note',
                  })}
                  height='80px'
                  control={control}
                  defaultValue={existingEvent?.note}
                />
              </div>
            </Modal.Content>
            <ButtonBar>
              {existingEvent && (
                <div className='mr-auto'>
                  <Button color='secondary' onClick={deleteEvent}>
                    Delete Request
                  </Button>
                </div>
              )}
              <div className='mr-2'>
                <Button
                  color='secondary'
                  appearance='outline'
                  onClick={onModalClose}
                >
                  {t('forms:buttons.modal.cancel')}
                </Button>
              </div>
              <Button type='submit' loading={editLoading}>
                {t('forms:buttons.submit')}
              </Button>
            </ButtonBar>
          </Form>
        </Modal>
      </div>
      <Modal
        width='xs'
        isOpen={!isValidFormat?.isValid}
        title='Error'
        onClose={() => {
          setIsValidFormat({ message: null, isValid: true }),
            setEmployeeFiles([]),
            setFilesToShow([]);
        }}
      >
        <Modal.Content>{isValidFormat?.message}</Modal.Content>
        <ButtonBar>
          <Button
            color='secondary'
            appearance='outline'
            onClick={() => {
              if (existingEvent) {
                setIsValidFormat({ message: null, isValid: true });
                const updateFileList = filesToShow?.filter(file =>
                  file?.hasOwnProperty('filename'),
                );
                setEmployeeFiles(updateFileList);
                setFilesToShow(updateFileList);
              } else {
                setIsValidFormat({ message: null, isValid: true });
                setEmployeeFiles([]);
                setFilesToShow([]);
              }
            }}
          >
            ok
          </Button>
        </ButtonBar>
      </Modal>
    </>
  );
};

export default NewBooking;
