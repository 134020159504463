import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useResource } from '@tg/core/hooks';
import { actions } from '@tg/core/store/modules/session';
import { Segment, Form, Heading, Button, ButtonBar } from '@tg/core/components';
import { PhoneNumber } from 'src/types';
import validationSchema from './validation';

interface FormData {
  officer: {
    first_name: string;
    last_name: string;
    job_title: string;
    email: string;
    phone: PhoneNumber;
  };
}

const OfficerWelcome: FC = () => {
  const { t } = useTranslation('onboarding');

  const dispatch = useDispatch();
  const { data, patchResource, isFetching } = useResource(
    { url: `officers` },
    true,
  );

  const onSubmit = (formData: FormData) => {
    const { officer } = formData;
    patchResource({
      formData: {
        officer: {
          ...officer,
          welcome_completed: true,
        },
      },
      onSuccess: response => {
        dispatch(actions.fetchUserSuccess(response.data.user));
      },
    });
  };

  const { handleSubmit, getFieldProps } = Form.useForm<FormData>({
    validationSchema,
  });

  const { user, job_title } = data || {};
  const { first_name, last_name, email, phone } = user || {};

  return (
    <>
      <Heading level='h2'>{t('complete_employee')}</Heading>
      <Segment flush>
        <Form onSubmit={handleSubmit(onSubmit)} isFetching={isFetching}>
          <div className='grid gap-6 grid-cols-2 pt-4 px-4 mb-6'>
            <Form.TextField
              {...getFieldProps({ id: 'officer', name: 'first_name' })}
              defaultValue={first_name}
              required
            />
            <Form.TextField
              {...getFieldProps({ id: 'officer', name: 'last_name' })}
              defaultValue={last_name}
              required
            />
            <Form.TextField
              {...getFieldProps({ id: 'officer', name: 'job_title' })}
              defaultValue={job_title}
              required
            />
            <Form.TextField
              {...getFieldProps({ id: 'officer', name: 'email' })}
              defaultValue={email}
              disabled
            />
            {phone && (
              <Form.PhoneField
                {...getFieldProps({ id: 'officer', name: 'phone' })}
                defaultValue={phone}
                required
              />
            )}
          </div>
          <ButtonBar>
            <Button type='submit' loading={isFetching}>
              {t('forms:buttons.save')}
            </Button>
          </ButtonBar>
        </Form>
      </Segment>
    </>
  );
};

export default OfficerWelcome;
