import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Segment, Button, Flag, Skeleton } from '@tg/core/components';
import { getPermission } from '@tg/core/utils/permisssionHelpers';
import permissions from '@tg/core/constant';
import { useSelector } from 'react-redux';
import worldMapImage from './world-map.png';

interface Country {
  id: string;
  code: string;
  flag: string;
  count: number;
  name: string;
}
interface Props {
  count: number;
  countries?: Country[];
  loading: boolean;
}

const Employees: FC<Props> = ({ count, countries, loading }) => {
  const { t } = useTranslation(['page_titles', 'dashboard', 'collections']);

  const accesslist = useSelector(state => state.access.roles);

  return (
    <Segment
      heading={t('page_titles:employees')}
      action={
        <div className='h-10'>
          {getPermission(accesslist, permissions.EMPLOYEES_ADD_EMPLOYEE) && (
            <Button to='/employees/add'>{t('dashboard:add_employee')}</Button>
          )}
        </div>
      }
      className='bg-no-repeat bg-contain bg-right-top'
      style={{
        backgroundImage: `url(${worldMapImage})`,
        backgroundSize: '500px',
      }}
    >
      <div className='-mt-5 mb-4'>
        <div className='text-6xl font-bold text-dark-blue-light leading-none'>
          {loading ? '-' : count}
        </div>
      </div>

      {loading ? (
        <div className='space-y-2 mb-4'>
          <Skeleton width='65%' />
          <Skeleton width='50%' />
          <Skeleton width='60%' />
        </div>
      ) : (
        countries && (
          <ul className='mb-4 space-y-1'>
            {countries.map(({ id, flag, count: country_count, name }) => (
              <li className='flex justify-between' key={id}>
                <div className='flex space-x-2 items-center'>
                  <Flag url={flag} size='small' />
                  <span className='font-semibold'>{name}</span>
                </div>
                <span className='font-semibold text-gray-500'>
                  {country_count}
                </span>
              </li>
            ))}
          </ul>
        )
      )}
      <Button to='/employees' fluid color='tertiary'>
        {t('dashboard:view_employees')}
      </Button>
    </Segment>
  );
};

export default Employees;
