import React from 'react';
import PropTypes from 'prop-types';

/**
 * Show and hide content when a button is clicked
 * State can be managed externally with the isOpen and onToggle props or
 * internally with the native details/summary behaviour. See storybook.
 *
 * NOTE: the native onToggle event is fired *after* toggle so we can't
 * use e.preventDefault();
 */
const DropDown = ({
  trigger,
  isOpen,
  children,
  triggerClassName,
  onToggle,
}) => {
  const handleToggle = onToggle
    ? e => {
        onToggle(e.target.open);
      }
    : null;

  return (
    <details open={isOpen} className='relative' onToggle={handleToggle}>
      <summary
        className={
          triggerClassName ||
          `inline-flex justify-center w-full px-4 py-2 text-sm
          font-medium leading-5 text-gray-700 transition duration-150
          ease-in-out bg-white border border-gray-300 rounded-md
          cursor-pointer hover:text-gray-600 focus:ring focus:outline-none`
        }
      >
        {trigger}
      </summary>
      <div className='rounded-lg border bg-white mt-2 absolute right-0 z-10'>
        {children}
      </div>
    </details>
  );
};

DropDown.propTypes = {
  children: PropTypes.node.isRequired,
  trigger: PropTypes.node.isRequired,
  triggerClassName: PropTypes.string,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
};

DropDown.defaultProps = {
  triggerClassName: null,
  isOpen: false,
  onToggle: null,
};

export default DropDown;
