import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { object, string } from 'yup';
import { Helmet } from 'react-helmet';
import { Heading, Button, Form } from '@tg/core/components';
import { actions } from '@tg/core/store/modules/session';
import { useResource } from '@tg/core/hooks';
import AuthErrors from '../AuthErrors';

const Login = () => {
  const { t } = useTranslation('auth');

  const dispatch = useDispatch();
  const [apiError, setApiError] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const { from } = location.state || { from: { pathname: '/' } };

  const validationSchema = object().shape({
    login: object().shape({
      email: string().email().required(),
      password: string().required(),
    }),
  });
  const { handleSubmit, getFieldProps } = Form.useForm({ validationSchema });

  const { postResource, isFetching } = useResource({ url: 'auth/login' });
  const onSubmit = formData => {
    postResource({
      formData: { ...formData.login, portal: ['Employee', 'Officer'] },
      onSuccess: response => {
        dispatch(actions.createSession({ token: response.token }));
        history.replace(from);
      },
      onError: error => {
        dispatch(actions.destroySession());
        setApiError(error.response || { error: 'unknown' });
      },
    });
  };

  return (
    <>
      <Helmet title={t('login.title')} />
      <Heading level='h1' element='h1'>
        {t('login.title')}
      </Heading>
      <AuthErrors error={apiError} />

      <Form onSubmit={handleSubmit(onSubmit)} isFetching={isFetching}>
        <div className='space-y-4'>
          <Form.TextField
            {...getFieldProps({ id: 'login', name: 'email' })}
            required
            autoComplete='username'
          />

          <Form.PasswordField
            {...getFieldProps({ id: 'login', name: 'password' })}
            required
            autoComplete='current-password'
          />

          <Link to='forgot' className='inline-block'>
            {t('login.linkText')}
          </Link>

          <Button type='submit' fluid>
            {t('login.submitButton')}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default Login;
