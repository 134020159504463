/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React, { FC } from 'react';

interface Props {
  label: string;
  id: string;
  error?: string;
  required?: boolean;
  spreadProps?: boolean;
}

const LabelledInput: FC<Props> = ({
  label,
  id,
  children,
  error,
  required = false,
  /**
   * if the input is wrapped in another element, use this to ensure the wrapper
   * doesn't recieve the id, required and hasError props. You'll have to pass
   * them yourself
   */
  spreadProps = true,
}) => {
  return (
    <div>
      {label && (
        <label
          htmlFor={id}
          className={classNames('block text-sm tracking-wide font-bold mb-1', {
            'text-gray-800': !error,
            'text-red-600': error,
          })}
        >
          {label}
          {required && <span className='text-red ml-1'>*</span>}
        </label>
      )}
      {React.cloneElement(
        children,
        spreadProps ? { id, hasError: error, required } : {},
      )}
      {error && (
        <div className='text-sm text-red-600' data-cy='field-error'>
          {error}
        </div>
      )}
    </div>
  );
};

export default LabelledInput;
