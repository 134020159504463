import React from 'react';
import { useSelector } from 'react-redux';
import { selectSessionUser } from '@tg/core/store/modules/session';
import WelcomePage from '../../Layouts/WelcomePage';
import EmployeeWelcome from './EmployeeWelcome';
import Routes from './Routes';

export default () => {
  const user = useSelector(state => selectSessionUser(state));

  if (!user) return null;

  return !user.welcome_completed ? (
    <WelcomePage>
      <EmployeeWelcome />
    </WelcomePage>
  ) : (
    <Routes />
  );
};
