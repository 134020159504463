import React from 'react';
import PropTypes from 'prop-types';

const InputToggle = ({ id, description, inputRef, ...rest }) => {
  return (
    <div className='relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in'>
      <input
        type='checkbox'
        id={id}
        ref={inputRef}
        className='toggle-checkbox
          absolute block w-6 h-6 rounded-full bg-white shadow
          appearance-none cursor-pointer
          focus:outline-none focus:ring'
        {...rest}
      />
      <label
        htmlFor={id}
        className='toggle-label block overflow-hidden h-6 rounded-full bg-gray-200 cursor-pointer'
        aria-label='toggle'
      />
    </div>
  );
};

InputToggle.propTypes = {
  id: PropTypes.string.isRequired,
  description: PropTypes.string,
  inputRef: PropTypes.func,
};

InputToggle.defaultProps = {
  description: null,
  inputRef: null,
};

export default InputToggle;
