import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '@tg/core/components';
import { Control } from 'react-hook-form';
import type { AddressWithSameAs } from 'src/types';
import TextField from '../../Fields/TextField';
import CollectionField from '../../Fields/CollectionField';
import CheckboxField from '../../Fields/CheckboxField';

interface Props {
  id?: string;
  getFieldProps: ({
    name,
    id,
    translationKey,
  }: {
    name: string;
    id: string;
    translationKey: string;
  }) => void;
  control: Control;
  heading: string;
  showSameAsCheckbox?: boolean;
  isSameAs?: boolean;
  defaultValues?: AddressWithSameAs;
}

/**
 * Renders either a set of address fields or a ticked 'same as registered' checkbox
 */
const AddressSet: FC<Props> = ({
  id = 'address',
  getFieldProps,
  control,
  defaultValues,
  heading,
  showSameAsCheckbox = false,
  isSameAs = false,
}) => {
  const { t } = useTranslation(['forms']);
  const translationKey = 'address';

  return (
    <fieldset>
      <Heading element='legend' level='h4'>
        {heading || t('forms:labels.employee.address')}
      </Heading>

      {showSameAsCheckbox && (
        <CheckboxField
          {...getFieldProps({
            name: 'same_as_registered',
            id,
            translationKey,
          })}
          customLabel={t(`labels.address_with_same_as.sameAsCheckbox`)}
          defaultChecked={defaultValues?.same_as_registered}
          control={control}
        />
      )}
      {!isSameAs && (
        <>
          <div className='grid gap-4 grid-cols-3'>
            <TextField
              {...getFieldProps({
                name: 'line_one',
                id,
                translationKey,
              })}
              required
              defaultValue={defaultValues?.line_one}
            />
            <TextField
              {...getFieldProps({
                name: 'line_two',
                id,
                translationKey,
              })}
              defaultValue={defaultValues?.line_two}
            />
            <TextField
              {...getFieldProps({
                name: 'city',
                id,
                translationKey,
              })}
              defaultValue={defaultValues?.city}
            />
            <TextField
              {...getFieldProps({
                name: 'state',
                id,
                translationKey,
              })}
              defaultValue={defaultValues?.state}
            />
            <TextField
              {...getFieldProps({ name: 'zip', id, translationKey: 'address' })}
              required
              defaultValue={defaultValues?.zip}
            />
            <CollectionField
              {...getFieldProps({
                name: 'country_id',
                id,
                translationKey,
              })}
              defaultValue={defaultValues?.country_id}
              resource='address_countries'
              control={control}
              required
              search
              useName
            />
          </div>
        </>
      )}
    </fieldset>
  );
};

export default AddressSet;
