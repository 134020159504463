import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { InputToggle } from '@tg/core/components';

const ToggleField = ({
  required,
  name,
  errors,
  defaultChecked,
  id,
  translationKey,
  customLabel,
  register,
}) => {
  const { t } = useTranslation('forms');

  const label = customLabel || t(`labels.${translationKey}`);
  const fieldId = `${id}.${name}`;

  return (
    <Form.Field required={required} error={!!errors}>
      <label htmlFor={fieldId}>{label}</label>
      <InputToggle
        defaultChecked={defaultChecked}
        inputRef={register}
        name={fieldId}
        id={fieldId}
      />
      {errors && (
        <p>{t(`validations.${errors.message.key}`, errors.message.values)}</p>
      )}
    </Form.Field>
  );
};

ToggleField.propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  id: PropTypes.string.isRequired,
  translationKey: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
  customLabel: PropTypes.node,
  register: PropTypes.func.isRequired,
};

ToggleField.defaultProps = {
  errors: null,
  defaultChecked: false,
  customLabel: null,
  required: false,
};

export default ToggleField;
