import { useRef, useEffect } from 'react';

/**
 * Combines two refs in to one new ref
 * to enable multiple refs on a single element / component
 */
export default (ref1, ref2) => {
  const targetRef = useRef();

  useEffect(() => {
    [ref1, ref2].forEach(ref => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        // eslint-disable-next-line no-param-reassign
        ref.current = targetRef.current;
      }
    });
  }, [ref1, ref2]);

  return targetRef;
};
