import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputText } from '@tg/core/components';
import { Subject } from 'rxjs';
import { auditTime } from 'rxjs/operators';

const SearchInput = ({ onChange, loading, ...props }) => {
  const debouncedOnChange = new Subject().pipe(auditTime(300));

  useEffect(() => {
    const subscription = debouncedOnChange.subscribe(e => {
      onChange(e);
    });
    return () => subscription.unsubscribe();
  }, [onChange]);

  return (
    <InputText
      {...props}
      icon='search'
      loading={loading}
      onChange={event => {
        event.persist();
        debouncedOnChange.next(event);
      }}
    />
  );
};

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

SearchInput.defaultProps = {
  placeholder: 'Search',
  loading: false,
};

export default SearchInput;
