import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { actions } from '@tg/core/store/modules/session';
import { LoadingSpinner } from '@tg/core/components';
import { RESOURCE_STATUS } from '@tg/core/config/constants';
import { useResource } from '@tg/core/hooks';
import AuthErrors from '../AuthErrors';

import SetPassword from './views/SetPassword';
import InvalidToken from './views/InvalidToken';
import ExpiredToken from './views/ExpiredToken';

const Activate = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [view, setView] = useState('default');
  const [apiError, setApiError] = useState(null);
  const [user, setUser] = useState(null);

  const { patchResource, isFetching } = useResource({
    url: `auth/activate/${token}`,
  });
  const onSubmit = formData => {
    patchResource({
      formData: { ...formData?.create_password },
      onSuccess: response => {
        if (
          response.status &&
          response.status === RESOURCE_STATUS.force_password_change
        ) {
          setUser(response.user);
          setView('password');

          return;
        }
        dispatch(actions.createSession({ token: response.token }));
        history.replace({ pathname: '/' });
      },
      onError: error => {
        dispatch(actions.destroySession());

        const errorMessage = error.response.error;

        switch (errorMessage) {
          case RESOURCE_STATUS.invalid_activation_token:
            setView('invalidToken');
            break;
          case RESOURCE_STATUS.expired_activation_token:
            setView('expiredToken');
            break;
          case null:
            setApiError({ error: 'unknown' });
            break;
          default:
            setApiError(error.response);
        }
      },
    });
  };

  useEffect(() => {
    if (token) {
      // Run on first mount to get the current status of the activation token
      onSubmit();
    } else {
      // There's no token in the URL
      setView('invalidToken');
    }
  }, [token]);

  if (isFetching) {
    return <LoadingSpinner />;
  }

  switch (view) {
    case 'password':
      return <SetPassword onSubmit={onSubmit} user={user} />;
    case 'invalidToken':
      return <InvalidToken />;
    case 'expiredToken':
      return <ExpiredToken token={token} />;

    default:
      return <AuthErrors error={apiError} />;
  }
};

export default Activate;
