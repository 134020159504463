/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, ButtonBar, Modal } from '@tg/core/components';
import Form from '@tg/core/components/systems/Form';
import { useTranslation } from 'react-i18next';
import { Checkbox, Dropdown } from 'semantic-ui-react';

const EditAdministrator = ({
  modalOpen,
  modalOfficer,
  setModalOpen,
  data,
  patchResource,
  onClose,
  employees,
  onSuccess,
}) => {
  const { t } = useTranslation(['officers', 'forms', 'administrators']);
  const [validation, setValidation] = useState([null, null]);

  const [getRole, setRole] = useState([]);
  const [getemployees, setEmployees] = useState(employees);
  const [visible, setVisible] = useState([]);
  const [selectedId, setSelectedId] = useState([]);

  const { getFieldProps, handleSubmit, errors } = Form.useForm({
    validationSchema: null,
  });

  useEffect(() => {
    if (modalOfficer) {
      const newArray = modalOfficer?.assigned_employees?.map(obj => ({
        key: obj.id,
        value: obj.id,
        text: `${obj.full_name}`,
      }));
      setEmployees([...employees, ...newArray]);
    }
  }, [modalOfficer]);

  useEffect(() => {
    if (modalOfficer) {
      const initialIds = modalOfficer?.user?.roles?.map(val => val.id);
      setRole(initialIds);
      setVisible(modalOfficer?.user?.roles?.map(val => val.name));
      setSelectedId(
        modalOfficer?.user?.roles.some(val => val.name === 'Line Manager') &&
          modalOfficer?.assigned_employees?.map(val => val.id),
      );
    }
  }, [modalOfficer]);

  const hasAnyError = errorValue => {
    return errorValue.some(value => value !== null);
  };

  const validateForm = getUserRole => {
    const errorObj = [...validation];
    errorObj[0] = null;
    errorObj[1] = null;

    if (!getUserRole.length) {
      errorObj[0] = t('administrators:validation');
    }
    setValidation(errorObj);
    return errorObj;
  };

  const onSubmit = formData => {
    const customError = validateForm(getRole, selectedId, visible);
    const { first_name, last_name, job_title } = formData.officer;

    if (!hasAnyError(customError)) {
      patchResource({
        id: modalOfficer?.id,
        formData: {
          officer: {
            first_name,
            last_name,
            job_title,
            role_ids: getRole,
            assigned_employee_ids: visible?.includes('Line Manager')
              ? selectedId
              : null,
          },
        },
        onSuccess: () => {
          onClose();
          onSuccess();
          setValidation([null, null]);
        },
      });
    }
  };

  const handleCheckBox = (e, { value, checked }) => {
    if (checked) {
      setRole([...getRole, value]);
      setVisible([...visible, e.target.textContent]);
      const errorObj = [...validation];
      errorObj[0] = null;
      setValidation(errorObj);
    } else {
      setRole(getRole?.filter(item => item !== value));
      setVisible(visible?.filter(item => item !== e.target.textContent));
      const errorObj = [...validation];
      errorObj[1] = null;
      setValidation(errorObj);
    }
  };

  const onEmployeeChange = (e, { value }) => {
    setSelectedId(value);
  };

  const isClosed = () => {
    setModalOpen(false);
    setValidation([null, null]);
  };

  return (
    <>
      <Modal title={t('officers:edit')} isOpen={modalOpen} onClose={isClosed}>
        <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
          <Modal.Content>
            <div className='space-y-4'>
              <Form.TextField
                {...getFieldProps({
                  id: 'officer',
                  name: 'first_name',
                })}
                defaultValue={modalOfficer && modalOfficer.user.first_name}
                required
              />
              <Form.TextField
                {...getFieldProps({
                  id: 'officer',
                  name: 'last_name',
                })}
                defaultValue={modalOfficer && modalOfficer.user.last_name}
                required
              />
              <Form.TextField
                {...getFieldProps({
                  id: 'officer',
                  name: 'email',
                })}
                defaultValue={modalOfficer && modalOfficer.user.email}
                disabled={!!modalOfficer}
                required
              />
              <Form.TextField
                {...getFieldProps({
                  id: 'officer',
                  name: 'job_title',
                })}
                defaultValue={modalOfficer && modalOfficer.user.job_title}
              />
              <div className='flex-col'>
                <div className='mt-6 text-lg font-semibold'>
                  {t('administrators:access')}
                </div>
                <div className='flex gap-4 mt-4'>
                  {data?.map(({ id, name }) => (
                    <Checkbox
                      value={id}
                      key={id}
                      label={name}
                      onChange={handleCheckBox}
                      defaultChecked={modalOfficer?.user?.roles?.some(
                        val => val.id === id,
                      )}
                      className='mr-4'
                    />
                  ))}
                </div>
                {validation[0] && (
                  <div className='text-red'>{validation[0]}</div>
                )}
                {visible?.includes('Line Manager') && (
                  <Dropdown
                    fluid
                    options={getemployees}
                    placeholder='Select Employees'
                    multiple
                    search
                    selection
                    className='mt-4'
                    defaultValue={modalOfficer?.assigned_employees?.map(
                      item => item.id,
                    )}
                    onChange={onEmployeeChange}
                  />
                )}
              </div>
            </div>
          </Modal.Content>

          <ButtonBar>
            <div className='mr-2'>
              <Button color='secondary' appearance='outline' onClick={isClosed}>
                {t('forms:buttons.modal.cancel')}
              </Button>
            </div>
            <Button type='submit'>{t('forms:buttons.save')}</Button>
          </ButtonBar>
        </Form>
      </Modal>
    </>
  );
};

export default EditAdministrator;
