import React, { FC } from 'react';
import { Switch } from 'react-router-dom';
import { Container, PrivateRoute } from '@tg/core/components';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import List from './pages/List';
import Single from './pages/Single';

const OfficerPayroll: FC = () => {
  const { t } = useTranslation(['page_titles', 'dateTime']);

  return (
    <>
      <Helmet title={t('page_titles:payroll')} />
      <Container width='md'>
        <Switch>
          <PrivateRoute exact path='/payroll' component={() => <List />} />
          <PrivateRoute path='/payroll/:id' component={() => <Single />} />
        </Switch>
      </Container>
    </>
  );
};

export default OfficerPayroll;
