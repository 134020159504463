import React, { FC } from 'react';
import { Tooltip } from '@tg/core/components';

interface Step {
  title: string;
  status?: 'complete' | 'current' | null;
}

interface Props {
  steps: Step[];
}

const Steps: FC<Props> = ({ steps }) => {
  const Bullet = ({ status, title }) => {
    switch (status) {
      case 'complete': {
        return (
          <Tooltip content={<span className='text-xs'>{title}</span>}>
            <div className='block w-2.5 h-2.5 bg-indigo-600 rounded-full hover:bg-indigo-900'>
              <span className='sr-only'>{title}</span>
            </div>
          </Tooltip>
        );
      }
      case 'current': {
        return (
          <Tooltip content={<span className='text-xs'>{title}</span>}>
            <div
              className='relative flex items-center justify-center'
              aria-current='step'
            >
              <span className='absolute w-5 h-5 p-px flex' aria-hidden='true'>
                <span className='w-full h-full rounded-full bg-indigo-200' />
              </span>
              <span
                className='relative block w-2.5 h-2.5 bg-indigo-600 rounded-full'
                aria-hidden='true'
              />
              <span className='sr-only'>{title}</span>
            </div>
          </Tooltip>
        );
      }

      default:
        return (
          <Tooltip content={<span className='text-xs'>{title}</span>}>
            <div className='block w-2.5 h-2.5 bg-gray-200 rounded-full hover:bg-gray-400'>
              <span className='sr-only'>{title}</span>
            </div>
          </Tooltip>
        );
    }
  };

  return (
    <nav className='flex items-center justify-center' aria-label='Progress'>
      {/* <p className='text-sm font-medium'>
        Step {steps.findIndex(step => step.status === 'current') + 1} of{' '}
        {steps.length}
      </p> */}
      <ol className='ml-8 flex items-center space-x-5'>
        {steps.map(step => (
          <li key={step.title}>
            <Bullet status={step.status} title={step.title} />
            {/* {step.status === 'complete' ? (
              <a
                href={step.href}
                className='block w-2.5 h-2.5 bg-indigo-600 rounded-full hover:bg-indigo-900'
              >
                <span className='sr-only'>{step.name}</span>
              </a>
            ) : step.status === 'current' ? (
              <a
                href={step.href}
                className='relative flex items-center justify-center'
                aria-current='step'
              >
                <span className='absolute w-5 h-5 p-px flex' aria-hidden='true'>
                  <span className='w-full h-full rounded-full bg-indigo-200' />
                </span>
                <span
                  className='relative block w-2.5 h-2.5 bg-indigo-600 rounded-full'
                  aria-hidden='true'
                />
                <span className='sr-only'>{step.name}</span>
              </a>
            ) : (
              <a
                href={step.href}
                className='block w-2.5 h-2.5 bg-gray-200 rounded-full hover:bg-gray-400'
              >
                <span className='sr-only'>{step.name}</span>
              </a>
            )} */}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Steps;
