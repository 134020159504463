import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@tg/core/localisation/i18n';
import '@tg/core/logger';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import store from '@tg/core/store';
import App from './App';

import '@tg/core/styles/tailwind.css';
import '@tg/core/styles/semantic.less';

const container = document.getElementById('app');
const root = createRoot(container!);

root.render(
  <>
    <PersistGate loading={null} persistor={persistStore(store)}>
      <Provider store={store}>
        <App />
      </Provider>
    </PersistGate>
  </>,
);

// Expose store when run in Cypress so we can dispatch actions without the UI
// See https://www.cypress.io/blog/2018/11/14/testing-redux-store/
if (window.Cypress) {
  window.store = store;
}
