import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '../store';

// Use throughout the app instead of plain `useDispatch` and `useSelector`
// These are typed
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export { default as useResource } from './useResource';
export { default as useWindowSize } from './useWindowSize';
export { default as useCombinedRefs } from './useCombinedRefs';
export { default as useConfirm } from './useConfirm';
