import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getWeekOfMonth } from 'date-fns';
import { handleTimeZone } from '@tg/core/utils/datetimeHelpers';
import { CalendarData } from '../../types';

interface Marker {
  date: string;
  dayOfWeek: number;
  dayOfMonth: number;
  weekOfMonth: number;
}

interface MarkersByMonth {
  // 'key' is the month number
  [key: number]: Marker[];
}

interface PublicHolidaysProps {
  data: CalendarData;
  month: number;
  year: number;
  layout: 'row' | 'grid';
}

interface Thing {
  id: string;
  markersByMonth: MarkersByMonth;
}

const PublicHolidays: FC<PublicHolidaysProps> = ({
  data,
  year,
  month,
  layout,
}) => {
  const { t } = useTranslation(['time_off', 'dateTime']);

  const [transformedData, setTransformedDate] = useState([] as Thing[]);

  useEffect(() => {
    const publicHolidaysCombined = [];
    Object.keys(data).forEach(id => {
      const yearData = data[id];
      const { publicHolidays } = yearData;
      publicHolidays.forEach(publicHoliday => {
        publicHolidaysCombined.push(publicHoliday);
      });
    });
    // Loops though eash USER in DATA
    const newData = Object.keys(data).map(id => {
      const { publicHolidays } = data[id];
      const pubHol = layout === 'row' ? publicHolidays : publicHolidaysCombined;
      const markersByMonth = pubHol?.reduce((acc, dateStr) => {
        const date = handleTimeZone(new Date(dateStr));
        const monthNo = date.getMonth();

        // Double check we don't have stale data, caused by new data 404ing etc
        if (date.getFullYear() !== year) return acc;

        if (!acc[monthNo]) acc[monthNo] = [];
        if (!acc[monthNo].find(acMonth => acMonth.date === dateStr)) {
          acc[monthNo].push({
            date: dateStr,
            dayOfWeek: date.getDay(),
            dayOfMonth: date.getDate(),
            weekOfMonth: getWeekOfMonth(date, { weekStartsOn: 1 }),
          });
        }
        return acc;
      }, {} as MarkersByMonth);

      return {
        id,
        markersByMonth,
      } as Thing;
    });

    setTransformedDate(newData);
  }, [data, layout, year]);

  return (
    <>
      {transformedData.map(({ id, markersByMonth }, employeeIndex) => (
        <React.Fragment key={id}>
          {markersByMonth &&
            markersByMonth[month] &&
            markersByMonth[month].map(
              ({ date, dayOfWeek, weekOfMonth, dayOfMonth }) => (
                <div
                  key={date}
                  style={
                    layout === 'grid'
                      ? {
                          gridColumn: `${dayOfWeek === 0 ? '7' : dayOfWeek}`,
                          gridRow: `${weekOfMonth}`,
                        }
                      : {
                          gridColumn: `${dayOfMonth + 1}`,
                          gridRow: `${employeeIndex + 2}`,
                        }
                  }
                  className='bg-blue-100 flex items-end'
                >
                  <span className='sr-only'>{date}</span>
                  {layout === 'grid' && (
                    <span className='block p-1 text-xs text-gray-600'>
                      {t('time_off:public_holiday')}
                    </span>
                  )}
                </div>
              ),
            )}
        </React.Fragment>
      ))}
    </>
  );
};

export default PublicHolidays;
