import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Segment, Button, Status, Skeleton } from '@tg/core/components';
import { Invoice } from 'src/types';
import { formatValue } from '@tg/core/utils/currencyHelpers';
import { getActiveStorageUrl, getDownloadUrl } from '@tg/core/utils/urlHelpers';

interface Props {
  invoice: Invoice;
  loading: boolean;
}

const LatestInvoice: FC<Props> = ({ invoice, loading }) => {
  const { t } = useTranslation(['page_titles', 'dashboard', 'dateTime']);

  const InvoiceStatus = ({ paid }: { paid: Invoice['paid'] }) => {
    switch (paid) {
      case null: {
        return null;
      }
      case true: {
        return <Status color='green' text='Paid' />;
      }
      case false: {
        return <Status color='orange' text='Due' />;
      }

      default:
        return null;
    }
  };

  return (
    <Segment
      heading={t('dashboard:invoices.title')}
      action={
        invoice && (
          <Button
            color='tertiary'
            size='small'
            href={`${getDownloadUrl(
              getActiveStorageUrl(invoice.document.url),
            )}`}
          >
            {t('dashboard:invoices.download')}
          </Button>
        )
      }
    >
      {loading && (
        <div className='text-center mb-4'>
          <Skeleton />
        </div>
      )}
      {!invoice && !loading && (
        <div className='text-center mb-4'>
          {t('dashboard:invoices.not_found')}
        </div>
      )}
      {invoice && (
        <div className='-mt-3 mb-4'>
          <InvoiceStatus paid={invoice.paid} />
          <div className='text-gray-800 font-semibold text-3xl leading-relaxed'>
            {t(`dateTime:months.${invoice.month}.full`)} {invoice.year}
          </div>
          {invoice.value && (
            <div className='font-semibold text-gray-500'>
              Amount:{' '}
              {formatValue({
                value: invoice.value,
                currency: invoice.currency,
                type: 'salary',
              })}
            </div>
          )}
        </div>
      )}
      <Button color='tertiary' fluid to='/invoices'>
        {t('dashboard:invoices.view_all')}
      </Button>
    </Segment>
  );
};

export default LatestInvoice;
