import React from 'react';
import PropTypes from 'prop-types';
import emptySet from '@tg/core/assets/images/no-payslips.svg';
import { Heading } from '@tg/core/components';

const EmptySet = ({ heading, description, image }) => {
  return (
    <div className='flex flex-col text-center m-auto leading-normal text-xl max-w-lg'>
      <div className='mx-auto mb-5'>
        <img src={image || emptySet} alt='' height='138px' width='138px' />
      </div>
      {heading && <Heading level='h2'>{heading}</Heading>}
      {description && <span>{description}</span>}
    </div>
  );
};

EmptySet.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
};

EmptySet.defaultProps = {
  heading: '',
  description: '',
  image: null,
};

export default EmptySet;
