import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Heading, Button, Form } from '@tg/core/components';
import { useResource } from '@tg/core/hooks';
import AuthErrors from '../AuthErrors';

const ForgotPassword = () => {
  const { t } = useTranslation('auth');

  const [view, setView] = useState('default');
  const [email, setEmail] = useState('');
  const [apiError, setApiError] = useState(null);

  const { handleSubmit, getFieldProps } = Form.useForm();

  const { postResource, isFetching } = useResource({
    url: 'auth/reset-password',
  });
  const onSubmit = formData => {
    setEmail(formData.forgot.email);
    postResource({
      formData: { ...formData.forgot },
      onSuccess: () => setView('success'),
      onError: error => setApiError(error.response || { error: 'unknown' }),
    });
  };

  switch (view) {
    case 'success':
      return (
        <>
          <Helmet title={t('forgot.successTitle')} />
          <Heading level='h1' element='h1'>
            {t('forgot.successTitle')}
          </Heading>
          <p
            /* eslint-disable react/no-danger */
            dangerouslySetInnerHTML={{
              __html: t('forgot.successDescription', {
                interpolation: { escapeValue: false },
                email,
              }),
            }}
            /* eslint-enable react/no-danger */
          />
          <Link to='login'>{t('forgot.linkText')}</Link>
        </>
      );
    default:
      return (
        <>
          <Helmet title={t('forgot.title')} />
          <Heading level='h1' element='h1'>
            {t('forgot.title')}
          </Heading>
          <p>{t('forgot.description')}</p>

          <AuthErrors error={apiError} />

          <Form onSubmit={handleSubmit(onSubmit)} isFetching={isFetching}>
            <Form.TextField
              {...getFieldProps({ id: 'forgot', name: 'email' })}
              required
            />
            <div className='mb-8'>
              <Button type='submit'>{t('forgot.submitButton')}</Button>
            </div>
            <Link to='login'>{t('forgot.linkText')}</Link>
          </Form>
        </>
      );
  }
};

export default ForgotPassword;
