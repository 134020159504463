import { createActions } from '@tg/core/utils/actionHelpers';

export default {
  ...createActions({
    resource: 'session',
    prefix: 'session',
    verbs: ['create', 'destroy'],
  }),
  ...createActions({
    resource: 'user',
    prefix: 'session',
    verbs: ['fetch'],
  }),
  ...createActions({
    resource: 'user_preferences',
    prefix: 'session',
    verbs: ['update'],
  }),
};
