import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'semantic-ui-react';

const PaginationControl = ({ onPageChange, totalPages, activePage }) => {
  const handlePageChange = (_, { activePage: newActivePage }) => {
    onPageChange(newActivePage);
  };

  return (
    totalPages > 1 && (
      <div style={{ textAlign: 'center' }}>
        <Pagination
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    )
  );
};

PaginationControl.propTypes = {
  onPageChange: PropTypes.func,
  totalPages: PropTypes.number,
  activePage: PropTypes.number.isRequired,
};

PaginationControl.defaultProps = {
  onPageChange: f => f,
  totalPages: 0,
};

export default PaginationControl;
