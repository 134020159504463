import { object, string } from 'yup';
import { isDate, isAfter } from 'date-fns';
import { user } from '@tg/core/components/systems/Form/validations';

export default object().shape({
  employee: user.shape({
    email: string().email().required(),
    domicile_id: string().required(),
  }),
  contract: object().shape({
    job_title: string().required(),
    contract_type_id: string().required(),
    attendance_type_id: string().required(),
    line_manager_id: string(),
    start_date: string()
      .nullable()
      .test('is-date-string', { key: 'field_invalid' }, value =>
        isDate(new Date(value)),
      )
      .required(),
    end_date: string()
      .nullable()
      .when('fixed_term', {
        is: 'true',
        then: string()
          .required()
          .test('is-date-string', { key: 'field_invalid' }, value =>
            isDate(new Date(value)),
          )
          .when('start_date', (start_date, schema) => {
            return schema.test(
              'is-after-date',
              { key: 'end_date_before_start_date' },
              value => isAfter(new Date(value), new Date(start_date)),
            );
          }),
      }),
    currency_id: string().required(),
    gross_salary: string().test(
      'is-number',
      { key: 'number_required' },
      value => {
        if (
          typeof value === 'string' &&
          /^[+-]?([0-9]*[.])?[0-9]+$/.test(value)
        ) {
          return true;
        }
        return false;
      },
    ),
  }),
});
