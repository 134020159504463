import React, { FC, useState } from 'react';
import { useResource } from '@tg/core/hooks';
import {
  List,
  Button,
  PageHeader,
  Container,
  EmptySet,
  PaginationControl,
} from '@tg/core/components';
import { useTranslation } from 'react-i18next';
import { getActiveStorageUrl, getDownloadUrl } from '@tg/core/utils/urlHelpers';

interface EmployerDocuments {
  id: string;
  filename: Document;
  created_at: Date;
  url: Document;
}

const EmployerDocuments: FC = () => {
  const { t } = useTranslation(['page_titles']);
  const [page, setPage] = useState(1);

  const { data, pagination } = useResource<EmployerDocuments[]>(
    { url: `employers/documents?page=${page}&pagesize=10` },
    true,
  );

  const formateDate = (date: Date) => {
    const uploadDate = new Date(date);
    return `${uploadDate.getDate()} ${uploadDate
      .toLocaleString('en-us', { month: 'short' })
      .toLocaleUpperCase()} ${uploadDate.getFullYear()}`;
  };

  const listItems = data
    ? data.map(({ id, filename, created_at, url }) => ({
        id,
        columns: [
          <span style={{ whiteSpace: 'normal' }}>{filename}</span>,
          <span>{formateDate(created_at)}</span>,
          <Button size='small' href={getDownloadUrl(getActiveStorageUrl(url))}>
            {t('Documents:download')}
          </Button>,
        ],
      }))
    : [];

  return (
    <>
      <Container width='md'>
        <PageHeader heading='Contract Documents' />
        <div className='mb-4'>
          {data && listItems.length === 0 && (
            <EmptySet description={t('Documents:no_Contracts')} />
          )}
          {!!listItems.length && (
            <List
              headings={[
                { text: t('Documents:file_name') },
                { text: t('Documents:uploaded_On') },
                { text: '', align: 'right' },
              ]}
              items={listItems}
            />
          )}
          {pagination && (
            <div>
              <PaginationControl {...pagination} onPageChange={setPage} />
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default EmployerDocuments;
