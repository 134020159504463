import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  EmptySet,
  List,
  StaticAssetActions,
  PaginationControl,
} from '@tg/core/components';

const PayslipsList = ({ collection, setPage, pagination }) => {
  const { t } = useTranslation(['payslips', 'dateTime']);

  const documents = collection.map(({ id, document, month, year }) => ({
    id,
    // columns: [`${t(`months:${month}.full`)} ${year}`],
    columns: [
      `${t(`dateTime:months.${month}.full`)} ${year}`,
      <StaticAssetActions url={document.url} />,
    ],
  }));

  return (
    <>
      {collection.length ? (
        <List items={documents} />
      ) : (
        <EmptySet
          heading={t('payslips:empty')}
          description={t('payslips:empty_description')}
        />
      )}
      {pagination && (
        <div>
          <PaginationControl {...pagination} onPageChange={setPage} />
        </div>
      )}
    </>
  );
};

PayslipsList.propTypes = {
  collection: PropTypes.array.isRequired,
  pagination: PropTypes.bool.isRequired,
  setPage: PropTypes.func.isRequired,
};

export default PayslipsList;
