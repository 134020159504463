import React, { FC, Fragment, useReducer } from 'react';
import { Segment, Button, Heading, Icon } from '@tg/core/components';
import { LinkProps } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';

export interface Item {
  title: string;
  description: string;
  button: {
    to: LinkProps['to'];
    text: string;
  };
}

interface Props {
  items: Item[];
}

const Support: FC<Props> = ({ items = [] }) => {
  const pageReducer = (state: number, action: string) => {
    switch (action) {
      case 'increment':
        if (state === items.length - 1) return 0;
        return state + 1;
      case 'decrement':
        if (state === 0) return items.length - 1;
        return state - 1;
      default:
        throw new Error('unknown action');
    }
  };

  const [page, dispatch] = useReducer(pageReducer, 0);

  if (!items.length) {
    return null;
  }

  return (
    <Segment className='bg-dark-blue text-white'>
      <ul
        className={classNames('relative p-4 sm:p-0', {
          'h-32': items.length > 1,
        })}
      >
        {items.map(({ title, description, button }, index) => (
          <Transition
            as={Fragment}
            show={page === index}
            enter='transition-opacity duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity duration-500'
            leaveFrom='opacity-100'
            leaveTo='opacity-0 pointer-events-none'
            key={title}
          >
            <li
              className={classNames('flex', {
                'z-10': page === index,
                'inset-0 absolute': items.length > 1,
              })}
            >
              <div className='flex flex-col justify-between w-full'>
                <div>
                  <Heading level='h4' element='h4' inheritColor>
                    {title}
                  </Heading>
                  <p>{description}</p>
                </div>
                <div className='flex justify-between items-end mt-4'>
                  <Button to={button.to}>{button.text}</Button>
                  {items.length > 1 && (
                    <div className='space-x-2'>
                      <button
                        onClick={() => dispatch('increment')}
                        type='button'
                        aria-label='next'
                      >
                        <Icon name='arrow left' />
                      </button>
                      <button
                        onClick={() => dispatch('decrement')}
                        type='button'
                        aria-label='prev'
                      >
                        <Icon name='arrow right' />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </li>
          </Transition>
        ))}
      </ul>
    </Segment>
  );
};

export default Support;
