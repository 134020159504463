import React from 'react';
import PropTypes from 'prop-types';

const InputRadio = React.forwardRef(function InputRadio(
  { id, label, description, radioValue, ...rest },
  ref,
) {
  return (
    <label htmlFor={id} className='flex items-center cursor-pointer'>
      <input
        type='radio'
        id={id}
        className='form-radio h-4 w-4 mt-0.5 cursor-pointer text-dark-blue-light border-gray-300 focus:ring'
        {...rest}
        value={radioValue}
        ref={ref}
      />

      <div className='ml-3 text-sm leading-none'>
        <div className='font-semibold '>{label}</div>
        {description && <div className='text-gray-700 mt-1'>{description}</div>}
      </div>
    </label>
  );
});

InputRadio.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  description: PropTypes.string,
  radioValue: PropTypes.string.isRequired,
};

InputRadio.defaultProps = {
  description: null,
};

export default InputRadio;
