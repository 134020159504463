import React, { FC } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import AuthLayout from '../../Layouts/Auth';
import Login from './Login';
import Activate from './Activate';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import ConfirmEmail from './ConfirmEmail';

interface Props {
  isLoggedIn: boolean;
}

const Auth: FC<Props> = ({ isLoggedIn }) => {
  const { path } = useRouteMatch();

  if (isLoggedIn) {
    return <Redirect to='/' />;
  }

  return (
    <AuthLayout>
      <Switch>
        <Route path={`${path}/login`}>
          <Login />
        </Route>

        <Route path={`${path}/forgot`}>
          <ForgotPassword />
        </Route>

        <Route path={[`${path}/reset/:token`, `${path}/reset/`]}>
          <ResetPassword />
        </Route>

        <Route path={[`${path}/activate/:token`, `${path}/activate`]}>
          <Activate />
        </Route>

        <Route path={`${path}/confirm/:token`}>
          <ConfirmEmail />
        </Route>
      </Switch>
    </AuthLayout>
  );
};

export default Auth;
