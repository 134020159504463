import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useResource } from '@tg/core/hooks';
import { Form, Button, ButtonBar, Segment } from '@tg/core/components';
import { PhoneNumber, Identifier } from 'src/types';
import validationSchema from '../../../../EmployeeWelcome/validation';

interface FormData {
  employee: {
    first_name: string;
    last_name: string;
    domicile_id: string;
    email: string;
    phone: PhoneNumber;
    identifiers: Identifier[];
  };
  address: unknown;
  emergency_contact: unknown;
  bank_details: {
    account_name: string;
    account_iban: string;
    account_swift: string;
  };
  identifiers: unknown;
}

const UserDetails: FC = () => {
  const { t } = useTranslation(['forms']);

  const { data, patchResource, getResource, isFetching } = useResource(
    { url: `employees` },
    true,
  );

  const onSubmit = (formData: FormData) => {
    const { employee, address, emergency_contact, bank_details } = formData;

    patchResource({
      formData: {
        employee: {
          ...employee,
          bank_details,
          emergency_contact,
        },
        address,
      },
      onSuccess: getResource,
    });
  };

  const { handleSubmit, getFieldProps, control } = Form.useForm({
    validationSchema,
  });

  const {
    user,
    address,
    bank_details,
    emergency_contact,
    domicile_id,
    identifiers,
  } = data || {};
  const { first_name, last_name, email, phone } = user || {};

  return (
    <div data-cy='user-details-form'>
      <Segment flush>
        <Form onSubmit={handleSubmit(onSubmit)} isFetching={isFetching}>
          <div className='p-6 space-y-8'>
            <Form.Sets.Employee
              getFieldProps={getFieldProps}
              control={control}
              domicileId={domicile_id}
              disableEmail
              defaultValues={{
                first_name,
                last_name,
                email,
                phone,
                identifiers,
              }}
              mode='edit'
            />

            <Form.Sets.Address
              defaultValues={address}
              getFieldProps={getFieldProps}
              control={control}
            />

            <Form.Sets.BankDetails
              getFieldProps={getFieldProps}
              defaultValues={bank_details}
            />

            <Form.Sets.EmergencyContact
              getFieldProps={getFieldProps}
              control={control}
              defaultValues={emergency_contact}
            />
          </div>
          <ButtonBar>
            <Button type='submit' loading={isFetching}>
              {t('forms:buttons.save')}
            </Button>
          </ButtonBar>
        </Form>
      </Segment>
    </div>
  );
};

export default UserDetails;
