import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector, useResource } from '@tg/core/hooks';
import { formatPhone, formatSalary } from '@tg/core/utils/stringHelpers';
import {
  Heading,
  DisplayDetails,
  Tabs,
  Breadcrumbs,
  Message,
  ListInline,
  Icon,
  Segment,
} from '@tg/core/components';
import { actions } from '@tg/core/store/modules/collections';
import { formatValue } from '@tg/core/utils/currencyHelpers';
import { formatDate } from '@tg/core/utils/datetimeHelpers';
import type { Contract } from 'src/types';
import { useSelector } from 'react-redux';
import { getPermission } from '@tg/core/utils/permisssionHelpers';
import permissions from '@tg/core/constant';
import ContractPayslips from '../ContractPayslips';
import ContractDocuments from '../ContractDocuments';
import ContractBenefits from '../ContractBenefits';
import ContractInvoices from '../ContractInvoices';
import ContractSalaries from '../ContractSalary';
import ContractJobTitles from '../ContractJobTitle/ContractJobTitles';

interface Props {
  employeeId: string;
  showBreadcrumb?: boolean;
}

type ContractTab =
  | 'profile'
  | 'contracts'
  | 'benefits'
  | 'payslips'
  | 'invoices';

const ShowContract: FC<Props> = ({ employeeId, showBreadcrumb = false }) => {
  const { t } = useTranslation([
    'forms',
    'collections',
    'employees',
    'contract',
    'job_titles',
    'salary_history',
  ]);
  const accesslist = useSelector(state => state.access.roles);
  const [activeItem, setActiveItem] = useState(
    getPermission(accesslist, permissions.EMPLOYEES_EMPLOYEE_DETAILS_PROFILE)
      ? 'profile'
      : 'contracts',
  );

  const { data, isFetching } = useResource<Contract>(
    { url: `employers/contracts/${employeeId}` },
    true,
  );

  useEffect(() => {
    setActiveItem(
      getPermission(accesslist, permissions.EMPLOYEES_EMPLOYEE_DETAILS_PROFILE)
        ? 'profile'
        : 'contracts',
    );
  }, [employeeId]);

  /**
   * Get the country names and IDs
   */
  const addressCountries = useAppSelector(
    state => state.collections.address_countries || null,
  );
  const employeeCountries = useAppSelector(
    state => state.collections.domiciles || null,
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!addressCountries)
      dispatch(actions.fetchCollection({ resource: 'address_countries' }));
    if (!employeeCountries)
      dispatch(actions.fetchCollection({ resource: 'domiciles' }));
  }, []);

  if (data && !isFetching) {
    const {
      start_date,
      end_date,
      job_title,
      employee: {
        domicile,
        emergency_contact,
        address,
        identity_check_completed_at,
        user: { full_name, email, phone },
        identifiers,
      },
      current_salary,
      contract_type,
      attendance_type,
      employee_signature_requested_at,
      employee_signed_at,
      contractor,
      payroll_only,
      line_manager,
      current_job_title,
    } = data;

    const onBoardingComplete: boolean =
      !!identity_check_completed_at &&
      !!employee_signature_requested_at &&
      !!employee_signed_at;

    const profileDetails = [
      {
        title: t('labels.employee.email'),
        value: email,
        width: '50%',
      },
      {
        title: t('labels.employee.phone'),
        value: formatPhone(phone),
        width: '50%',
      },
      {
        title: t('labels.employee.domicile_id'),
        value: domicile.name,
        width: '50%',
      },
    ];

    /**
     * If the domicile has employee_identifiers, show
     */
    const domicileIdentifiers =
      employeeCountries?.byId[domicile.id].employee_identifiers;

    if (domicileIdentifiers.length) {
      domicileIdentifiers.forEach(({ description }, index) => {
        profileDetails.push({
          title: description,
          value: identifiers[index]?.value,
          width: '50%',
        });
      });
    }

    const renderActiveView = () => {
      switch (activeItem) {
        case 'profile':
          return (
            <>
              <section className='mb-4'>
                <DisplayDetails details={profileDetails} />
              </section>

              <section className='mb-4'>
                <Heading level='h3'>{t('labels.address.heading')}</Heading>
                <DisplayDetails
                  details={[
                    {
                      title: t('labels.address.line_one'),
                      value: address ? address.line_one : '',
                    },
                    {
                      title: t('labels.address.line_two'),
                      value: address ? address.line_two : '',
                      width: '66.666%',
                    },
                    {
                      title: t('labels.address.country_id'),
                      value:
                        address &&
                        addressCountries &&
                        addressCountries.byId[address.country_id]
                          ? addressCountries.byId[address.country_id].name
                          : '',
                      width: '33.3333%',
                    },
                    {
                      title: t('labels.address.city'),
                      value: address ? address.city : '',
                      width: '33.3333%',
                    },
                    {
                      title: t('labels.address.state'),
                      value: address ? address.state : '',
                      width: '33.3333%',
                    },
                    {
                      title: t('labels.address.zip'),
                      value: address ? address.zip : '',
                      width: '33.3333%',
                    },
                  ]}
                />
              </section>

              <section>
                <Heading level='h3'>
                  {t('labels.emergency_contact.heading')}
                </Heading>
                <DisplayDetails
                  details={[
                    {
                      title: t('labels.emergency_contact.name'),
                      value:
                        emergency_contact && emergency_contact.name
                          ? emergency_contact.name
                          : '',
                      width: '33.3333%',
                    },
                    {
                      title: t('labels.emergency_contact.phone'),
                      value:
                        emergency_contact && emergency_contact.phone
                          ? formatPhone(emergency_contact.phone)
                          : '',
                      width: '33.3333%',
                    },
                  ]}
                />
              </section>
            </>
          );
        case 'contracts':
          return (
            <div className='class="flex flex-col space-y-4'>
              {getPermission(
                accesslist,
                permissions.EMPLOYEES_EMPLOYEE_DETAILS_CONTRACT,
              ) && (
                <Segment>
                  <DisplayDetails
                    details={[
                      {
                        title: t('contract:contract_type'),
                        value: t(
                          `collections:contract_types.${contract_type.code}`,
                        ),
                        width: '50%',
                      },
                      {
                        title: t('contract:attendance_type'),
                        value: t(
                          `collections:attendance_types.${attendance_type.code}`,
                        ),
                        width: '50%',
                      },
                      {
                        title: t('contract:start_date'),
                        value: formatDate({ dateStr: start_date }),
                        width: '50%',
                      },
                      {
                        title: t('contract:end_date'),
                        value: formatDate({ dateStr: end_date }),
                        width: '50%',
                      },
                      {
                        title: t('contract:line_manager'),
                        value: line_manager ? line_manager.full_name : '',
                        width: '50%',
                      },
                      {
                        title: t('labels.contract.contractor'),
                        width: '50%',
                        value: contractor ? (
                          <Icon name='check' />
                        ) : (
                          <Icon name='close' />
                        ),
                      },
                      {
                        title: t('contract:gross_salary'),
                        width: '50%',
                        value: formatValue({
                          value: current_salary.gross_salary,
                          currency: current_salary.currency,
                          type: 'salary',
                          subtype: 'grossSalary',
                        }),
                      },
                      {
                        title: 'Payroll Only',
                        width: '50%',
                        value: payroll_only ? (
                          <Icon name='check' />
                        ) : (
                          <Icon name='close' />
                        ),
                      },
                    ]}
                  />
                </Segment>
              )}
              {getPermission(
                accesslist,
                permissions.EMPLOYEES_SALARY_HISTORY,
              ) && (
                <Segment heading={t('salary_history:salary_history')}>
                  <ContractSalaries
                    contractId={employeeId}
                    current_salary={current_salary}
                  />
                </Segment>
              )}
              <Segment heading={t('job_titles:contract_job_titles')}>
                <ContractJobTitles
                  currentJobTitle={current_job_title}
                  contractId={employeeId}
                />
              </Segment>
              {getPermission(
                accesslist,
                permissions.EMPLOYEES_EMPLOYEE_DETAILS_CONTRACT_VIEW_DOWNLOAD,
              ) && (
                <Segment heading={t('contract_document:contract_document')}>
                  <ContractDocuments contractId={employeeId} />
                </Segment>
              )}
            </div>
          );
        case 'benefits':
          return <ContractBenefits contractId={employeeId} />;
        case 'payslips':
          return <ContractPayslips contractId={employeeId} />;
        case 'invoices':
          return <ContractInvoices contractId={employeeId} />;
        default:
          return null;
      }
    };

    const tabs = [
      // {
      //   text: t('employees:single.sections.profile'),
      //   id: 'profile',
      // },
      ...(getPermission(
        accesslist,
        permissions.EMPLOYEES_EMPLOYEE_DETAILS_PROFILE,
      )
        ? [
            {
              text: t('employees:single.sections.profile'),
              id: 'profile',
            },
          ]
        : []),
      {
        text: t('employees:single.sections.contract'),
        id: 'contracts',
      },
      ...(getPermission(
        accesslist,
        permissions.EMPLOYEES_EMPLOYEE_DETAILS_PROFILE,
      )
        ? [
            {
              text: t('employees:single.sections.benefits'),
              id: 'benefits',
            },
          ]
        : []),
    ];
    if (contractor) {
      tabs.push({
        text: t('employees:single.sections.invoices'),
        id: 'invoices',
      });
    } else {
      tabs.push({
        text: t('employees:single.sections.payslips'),
        id: 'payslips',
      });
    }

    return (
      <div data-cy='employee-details'>
        <div className='mb-8'>
          {showBreadcrumb && (
            <div className='mb-5'>
              <Breadcrumbs
                items={[
                  { text: t('employees:list.heading'), to: '/employees/' },
                  { text: full_name },
                ]}
              />
            </div>
          )}
          <header className='mb-6'>
            <Heading level='h3' element='h1' zeropad>
              {full_name}
            </Heading>
            <div className='text-gray-500 mt-1'>
              <ListInline
                items={[
                  domicile.name,
                  job_title,
                  contractor && t('employees:contractor'),
                ]}
              />
            </div>
            {!onBoardingComplete && (
              <div className='mt-4'>
                <Message title={t('employees:single.notOnboarded.heading')}>
                  <p>{t('employees:single.notOnboarded.description')}</p>
                </Message>
              </div>
            )}
          </header>
          <Tabs
            items={tabs}
            activeItem={activeItem}
            onItemClick={id => setActiveItem(id)}
          />
        </div>

        <div>{renderActiveView()}</div>
      </div>
    );
  }

  return null;
};

export default ShowContract;
