import React from 'react';
import PropTypes from 'prop-types';
import bgSvg from '@tg/core/assets/images/circles-group.svg';
import { Container } from '@tg/core/components';

const WelcomePage = ({ children }) => {
  return (
    <div
      className='bg-no-repeat'
      style={{
        backgroundImage: `url(${bgSvg})`,
        backgroundSize: 'auto 220px',
        backgroundPosition: 'top 120px right 10px',
      }}
    >
      <div className='pt-12 pb-8'>
        <Container>{children}</Container>
      </div>
    </div>
  );
};

WelcomePage.propTypes = {
  children: PropTypes.node,
};

WelcomePage.defaultProps = {
  children: null,
};

export default WelcomePage;
