import React, { FC, useState } from 'react';
import { useResource } from '@tg/core/hooks';
import {
  Container,
  EmptySet,
  PageHeader,
  List,
  StaticAssetActions,
  LoadingSpinner,
  Button,
  PaginationControl,
} from '@tg/core/components';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Document, Currency } from 'src/types';
import EmailRecipientsModal from './components/EmailRecipientsModal';

interface Invoice {
  id: string;
  document: Document;
  month: number;
  year: number;
  currency: Currency;
}

const Invoices: FC = () => {
  const { t } = useTranslation(['page_titles']);

  const [page, setPage] = useState(1);

  const [modalOpen, setModalOpen] = useState(false);
  const { data, isFetching, pagination } = useResource<Invoice[]>(
    { url: `employers/invoices?page=${page}&pagesize=10` },
    true,
  );

  const listItems = data
    ? data.map(({ id, document, month, year, currency }) => ({
        id,
        columns: [
          <span>
            {t(`dateTime:months.${month}.full`)} {year}
          </span>,
          <span>{currency.code}</span>,
          <StaticAssetActions url={document.url} />,
        ],
      }))
    : [];

  return (
    <>
      <Container width='md'>
        <Helmet title={t('page_titles:invoices')} />

        <PageHeader
          heading={t('page_titles:invoices')}
          action={
            <Button
              color='secondary'
              size='small'
              onClick={() => setModalOpen(true)}
            >
              {t('invoices:email_recipients.modal.title')}
            </Button>
          }
        />
        <div className='mb-5'>
          {isFetching && (
            <div className='w-full flex items-center justify-center'>
              <LoadingSpinner />
            </div>
          )}
          {data && listItems.length === 0 && !isFetching && (
            <EmptySet
              heading={t('invoices:empty')}
              description={t('invoices:empty_description')}
            />
          )}
          {!!listItems.length && (
            <List
              headings={[
                { text: t('invoices:date') },
                { text: t('invoices:currency') },
                { text: '' },
              ]}
              items={listItems}
            />
          )}
        </div>
        {pagination && (
          <div>
            <PaginationControl {...pagination} onPageChange={setPage} />
          </div>
        )}
      </Container>
      <EmailRecipientsModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
};

export default Invoices;
