import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LabelledInput } from '@tg/core/components';

const TextAreaField = ({
  required,
  name,
  errors,
  id,
  disabled,
  defaultValue,
  translationKey,
  register,
  autoComplete,
}) => {
  const { t } = useTranslation('forms');

  const label = t(`labels.${translationKey}`);
  const placeholder = t(`placeholders.${translationKey}`);
  const fieldId = `${id}.${name}`;

  let errorMessage = '';
  if (errors) {
    if (errors.message.key) {
      errorMessage = t(`validations.${errors.message.key}`);
    } else if (errors.type === 'required') {
      errorMessage = t(`validations.field_required`);
    }
  }

  return (
    <LabelledInput
      label={label}
      id={fieldId}
      required={required && !disabled}
      error={errors && errorMessage}
    >
      <textarea
        name={fieldId}
        placeholder={placeholder}
        defaultValue={defaultValue}
        disabled={disabled}
        ref={register({ required: required && !disabled })}
        autoComplete={autoComplete}
        style={{ height: '130px' }}
      />
    </LabelledInput>
  );
};

TextAreaField.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  id: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  translationKey: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  autoComplete: PropTypes.string,
};

TextAreaField.defaultProps = {
  errors: null,
  defaultValue: '',
  autoComplete: null,
  required: false,
  disabled: false,
};

export default TextAreaField;
