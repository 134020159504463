import React from 'react';
import { useTranslation } from 'react-i18next';
import { ASSET_URLS } from '@tg/core/config/constants';

const SettingsAbout = () => {
  const { t } = useTranslation(['about']);

  return (
    <>
      <p>
        <a href={ASSET_URLS.privacy_policy} target='_blank' rel='noreferrer'>
          {t('about:privacy_policy')}
        </a>
      </p>
      <p>
        <a href={ASSET_URLS.terms_of_service} target='_blank' rel='noreferrer'>
          {t('about:terms')}
        </a>
      </p>
    </>
  );
};

export default SettingsAbout;
