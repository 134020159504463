import React, { FC, ReactNode } from 'react';
import { Popover as HeadlessPopover } from '@headlessui/react';
import { Segment } from '@tg/core/components';

interface PopoverProps {
  trigger: ReactNode | (({ open }: { open: boolean }) => ReactNode);
}

const Popover: FC<PopoverProps> = ({ trigger, children }) => {
  return (
    <HeadlessPopover.Group>
      <div className='relative'>
        <HeadlessPopover>
          <HeadlessPopover.Button as={React.Fragment}>
            {({ open }) =>
              typeof trigger === 'function' ? trigger({ open }) : trigger
            }
          </HeadlessPopover.Button>
          <HeadlessPopover.Panel className='absolute z-10 right-0 mt-2'>
            <Segment flush shadowLevel='md'>
              {children}
            </Segment>
          </HeadlessPopover.Panel>
        </HeadlessPopover>
      </div>
    </HeadlessPopover.Group>
  );
};

export default Popover;
