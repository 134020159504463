import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { InputRadio } from '@tg/core/components';

const LanguageSelector = ({ onChange }) => {
  const { t, i18n } = useTranslation(['collections']);

  // The list of languages is based on the languages we load in './src/tgcore/localisation/messages.js'
  const [languages, setLanguages] = useState([]);
  useEffect(() => {
    setLanguages(
      Object.keys(i18n.options.resources).map(code => {
        return {
          label: t(`collections:languages.${code.substring(0, 2)}`),
          code,
        };
      }),
    );
  }, [i18n.options.resources]);

  const setLanguage = (lang = 'en-GB') => {
    i18n.changeLanguage(lang).then(() => {
      // console.warn('Language Set by LanguageSelector component', i18n.language);
      onChange(lang);
    });
  };

  return (
    <ul data-cy='language-selector'>
      {languages.map(({ label, code }) => (
        <li className='mb-3' key={code}>
          <InputRadio
            id={`language-${code}`}
            label={label}
            radioValue={code}
            defaultChecked={i18n.language === code}
            onChange={() => {
              setLanguage(code);
            }}
          />
        </li>
      ))}
    </ul>
  );
};

LanguageSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default LanguageSelector;
