import React, { useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { requestWithDispatch } from '@tg/core/utils/requestHelpers';
import { useTranslation } from 'react-i18next';
import { transformCollectionResponse } from '@tg/core/utils/dataTransformers';
import {
  formatLocaleDate,
  handleTimeZone,
} from '@tg/core/utils/datetimeHelpers';
import { Button, List, PaginationControl } from '@tg/core/components';
import { useResource } from '@tg/core/hooks';
import Form from './Form';

const initialState = {
  fetching: false,
  data: null,
  error: null,
  ui: {
    create: false,
    update: null,
  },
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'FETCH_BEGIN':
    case 'CREATE_BEGIN':
    case 'UPDATE_BEGIN':
    case 'DELETE_BEGIN':
      return {
        ...state,
        fetching: true,
      };

    case 'FETCH_END':
    case 'CREATE_END':
    case 'UPDATE_END':
    case 'DELETE_END':
      return {
        ...state,
        fetching: false,
      };

    case 'FETCH_SUCCESS':
    case 'CREATE_SUCCESS':
    case 'UPDATE_SUCCESS':
    case 'DELETE_SUCCESS':
      return {
        ...state,
        data: transformCollectionResponse(payload.data),
        ui: initialState.ui,
      };

    case 'FETCH_FAILURE':
    case 'CREATE_FAILURE':
    case 'UPDATE_FAILURE':
    case 'DELETE_FAILURE':
      return {
        ...state,
        error: payload.data,
      };

    case 'CREATE':
      return {
        ...state,
        ui: {
          ...state.ui,
          create: true,
        },
      };

    case 'UPDATE':
      return {
        ...state,
        ui: {
          ...state.ui,
          update: payload,
        },
      };

    case 'CANCEL':
      return {
        ...state,
        ui: initialState,
      };

    default:
      return state;
  }
};

const ContractJobTitles = ({ currentJobTitle, contractId }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [page, setPage] = useState(1);
  const token = useSelector(s => s.session.aut);
  const call = requestWithDispatch(dispatch, token);
  const { t } = useTranslation(['job_titles']);

  const { pagination } = useResource(
    {
      url: `employers/${contractId}/job_titles?page=${page}&pagesize=10`,
    },
    true,
  );

  useEffect(() => {
    if (page) {
      call({
        url: `employers/${contractId}/job_titles?page=${page}&pagesize=10`,
        action: 'fetch',
      });
    }
  }, [page]);

  const onCreateSubmit = ({ job_title: { effective_date, ...rest } }) => {
    call({
      url: `employers/${contractId}/job_titles`,
      action: 'create',
      method: 'post',
      data: {
        job_title: { effective_date: handleTimeZone(effective_date), ...rest },
      },
    }).then(() => setPage(1));
  };
  const onUpdateSubmit = ({ job_title: { effective_date, ...rest } }) => {
    const id = state.ui.update;
    call({
      url: `employers/${contractId}/job_titles/${id}`,
      action: 'update',
      method: 'patch',
      data: {
        job_title: { effective_date: handleTimeZone(effective_date), ...rest },
      },
    }).then(() => setPage(1));
  };
  const onCancel = () => dispatch({ type: 'CANCEL' });

  const listData = state?.data?.byId ? Object.values(state?.data?.byId) : [];

  const selectEditableData = () => {
    return state.data.byId[state.ui.update];
  };

  return (
    <>
      {state.ui.create && (
        <Form onSubmit={onCreateSubmit} onCancel={onCancel} />
      )}
      {state.ui.update && (
        <Form
          onSubmit={onUpdateSubmit}
          onCancel={onCancel}
          data={selectEditableData}
        />
      )}

      <div className='mb-4'>
        <List
          headings={[
            { text: t('job_titles:job_title') },
            { text: t('job_titles:effective_from'), width: '120px' },
            { text: '', align: 'right' },
          ]}
          items={listData.map(
            ({ id, job_title, effective_date, editable = true, approve }) => {
              return {
                id,
                columns: [
                  <span style={{ whiteSpace: 'normal' }}>{job_title}</span>,
                  formatLocaleDate(effective_date),
                  <span className='space-x-2'>
                    {!approve && id !== currentJobTitle.id && (
                      <span
                        className='font-semibold'
                        style={{ color: 'red', fontSize: '15px' }}
                      >
                        {t('job_titles:validation_Pending')}
                      </span>
                    )}
                    {id === currentJobTitle.id && (
                      <span className='font-semibold'>
                        {' '}
                        {t('job_titles:current')}
                      </span>
                    )}
                    {editable && (
                      <Button
                        size='small'
                        color='tertiary'
                        onClick={() =>
                          dispatch({ type: 'UPDATE', payload: id })
                        }
                      >
                        Edit
                      </Button>
                    )}
                  </span>,
                ],
              };
            },
          )}
        />
      </div>
      <Button onClick={() => dispatch({ type: 'CREATE' })}>Create new</Button>
      {pagination && (
        <div>
          <PaginationControl {...pagination} onPageChange={setPage} />
        </div>
      )}
    </>
  );
};

ContractJobTitles.propTypes = {
  contractId: PropTypes.string.isRequired,
  currentJobTitle: PropTypes.shape({
    id: PropTypes.string,
    job_title: PropTypes.string,
  }).isRequired,
};

export default ContractJobTitles;
