import React from 'react';
import PropTypes from 'prop-types';

const HiddenField = ({ name, id, defaultValue, register }) => {
  const fieldId = `${id}.${name}`;

  return (
    <input
      name={fieldId}
      ref={register}
      type='hidden'
      defaultValue={defaultValue}
    />
  );
};

HiddenField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  defaultValue: PropTypes.any,
};

HiddenField.defaultProps = {
  defaultValue: '',
};

export default HiddenField;
