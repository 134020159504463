import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Segment, Button, Skeleton } from '@tg/core/components';
import { Planner } from 'src/Routes/TimeOff/types';

interface Props {
  data: Planner;
  loading: boolean;
}

const TimeOff: FC<Props> = ({ data, loading }) => {
  const { t } = useTranslation([
    'forms',
    'time_off',
    'collections',
    'dashboard',
    'page_titles',
  ]);

  const [timeOffRemaining, setTimeOffRemaining] = useState(0);
  useEffect(() => {
    if (
      data?.leave_available?.holiday &&
      String(data?.entitlement_used?.holiday)
    ) {
      let newVal =
        data?.leave_available?.holiday - data?.entitlement_used?.holiday;
      if (newVal < 0) newVal = 0;
      setTimeOffRemaining(newVal);
    }
  }, [data]);

  return (
    <Segment
      heading={t('time_off:employee_dashboard.title')}
      action={
        data && (
          <div className='font-semibold text-gray-500'>
            {t('time_off:employee_dashboard.entitlement', {
              entitlement:
                data?.leave_available?.holiday > 0
                  ? Number(data?.leave_available?.holiday)
                  : 0,
            })}
          </div>
        )
      }
    >
      {(data || loading) && (
        <ul className='flex gap-8 mb-4'>
          <li>
            <div className='font-semibold'>
              {t('time_off:employee_dashboard.used')}
            </div>
            <span className='text-dark-blue-light font-bold text-2xl'>
              {loading ? (
                <Skeleton />
              ) : (
                data?.entitlement_used?.holiday?.toFixed(1)
              )}
            </span>
          </li>
          <li>
            <div className='font-semibold'>
              {t('time_off:employee_dashboard.remaining')}
            </div>
            <span className='text-dark-blue-light font-bold text-2xl'>
              {loading ? <Skeleton /> : timeOffRemaining?.toFixed(1)}
            </span>
          </li>
        </ul>
      )}
      {!loading && !data && (
        <div className='px-4 mb-6 text-center'>No Time Off Data Available</div>
      )}
      {/*
      <Heading level='h5'>Your Next Time Off</Heading>
      {loading && (
        <div className='space-y-4'>
          <Skeleton />
        </div>
      )} */}

      <Button to='/time-off' fluid color='tertiary'>
        {t('time_off:employee_dashboard.view_calendar')}
      </Button>
    </Segment>
  );
};

export default TimeOff;
