import React from 'react';
import { Container } from '@tg/core/components';
import CompanyInfo from './sections/CompanyInfo';

const CompanyProfile = () => {
  return (
    <Container width='md'>
      <div className='space-y-10'>
        <CompanyInfo />
      </div>
    </Container>
  );
};

export default CompanyProfile;
