export const transformCollectionResponse = collection => {
  return {
    byId: {
      ...collection.reduce((acc, { id, ...rest }) => {
        acc[id] = { id: String(id), ...rest };
        return acc;
      }, {}),
    },
    allIds: Array.from([...collection.map(item => String(item.id))]),
  };
};

export default {
  transformCollectionResponse,
};
