import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useResource, useConfirm } from '@tg/core/hooks';
import {
  Button,
  Icon,
  Popover,
  ButtonBar,
  Form,
  Heading,
} from '@tg/core/components';
import { isBefore } from 'date-fns';
import classNames from 'classnames';
import { OptionsWithBody } from '@tg/core/hooks/useResource';
import { Planner, PlannerEvent } from '../../types';
// import ApproveRejectForm from './ApproveReject/Form';

interface Props {
  event: PlannerEvent;
  year: number;
  contractId: string;
  onUpdate: (data: Planner) => void;
}

interface FormData {
  time_off_approve_reject: {
    choice: 'approve' | 'reject';
    rejection_note: string;
  };
}

const ApproveRejectEvent: FC<Props> = ({
  event,
  year,
  contractId,
  onUpdate,
  plan_year,
}) => {
  const { t } = useTranslation(['time_off', 'forms']);
  const {
    id: eventId,
    status: existingStatus,
    rejection_note: existingNote,
  } = event;

  const { patchResource, isFetching } = useResource({
    url: `employers/contracts/${contractId}/planner/${year}/${eventId}`,
  });

  const approve = (options: OptionsWithBody, status: string) => {
    let id;
    if (status === 'cancellation_pending') {
      id = 'cancellation_approved';
    } else {
      id = 'approve';
    }
    patchResource({ id, ...options });
  };
  const reject = (options: OptionsWithBody, status: string) => {
    let id;
    if (status === 'cancellation_pending') {
      id = 'cancellation_rejected';
    } else {
      id = 'reject';
    }
    patchResource({ id, ...options });
  };

  const onSuccess = res => {
    onUpdate(res.data);
    // NOTE: The popover only closes becuase this component unmounts
  };

  const postValues = (values: FormData) => {
    const { choice, rejection_note } = values.time_off_approve_reject;
    if (existingStatus === 'cancellation_pending') {
      if (choice === 'approve') {
        return approve({ onSuccess }, existingStatus);
      }
      if (choice === 'reject') {
        return reject(
          { formData: { rejection_note }, onSuccess },
          existingStatus,
        );
      }
    } else {
      if (choice === 'approve') {
        return approve({ onSuccess }, '');
      }
      if (choice === 'reject') {
        return reject({ formData: { rejection_note }, onSuccess }, '');
      }
    }

    // eslint-disable-next-line no-console
    console.error('Something went wrong with the Approve / Reject form');
    return false;
  };

  const { getFieldProps, handleSubmit, watch } = Form.useForm<FormData>({
    validationSchema: null,
  });

  const [popoverKey, setPopoverKey] = useState(0);

  const [formData, setFormData] = useState({} as FormData);
  const { open: openConfirm, Confirm } = useConfirm(
    () => postValues(formData),
    'This event is in the past. Please confirm you wish to edit it.',
  );

  const submitForm = (vals: FormData) => {
    setFormData(vals);
    const eventIsInPast =
      event && isBefore(new Date(event.start_date), new Date());

    if (eventIsInPast) {
      // hack to close the Poppver, the 'click outside' logic meant the user had to click the 'confirm' button twice
      setPopoverKey(Math.random());
      openConfirm();
    } else {
      postValues(vals);
    }
  };

  // Watch the 'approve' field so we can show the text field when rejecting for notes
  const watchApprove: 'approve' | 'reject' = watch(
    'time_off_approve_reject.choice',
    existingStatus === 'rejected' ? 'reject' : 'approve',
  );

  return (
    <div>
      <Popover
        key={popoverKey}
        trigger={() => (
          <Button size='small' color='tertiary'>
            {existingStatus === 'pending' ||
            existingStatus === 'cancellation_pending' ? (
              <>
                {t(`time_off:event_details.respond_button`)}
                <span className='ml-2'>
                  <Icon name='angle right' />
                </span>
              </>
            ) : (
              t(`time_off:event_status.${existingStatus}`)
            )}
          </Button>
        )}
      >
        {(existingStatus === 'pending' ||
          existingStatus === 'cancellation_pending') && (
          <Form onSubmit={handleSubmit(submitForm)}>
            <div className='p-4 pb-0'>
              <Heading level='h5'>Review request</Heading>
            </div>
            <div className='p-4 pt-0'>
              <Form.RadioField
                {...getFieldProps({
                  id: 'time_off_approve_reject',
                  name: 'approve',
                })}
                radioValue='approve'
                defaultChecked={existingStatus !== 'rejected'}
                name='time_off_approve_reject.choice'
              />
              <Form.RadioField
                {...getFieldProps({
                  id: 'time_off_approve_reject',
                  name: 'reject',
                })}
                radioValue='reject'
                defaultChecked={existingStatus === 'rejected'}
                name='time_off_approve_reject.choice'
              />
              <div
                className={classNames({ hidden: watchApprove !== 'reject' })}
              >
                <Form.TextAreaField
                  {...getFieldProps({
                    id: 'time_off_approve_reject',
                    name: 'rejection_note',
                  })}
                  defaultValue={existingNote}
                />
              </div>
            </div>
            <ButtonBar>
              <div className='ml-40'>
                <Button type='submit' loading={isFetching}>
                  {t('forms:buttons.submit')}
                </Button>
              </div>
            </ButtonBar>
          </Form>
        )}
      </Popover>
      <Confirm />
    </div>
  );
};

export default ApproveRejectEvent;
