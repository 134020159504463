import { Observable } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import axios from 'axios';

const { ROOT_API } = process.env;

export interface Config {
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  body?: {
    [key: string]: unknown;
  };
}

export interface ObservableRequestArgs {
  url: string;
  config: Config;
  headers: unknown;
  token: string;
  currentRole: unknown;
}
/**
 * Creates an ajax observable request with auth token and the API root added in
 */
export const observableRequest = ({
  url,
  config = { method: 'GET' },
  headers = null,
  token = '',
  currentRole,
}: ObservableRequestArgs): Observable<AjaxResponse> => {
  const authHeader = token
    ? {
        Authorization: `Bearer ${token}`,
        current_Role_Id: currentRole,
      }
    : {};
  return ajax({
    url: `${ROOT_API}/${url}`,
    // type: 'json',
    headers: headers
      ? {
          ...headers,
          ...authHeader,
        }
      : {
          'Content-Type': 'application/json',
          ...authHeader,
        },
    ...config,
  });
};

export const requestWithDispatch =
  (dispatch, token = null) =>
  async ({ url, action = '', ...rest }) => {
    const authHeader = token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {};
    dispatch({ type: `${action}_BEGIN`.toUpperCase() });
    try {
      const response = await axios({
        url: `${ROOT_API}/${url}`,
        headers: {
          'Content-Type': 'application/json',
          ...authHeader,
        },
        ...rest,
      });
      dispatch({
        type: `${action}_SUCCESS`.toUpperCase(),
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: `${action}_FAILURE`.toUpperCase(),
        payload: error.response,
      });
    } finally {
      dispatch({ type: `${action}_END`.toUpperCase() });
    }
  };
