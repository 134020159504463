import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useResource } from '@tg/core/hooks';
import { BenefitsList, PageHeader } from '@tg/core/components';
import { Helmet } from 'react-helmet';

const EmployeeContractBenefits = ({ contractId }) => {
  const { t } = useTranslation(['page_titles']);

  const [page, setPage] = useState(1);

  const { data, pagination } = useResource(
    {
      url: `employees/contracts/${contractId}/benefits?page=${page}&pagesize=10`,
    },
    true,
  );

  return (
    <>
      <Helmet title={t('page_titles:benefits')} />
      <PageHeader heading={t('page_titles:benefits')} />
      {data && (
        <BenefitsList
          collection={data}
          setPage={setPage}
          pagination={pagination}
        />
      )}
    </>
  );
};

EmployeeContractBenefits.propTypes = {
  contractId: PropTypes.string.isRequired,
};

export default EmployeeContractBenefits;
