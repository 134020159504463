import React, { useEffect, useState } from 'react';
import {
  Container,
  Segment,
  PageHeader,
  Form,
  Button,
  List,
  PaginationControl,
} from '@tg/core/components';

import { useAppSelector, useResource } from '@tg/core/hooks';
import { Checkbox, Dropdown, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { requestWithDispatch } from '@tg/core/utils/requestHelpers';
import { selectSessionUser } from '@tg/core/store/modules/session';
import EditAdministrator from './EditAdministrator';

const CreateAdministrator = () => {
  const { t } = useTranslation(['administrators']);
  const [page, setPage] = useState(1);
  const [getRole, setRole] = useState([]);
  const [validation, setValidation] = useState([null, null]);
  const [uniqueMailValidation, setuniqueMailValidation] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector(s => s.session.aut);
  const call = requestWithDispatch(dispatch, token);
  const [modalOfficer, setModalOfficer] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [visible, setVisible] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [adminId, setAdminId] = useState(null);

  const getUser = useAppSelector(state => selectSessionUser(state));

  const { handleSubmit, getFieldProps, resetForm } = Form.useForm({});

  const { data } = useResource(
    {
      url: `employers/roles`,
    },
    true,
  );

  const {
    data: officers,
    pagination,
    getResource,
  } = useResource(
    {
      url: `employers/officers?page=${page}&pagesize=10`,
    },
    true,
  );

  const { postResource, patchResource } = useResource({
    url: `employers/officers`,
  });

  const { data: employeesList, getResource: getEmployees } = useResource(
    {
      url: `employers/${getUser?.id}/contracts`,
    },
    true,
  );

  useEffect(() => {
    if (employeesList) {
      const newArray = employeesList.map(obj => ({
        key: obj.employee.user.id,
        value: obj.employee.user.id,
        text: `${obj.employee.user.full_name}`,
      }));
      setEmployees(newArray);
    }
  }, [employeesList]);

  const onSuccess = () => {
    setRole([]);
    setValidation([null, null]);
    setVisible([]);
    getResource();
    getEmployees();
    resetForm();
    setuniqueMailValidation(null);
  };

  const hasAnyError = errorValue => {
    return errorValue.some(value => value !== null);
  };

  const validateForm = getUserRole => {
    const errorObj = [...validation];
    errorObj[0] = null;
    errorObj[1] = null;

    if (!getUserRole.length) {
      errorObj[0] = t('administrators:validation');
    }
    setValidation(errorObj);
    return errorObj;
  };

  const onSubmit = values => {
    const customError = validateForm(getRole, selectedId, visible);
    const { first_name, last_name, email, job_title } = values.officer;

    if (!hasAnyError(customError)) {
      postResource({
        formData: {
          officer: {
            first_name,
            last_name,
            email,
            job_title,
            role_ids: getRole,
            assigned_employee_ids: visible?.includes('Line Manager')
              ? selectedId
              : null,
          },
        },
        onSuccess: () => {
          onSuccess();
        },
        onError: () => {
          setuniqueMailValidation('Please select unique email Id');
        },
      });
    }
  };

  const openModal = ({ id, user, assigned_employees }) => {
    setModalOpen(true);
    // For editing existing officer
    if (id) {
      setModalOfficer({ id, user, assigned_employees });
    }
  };

  const handleCheckBox = (e, { value, checked }) => {
    if (checked) {
      setRole([...getRole, value]);
      setVisible([...visible, e.target.textContent]);
    } else {
      setRole(getRole.filter(item => item !== value));
      setVisible(visible.filter(item => item !== e.target.textContent));
    }
  };

  const onEmployeeChange = (e, { value }) => {
    setSelectedId(value);
  };
  const checkOfficerStatus = ev => {
    if (ev.target.textContent === 'Yes') {
      call({
        url: `employers/${adminId}/activate_deactive`,
        action: 'update',
        method: 'patch',
      }).then(() => {
        onSuccess();
        setConfirmationPopup(false);
      });
    } else {
      setConfirmationPopup(false);
    }
  };

  const openPopup = (id, popup) => {
    setConfirmationPopup(popup);
    setAdminId(id);
  };

  const OfficerList = officers
    ? officers.map(({ id, user, assigned_employees }) => ({
        id,
        faded: user?.suspended_by_id,
        columns: [
          <div className='font-semibold'>{`${user.first_name} ${user.last_name}`}</div>,
          <div style={{ overflowWrap: 'break-word', whiteSpace: 'normal' }}>
            {user?.email}
          </div>,
          <div style={{ whiteSpace: 'normal' }}>
            {user?.roles?.map(value => value.name).join(', ')}
          </div>,
          <div className='space-x-2'>
            {!user?.suspended_by_id && (
              <Button
                color='tertiary'
                size='small'
                onClick={() => {
                  openModal({ id, user, assigned_employees });
                }}
              >
                {t('administrators:edit')}
              </Button>
            )}
            <Button
              onClick={() => openPopup(id, true)}
              color='tertiary'
              size='small'
            >
              {!user?.suspended_by_id ? 'Delete' : 'Enable'}
            </Button>
          </div>,
        ],
      }))
    : [];

  const onClose = () => {
    setModalOpen(false);
    getResource();
  };

  return (
    <Container width='md'>
      {confirmationPopup && (
        <Modal
          open={confirmationPopup}
          header='Confirmation'
          content='Officer will be deleted. Do you want to continue?'
          actions={[
            <Button color='secondary' style={{ marginRight: '5px' }}>
              No
            </Button>,
            <Button color='primary'>Yes</Button>,
          ]}
          onActionClick={event => checkOfficerStatus(event)}
          onClose={() => setConfirmationPopup(false)}
          size='mini'
        />
      )}
      <PageHeader heading={t('administrators:add')} />

      <Segment>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className='space-y-6'>
            <div className='grid gap-x-4 grid-cols-3'>
              <Form.TextField
                {...getFieldProps({ id: 'officer', name: 'first_name' })}
                required
              />
              <Form.TextField
                {...getFieldProps({ id: 'officer', name: 'last_name' })}
                required
              />
              <div>
                <Form.TextField
                  {...getFieldProps({ id: 'officer', name: 'email' })}
                  required
                />
                {uniqueMailValidation && (
                  <div className='text-red'>{uniqueMailValidation}</div>
                )}
              </div>
            </div>
            <div className='grid gap-x-6 grid-cols-2'>
              <Form.TextField
                {...getFieldProps({ id: 'officer', name: 'job_title' })}
              />
              <div>
                <label className='font-semibold '>
                  {t('administrators:access')}
                </label>
                <div className='flex items-center mt-5'>
                  {data?.map(({ id, name }) => (
                    <Checkbox
                      value={id}
                      key={id}
                      label={name}
                      onChange={handleCheckBox}
                      className='mr-2'
                      checked={getRole.includes(id)}
                    />
                  ))}
                </div>
                {validation[0] && (
                  <div className='text-red'>{validation[0]}</div>
                )}

                {visible.includes('Line Manager') && (
                  <Dropdown
                    fluid
                    options={employees}
                    placeholder='Select Employees'
                    multiple
                    search
                    selection
                    className='mt-4'
                    onChange={onEmployeeChange}
                  />
                )}
              </div>
            </div>

            <div className='mt-6'>
              <Button size='small' type='submit'>
                {t('administrators:create')}
              </Button>
            </div>
            <div className='mt-6 font-semibold'>
              {t('administrators:officers')}
            </div>
            {OfficerList.length > 0 && (
              <List
                headings={[
                  { text: 'Name' },
                  { text: 'Email' },
                  { text: 'Function / Role', align: 'right' },
                  { text: '', align: 'right' },
                ]}
                items={OfficerList}
                noShadow
              />
            )}
          </div>
        </Form>
        {pagination && (
          <div>
            <PaginationControl {...pagination} onPageChange={setPage} />
          </div>
        )}
      </Segment>
      <EditAdministrator
        modalOpen={modalOpen}
        onClose={onClose}
        modalOfficer={modalOfficer}
        employees={employees}
        setModalOpen={setModalOpen}
        data={data}
        patchResource={patchResource}
        onSuccess={onSuccess}
      />
    </Container>
  );
};

export default CreateAdministrator;
