import React, { FC } from 'react';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Control, Controller } from 'react-hook-form';
import { CheckboxField as CheckboxInput } from '@tg/core/components';

interface Props {
  name: string;
  errors: unknown;
  id: string;
  control: Control;
  defaultChecked?: boolean;
  translationKey?: string;
  required?: boolean;
  customLabel?: string;
}

const CheckboxField: FC<Props> = ({
  required = false,
  name,
  errors,
  defaultChecked,
  id,
  translationKey,
  control,
  customLabel,
}) => {
  const { t } = useTranslation('forms');

  const label = customLabel || t(`labels.${translationKey}`);
  const fieldId = `${id}.${name}`;

  return (
    <Form.Field required={required} error={!!errors}>
      <Controller
        render={({ value, ...props }) => (
          <CheckboxInput
            id={fieldId}
            label={label}
            defaultChecked={defaultChecked}
            {...props}
          />
        )}
        control={control}
        rules={{ required }}
        name={fieldId}
        defaultValue={defaultChecked}
      />
      {errors && (
        <p>{t(`validations.${errors.message.key}`, errors.message.values)}</p>
      )}
    </Form.Field>
  );
};

export default CheckboxField;
