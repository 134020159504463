import { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { pluck } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {
  observableRequest,
  Config,
  ObservableRequestArgs,
} from '../utils/requestHelpers';
import { createActions, Action } from '../utils/actionHelpers';
import {
  getCurrentUserRole,
  selectSessionAuthToken,
} from '../store/modules/session';

// As per the PaginationControl component
interface Pagination {
  activePage: number;
  totalPages: number;
}

interface Meta {
  current_page: number | null;
  next_page: number | null;
  prev_page: number | null;
  total_count: number | null;
  total_pages: number | null;
}

interface State<Data> {
  isFetching: boolean;
  pagination: Pagination;
  data: Data;
  meta: Meta | null;
  errors: string[];
}

interface Options {
  id?: string;
  headers?: unknown;
  onSuccess?: (res: unknown) => void;
  onError?: (error: unknown) => void;
}

export interface OptionsWithBody extends Options {
  formData?: Config['body'];
}
export interface ResourceOutput<Data> extends State<Data> {
  getResource: (arg?: Options) => void;
  postResource: (arg: OptionsWithBody) => void;
  putResource: (arg: OptionsWithBody) => void;
  patchResource: (arg: OptionsWithBody) => void;
  deleteResource: (arg: Options) => void;
}

const transformPaginationMeta = (meta: Meta): Pagination | null => {
  if (!meta) return null;
  return {
    activePage: meta.current_page,
    totalPages: meta.total_pages,
  };
};

const actions = createActions({
  resource: 'resource',
  prefix: 'resource',
  verbs: ['FETCH', 'UPDATE', 'CREATE', 'DESTROY'],
}) as {
  fetchResource: () => Action;
  fetchResourceSuccess: (res: unknown) => Action;
  fetchResourceFailure: (error: unknown) => Action;
  FETCH_RESOURCE: string;
  FETCH_RESOURCE_SUCCESS: string;
  FETCH_RESOURCE_FAILURE: string;
};

const initialState: State<null> = {
  isFetching: false,
  data: null,
  meta: null,
  errors: [],
  pagination: null,
};

const reducer = <Data>(state: State<Data>, action: Action): State<Data> => {
  // console.log(action);
  const { type, payload } = action;

  switch (type) {
    case actions.FETCH_RESOURCE: {
      return {
        ...state,
        errors: [],
        isFetching: true,
      };
    }
    case actions.FETCH_RESOURCE_SUCCESS: {
      const { data = null, meta = null } = payload || {};
      return {
        ...state,
        data,
        meta,
        pagination: transformPaginationMeta(meta),
        errors: [],
        isFetching: false,
      };
    }
    case actions.FETCH_RESOURCE_FAILURE: {
      let { errors } = payload;
      if (!errors && payload.response?.error) {
        errors = [payload.response?.error];
      }
      if (!errors) {
        errors = ['No Error Message Found'];
      }
      return {
        ...state,
        errors,
        isFetching: false,
      };
    }
    default:
      return state;
  }
};

// export default ({ url, config = {}, options = {} }, initWithFetch = false) => {
export default <Data>(
  {
    url,
    config = {},
  }: {
    url: string;
    config?: ObservableRequestArgs['config'];
  },
  initWithFetch = false,
): ResourceOutput<Data> => {
  /* eslint-disable no-shadow */
  const [state, dispatch] = useReducer(reducer, initialState);
  const token: string = useSelector(state => selectSessionAuthToken(state));
  const getRoleId = useSelector(state => getCurrentUserRole(state));
  let subject: Subscription = null;

  const call = (config: Config = null, options: Options = {}) => {
    const {
      id = null,
      headers = null,
      onSuccess = null,
      onError = null,
    } = options;
    let reqUrl = null;

    if (['DELETE', 'PATCH'].includes(config.method)) {
      const strArr = url.split('?');
      reqUrl = `${strArr[0]}${
        id ? `/${id}${strArr[1] ? `?${strArr[1]}` : ''}` : ''
      }`;
    } else {
      reqUrl = `${url}${id ? `/${id}` : ''}`;
    }
    dispatch(actions.fetchResource());
    subject = observableRequest({
      url: reqUrl,
      token,
      config,
      headers,
      currentRole: getRoleId,
    })
      .pipe(pluck('response'))
      .subscribe(
        response => {
          dispatch(actions.fetchResourceSuccess(response));
          if (onSuccess) onSuccess(response);
        },
        error => {
          dispatch(actions.fetchResourceFailure(error));
          if (onError) onError(error);
        },
        () => {
          subject = null;
        },
      );
  };

  const getResource = (options: Options = {}) => {
    return call({ method: 'GET' }, { ...options });
  };

  const postResource = ({ formData = {}, ...rest }: OptionsWithBody) => {
    call({ method: 'POST', body: formData }, { ...rest });
  };

  const putResource = ({ formData = {}, ...rest }: OptionsWithBody) => {
    call({ method: 'PUT', body: formData }, { ...rest });
  };

  const patchResource = ({ formData = {}, ...rest }: OptionsWithBody) => {
    call({ method: 'PATCH', body: formData }, { ...rest });
  };

  const deleteResource = (options: Options) => {
    call({ method: 'DELETE' }, { ...options });
  };

  useEffect(() => {
    if (initWithFetch) getResource(config);
    return () => {
      if (subject) subject.unsubscribe();
    };
  }, [url]);

  return {
    getResource,
    postResource,
    putResource,
    patchResource,
    deleteResource,
    ...(state as State<Data>),
  };
};
