import React from 'react';
import PropTypes from 'prop-types';

/**
 * A traffic light status indicator, e.g. 'active', 'pendiing', 'expired' etc
 */
const Status = ({ text, color }) => {
  const dotColors = {
    green: '#65C193',
    red: '#F05D5D',
    orange: '#EE9F03',
    gray: '#d8d8d8',
  };
  const dotColor = dotColors[color];

  return (
    <div className='font-bold flex items-center uppercase leading-none text-gray-500'>
      <span className='text-xs'>{text}</span>
      <span
        style={{ backgroundColor: dotColor }}
        className='rounded-full w-3 h-3 ml-2'
      />
    </div>
  );
};

Status.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['green', 'orange', 'red', 'grey']).isRequired,
};

// Status.defaultProps = {
//   flush: false,
//   size: 'md',
// };

export default Status;
