import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { actions } from '@tg/core/store/modules/session';
import { useResource } from '@tg/core/hooks';
import AuthErrors from '../AuthErrors';

const ConfirmEmail = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [apiError, setApiError] = useState(null);

  const { patchResource } = useResource({
    url: `auth/confirm-email/${token}`,
  });
  const onSubmit = () => {
    patchResource({
      onSuccess: response => {
        dispatch(actions.createSession({ token: response.token }));
        history.replace({ pathname: '/' });
      },
      onError: error => {
        dispatch(actions.destroySession());
        setApiError(error.response || { error: 'unknown' });
      },
    });
  };

  useEffect(() => {
    onSubmit();
  }, []);

  return <AuthErrors error={apiError} />;
};

export default ConfirmEmail;
