import React from 'react';
import PropTypes from 'prop-types';
import { getActiveStorageUrl, getDownloadUrl } from '@tg/core/utils/urlHelpers';

/**
 * 'View' and 'Download' buttons for static assets or documents
 */
const StaticAssetActions = ({ url, file, onDelete, type }) => {
  const buttonClassNames =
    'ml-4 rounded border border-gray-300 text-gray-800 text-sm font-medium leading-none px-3 py-2';
  return (
    <div className='flex justify-end'>
      <a
        href={`${getActiveStorageUrl(url)}`}
        target='_blank'
        rel='noreferrer'
        className={buttonClassNames}
      >
        View
      </a>
      <a
        href={`${getDownloadUrl(getActiveStorageUrl(url))}`}
        className={buttonClassNames}
      >
        Download
      </a>
      {type === 'request_leave' && (
        <button
          type='button'
          className={buttonClassNames}
          onClick={() => onDelete(file)}
        >
          Delete
        </button>
      )}
    </div>
  );
};

StaticAssetActions.propTypes = {
  url: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  file: PropTypes.any,
  type: PropTypes.string,
};

StaticAssetActions.defaultProps = {
  onDelete: () => {},
  file: {},
  type: '',
};

export default StaticAssetActions;
