import { object, string, array } from 'yup';
import {
  user,
  address,
  phoneRequired,
} from '@tg/core/components/systems/Form/validations';

export default object().shape({
  employee: user.shape({
    identifiers: array()
      .default([])
      .test('identifier-test', { key: 'field_required' }, identifiers =>
        identifiers.every(
          x => x.value && x.value.match(x.validator || /[a-zA-Z\d]/),
        ),
      ),
  }),
  address,
  emergency_contact: object({
    name: string().required(),
    phone: phoneRequired,
  }),
  bank_details: object().shape({
    account_name: string().required(),
    account_iban: string().required(),
    account_swift: string().required(),
  }),
});
