import { Control, FormState, useForm, UseFormMethods } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';

interface GetFieldPropsArgs<FormData> {
  id: keyof FormData; // FormData -> key;
  name: string; // FormData -> key -> key
  translationKey: string;
}
interface UseFormResponse<FormData> {
  getFieldProps: (args: GetFieldPropsArgs<FormData>) => void;
  handleSubmit: UseFormMethods['handleSubmit'];
  resetForm: UseFormMethods['reset'];
  control: Control;
  watch: UseFormMethods['watch'];
  formState: FormState<FormData>;
}

/**
 * Wraps react-hook-form in some defaults
 */
export default <FormData>({
  validationSchema = null,
}: {
  validationSchema?: ObjectSchema;
} = {}): UseFormResponse<FormData> => {
  const {
    register,
    control,
    watch,
    handleSubmit,
    errors: fieldErrors,
    reset,
    formState,
    setValue,
  } = useForm<FormData>({
    resolver: validationSchema && yupResolver(validationSchema),
  });

  /**
   * Get the props that are the same for all input fields
   * @param {string} id The name of the 'field set' e.g. 'address'
   * @param {string} name The unique name of the input e.g. 'line_one'
   * @param {string} translationKey The 'address' in t(`forms:labels.address.line_one`)
   */
  const getFieldProps = ({
    id,
    name,
    translationKey = '',
  }: GetFieldPropsArgs<FormData>) => {
    // eslint-disable-next-line
    // @ts-ignore
    const errors = fieldErrors && fieldErrors[id] && fieldErrors[id][name];

    // When not using a validationSchema, validation is set as each field is 'registerd' (by react-hook-form)
    // e.g. ref={register({ required: true})}
    // In these instances, we don't have a `message`, this adds it
    if (errors && !errors.message) {
      errors.message = {
        // TODO: make this dynamic
        key: 'field_required',
      };
    }

    return {
      id,
      name,
      translationKey: `${translationKey || (id as string)}.${name}`,
      errors,
      register,
      control,
    };
  };

  return {
    getFieldProps,
    handleSubmit,
    resetForm: reset,
    control,
    watch,
    formState,
    setValue,
  };
};
