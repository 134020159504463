import { object, boolean, string } from 'yup';
import {
  dateValidation,
  endDateValidation,
} from '@tg/core/components/systems/Form/validations';

export default object().shape({
  new_time_off: object()
    .shape({
      first_day_half: boolean(),
      last_day_half: boolean(),
      // leave_type: string().required(),
      take_unpaid: boolean(),
      start_date: dateValidation.required(),
      end_date: endDateValidation,
      note: string(),
      // first_day_half: boolean(),
      // last_day_half: boolean(),
    })
    .noUnknown(),
});
