import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector, useResource } from '@tg/core/hooks';
import { selectSessionUser, actions } from '@tg/core/store/modules/session';
import { Heading, Segment, Form, ButtonBar, Button } from '@tg/core/components';
import { Address, Employer } from 'src/types';
import validationSchema from './validation';

interface PrimaryAddress extends Address {
  same_as_registered: boolean;
}
interface FormData {
  primary_address: PrimaryAddress;
  registered_address: Address;
  employer: Employer;
}

const EmployerWelcome: FC = () => {
  const { t } = useTranslation(['onboarding', 'forms']);

  const dispatch = useAppDispatch();
  const user = useAppSelector(state => selectSessionUser(state));
  const { data, patchResource, isFetching, errors } = useResource(
    { url: 'employers' },
    true,
  );

  const onSuccess = response => {
    dispatch(
      actions.fetchUserSuccess({
        ...user,
        company: {
          ...user.company,
          welcome_completed: response.data.welcome_completed,
        },
      }),
    );
  };

  const onSubmit = (formData: FormData) => {
    // Remove same_as_registered from the primary address (where it live in the UI) on to
    // registered_address.use_as_contact_address (where it lives on the backend)
    const { same_as_registered, ...primary_address } = formData.primary_address;

    const patchData = {
      primary_address,
      registered_address: {
        ...formData.registered_address,
        use_as_contact_address: same_as_registered,
      },
      employer: {
        ...formData.employer,
        welcome_completed: true,
      },
    };
    if (same_as_registered) {
      delete patchData.primary_address;
    }

    patchResource({
      formData: patchData,
      onSuccess,
    });
  };

  const {
    addresses,
    domicile_id: domicileId,
    name,
    identifiers,
    industry_sector_id,
  } = data || {};

  const registered_address = addresses
    ? addresses.find(x => x.address_type === 'registered') || {}
    : {};

  const existingPrimaryAdd =
    addresses && addresses.find(x => x.address_type === 'trading');

  const primary_address = existingPrimaryAdd
    ? {
        ...existingPrimaryAdd,
        same_as_registered: false,
      }
    : {
        same_as_registered: true,
      };

  const { handleSubmit, getFieldProps, control, watch } =
    Form.useForm<FormData>({
      validationSchema,
    });

  const defaultSameAsRegistered = primary_address.same_as_registered;
  const watchSameAs = watch(
    `primary_address.same_as_registered`,
    defaultSameAsRegistered,
  );

  return (
    <>
      <Heading level='h2'>{t('onboarding:complete_employer')}</Heading>
      <Segment flush>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          isFetching={isFetching}
          errors={errors}
        >
          <div className='space-y-6 p-6'>
            <Form.Sets.Employer
              getFieldProps={getFieldProps}
              control={control}
              domicileId={domicileId}
              defaultValues={{
                name,
                identifiers,
                industry_sector_id,
              }}
            />
            <Form.Sets.Address
              id='registered_address'
              getFieldProps={getFieldProps}
              control={control}
              defaultValues={registered_address}
              heading={t('forms:labels.address.heading_registered')}
            />
            <Form.Sets.Address
              id='primary_address'
              getFieldProps={getFieldProps}
              control={control}
              defaultValues={primary_address}
              heading={t('forms:labels.address.heading_contact')}
              showSameAsCheckbox
              isSameAs={watchSameAs}
            />
          </div>

          <ButtonBar>
            <Button type='submit' loading={isFetching}>
              {t('forms:buttons.submit')}
            </Button>
          </ButtonBar>
        </Form>
      </Segment>
    </>
  );
};

export default EmployerWelcome;
