import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useResource } from '@tg/core/hooks';
import { actions } from '@tg/core/store/modules/session';
import { Segment, Heading, Form, ButtonBar, Button } from '@tg/core/components';
import validationSchema from './validation';

const EmployeeWelcome = () => {
  const { t } = useTranslation(['forms', 'onboarding']);

  const dispatch = useDispatch();

  const { data, patchResource, isFetching, errors } = useResource(
    { url: `employees` },
    true,
  );

  const onSuccess = response => {
    dispatch(actions.fetchUserSuccess(response.data.user));
  };

  const onSubmit = formData => {
    const { employee, address, emergency_contact, bank_details } = formData;
    patchResource({
      formData: {
        employee: {
          ...employee,
          bank_details,
          emergency_contact,
          welcome_completed: true,
        },
        address,
      },
      onSuccess,
    });
  };

  const { handleSubmit, getFieldProps, control } = Form.useForm({
    validationSchema,
  });

  if (data) {
    const {
      user,
      address,
      bank_details,
      emergency_contact,
      domicile_id,
      identifiers,
    } = data || {};
    const { first_name, last_name, email, phone } = user || {};

    return (
      <>
        <Heading level='h2' element='h1'>
          {t('onboarding:complete_employee')}
        </Heading>
        <Segment flush>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            loading={isFetching}
            errors={errors}
          >
            <div className='space-y-6 p-6'>
              <Form.Sets.Employee
                getFieldProps={getFieldProps}
                control={control}
                domicileId={domicile_id}
                disableEmail
                defaultValues={{
                  first_name,
                  last_name,
                  email,
                  phone,
                  identifiers,
                }}
                mode='edit'
              />
              <Form.Sets.Address
                getFieldProps={getFieldProps}
                control={control}
                defaultValues={address}
              />
              <Form.Sets.BankDetails
                getFieldProps={getFieldProps}
                defaultValues={bank_details}
              />
              <Form.Sets.EmergencyContact
                getFieldProps={getFieldProps}
                control={control}
                defaultValues={emergency_contact}
              />
            </div>

            <ButtonBar>
              <Button type='submit' loading={isFetching}>
                {t('forms:buttons.submit')}
              </Button>
            </ButtonBar>
          </Form>
        </Segment>
      </>
    );
  }

  return null;
};

export default EmployeeWelcome;
