import { createSelector } from 'reselect';
import type { SessionUser } from 'src/types';
import type { RootState } from '../..';

export const selectSessionAuthToken = (state: RootState): string =>
  state.session.aut;

export const selectSessionUser = createSelector(
  (state: RootState) => state.session,
  (session): SessionUser => {
    const { user_id, role_id, role_desc: role, user } = session;
    return { user_id, role_id, role, ...user };
  },
);

export const getCurrentUserRole = (state: RootState): string =>
  state.access.rolesId;
