import React, { FC, ReactNode } from 'react';
import { Icon } from '@tg/core/components';
import classNames from 'classnames';

export type MessageType = 'info' | 'warning' | 'error' | 'success';

interface Props {
  type?: MessageType;
  title?: string;
  action?: ReactNode;
}

const Message: FC<Props> = ({ type = 'info', children, title, action }) => {
  const iconMap = {
    info: 'info circle',
    warning: 'warning sign',
    error: 'times circle',
    success: 'check circle',
  };

  return (
    <div
      className={classNames(
        'Message flex justify-between grid gap-4 px-5 py-4 rounded font-medium text-sm',
        {
          'bg-light-blue-100': type === 'info',
          'text-light-blue-600': type === 'info',
          'bg-yellow-100': type === 'warning',
          'text-yellow-700': type === 'warning',
          'bg-red-100': type === 'error',
          'text-red-600': type === 'error',
          'bg-green-100': type === 'success',
          'text-green-800': type === 'success',
        },
      )}
      style={{ gridTemplateColumns: 'auto 1fr auto' }}
      aria-label={type}
    >
      <Icon name={iconMap[type]} size='lg' />
      <div>
        {title && <div className='font-bold leading-none mb-1'>{title}</div>}
        {children}
      </div>
      {action && <span>{action}</span>}
    </div>
  );
};

export default Message;
