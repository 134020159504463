import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  EmptySet,
  StaticAssetActions,
  List,
  PaginationControl,
} from '@tg/core/components';

const ContractDocumentsList = ({
  collection,
  loading,
  setPage,
  pagination,
}) => {
  const { t } = useTranslation(['collections', 'contract']);

  const documents = collection
    ? collection.map(
        ({ id, document, metadata: { document_type, document_language } }) => ({
          id,
          columns: [
            `${t(`collections:contract_document_types.${document_type}`)}`,
            <div>{t(`collections:languages.${document_language}`)}</div>,
            <StaticAssetActions url={document.url} />,
          ],
        }),
      )
    : [];

  return (
    <>
      {documents.length > 0 && (
        <List
          headings={[
            { text: t('contract:documents.type') },
            { text: t('contract:documents.language'), width: '120px' },
            { text: '', align: 'right' },
          ]}
          items={documents}
          noShadow
        />
      )}
      {!documents.length && !loading && (
        <EmptySet
          heading={t('contract:documents.empty')}
          description={t('contract:documents.empty_description')}
        />
      )}
      {pagination && (
        <div>
          <PaginationControl {...pagination} onPageChange={setPage} />
        </div>
      )}
    </>
  );
};

ContractDocumentsList.propTypes = {
  collection: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.bool.isRequired,
  setPage: PropTypes.func.isRequired,
};

export default ContractDocumentsList;
