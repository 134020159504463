import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useResource } from '@tg/core/hooks';
import { PayslipsList } from '@tg/core/components';

const ContractPayslips = ({ contractId }) => {
  const [page, setPage] = useState(1);

  const { data, pagination } = useResource(
    {
      url: `employers/contracts/${contractId}/payslips?page=${page}&pagesize=10`,
    },
    true,
  );

  return data ? (
    <PayslipsList collection={data} setPage={setPage} pagination={pagination} />
  ) : null;
};

ContractPayslips.propTypes = {
  contractId: PropTypes.string.isRequired,
};

export default ContractPayslips;
