import React from 'react';
import PropTypes from 'prop-types';

const Steps = ({ steps }) => {
  const columnsClassMap = {
    2: 'grid-cols-2',
    3: 'grid-cols-3',
    4: 'grid-cols-4',
  };

  return (
    <ol className={`flex grid gap-4 ${columnsClassMap[steps.length]}`}>
      {steps.map(({ title, status, isComplete }, index) => (
        <li key={title}>
          <div
            className={`h-2 w-full mb-3 ${
              isComplete ? 'bg-blue-900' : 'bg-gray-200'
            }`}
          />
          <div className='uppercase text-blue-800 text-sm font-bold'>
            Step
            {index + 1}
          </div>
          <div className='font-semibold text-gray-700 mb-4'>Do the thing</div>
          {status && <div className='text-gray-500'>{status}</div>}
        </li>
      ))}
    </ol>
  );
};

Steps.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      status: PropTypes.status,
      isComplete: PropTypes.bool,
    }),
  ).isRequired,
};

// Steps.defaultProps = {
//   error: null,
// };

export default Steps;
