/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Heading } from '@tg/core/components';
import { withTranslation } from 'react-i18next';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error) {
    this.setState({ hasError: true });
    this.props.logException(error);
  }

  render() {
    const { hasError } = this.state;
    const { children, t } = this.props;

    return hasError ? (
      <Container>
        <div className='text-center'>
          <Heading element='h1' level='h2'>
            {t('code_error.heading')}
          </Heading>
          <p>
            {t('code_error.description')}
            <button
              type='button'
              className='underline'
              onClick={() => {
                window.location.reload();
              }}
            >
              {t('code_error.reload')}
            </button>
          </p>
        </div>
      </Container>
    ) : (
      children
    );
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  logException: PropTypes.func,
  t: PropTypes.func.isRequired,
};

ErrorBoundary.defaultProps = {
  logException: e => {
    console.error(e); // eslint-disable-line no-console
  },
};

export default withTranslation('errors')(ErrorBoundary);
