import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Settings from '../../../Layouts/Settings';
import Profile from './pages/Profile';

const EmployeeSettings: FC = () => {
  const { t } = useTranslation(['settings']);

  return (
    <Settings
      pages={[
        {
          title: t('settings:profile'),
          to: '/',
          icon: 'user',
          component: () => <Profile />,
        },
      ]}
    />
  );
};

export default EmployeeSettings;
