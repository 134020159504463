import React, { FC } from 'react';

interface Props {
  width?: string;
}

const Skeleton: FC<Props> = ({ width = '100%' }) => {
  return (
    <div
      className='bg-gray-200 rounded h-6 animate-pulse'
      style={{ width }}
      aria-hidden
    />
  );
};

export default Skeleton;
