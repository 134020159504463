import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Segment, DisplayDetails } from '@tg/core/components';
import { Contract } from 'src/types';
import { formatDate } from '@tg/core/utils/datetimeHelpers';

interface Props {
  data: Contract;
  loading: boolean;
}

const ContractCard: FC<Props> = ({ data, loading }) => {
  const { t } = useTranslation(['dashboard', 'forms']);

  const { employer, job_title, start_date, end_date } = data || {};

  const contractDetails = [
    {
      title: t('forms:labels.employer.name'),
      value: employer?.name,
      width: '33.333%',
    },
    {
      title: t('forms:labels.contract.job_title'),
      value: job_title,
      width: '33.333%',
    },
    {
      title: t('forms:labels.contract.start_date'),
      value: formatDate({ dateStr: start_date }),
      width: end_date ? '50%' : '33.333%',
    },
  ];
  if (end_date) {
    contractDetails.push({
      title: t('forms:labels.contract.end_date'),
      value: formatDate({ dateStr: end_date }),
      width: '50%',
    });
  }
  return (
    <Segment heading={t('page_titles:contract')}>
      <DisplayDetails details={contractDetails} />
    </Segment>
  );
};

export default ContractCard;
