import React, { FC } from 'react';
import { Form as SemanticForm } from 'semantic-ui-react';
import LoadingSpinner from '../../atoms/LoadingSpinner';

interface Props {
  onSubmit: () => void;
  isFetching?: boolean;
}

const Form: FC<Props> = ({ children, onSubmit, isFetching = false }) => {
  return (
    <SemanticForm noValidate onSubmit={onSubmit}>
      <div className='relative'>
        {isFetching ? (
          <div className='absolute inset-0 flex items-center justify-center my-4 bg-white bg-opacity-25'>
            <LoadingSpinner />
          </div>
        ) : (
          children
        )}
      </div>
    </SemanticForm>
  );
};

export default Form;
