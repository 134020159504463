import { useState, useEffect } from 'react';
import { fromEvent } from 'rxjs';
import { auditTime } from 'rxjs/operators';

/**
 * Gets the window width and height for faux media queries in JS
 * Taken from https://usehooks.com/useWindowSize/
 */
export default () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function getWindowSize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Call handler right away so state gets updated with initial window size
    getWindowSize();

    // Subscribe to the window resize event, throttled (auditTime is more of a throttle than throttle in rxjs)
    const resizeObservable = fromEvent(window, 'resize').pipe(auditTime(250));
    const resizeSubscription = resizeObservable.subscribe(getWindowSize);

    // Remove event listener on cleanup
    return () => resizeSubscription.unsubscribe();
  }, []);

  return windowSize;
};
