import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Container = ({ children, width }) => {
  return (
    <div
      className={classNames('w-full mx-auto px-5', {
        'max-w-screen-2xl': width === 'xl',
        'max-w-screen-xl': width === 'lg',
        'max-w-screen-lg': width === 'md',
      })}
    >
      {children}
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node,
  width: PropTypes.oneOf(['xl', 'lg', 'md']),
};

Container.defaultProps = {
  children: null,
  width: 'lg',
};

export default Container;
