import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Input, Flag } from 'semantic-ui-react';

/**
 * Removing the 'search' prop breaks styling and a few of the
 * nice interactions so we pass it a search function that does nothing
 * instead
 */
const dummySearch = options => {
  return options;
};

const InputPhone = ({
  codeOptions,
  onChange,
  defaultValue,
  placeholder,
  disabled,
}) => {
  // const [code, setCode] = useState(defaultValue.code);
  const [country_code, setCountryCode] = useState(defaultValue?.country_code);
  const [phone, setPhone] = useState(defaultValue?.phone || undefined);

  /**
   * Format as per semantic-ui Dropdown component
   */
  const options = codeOptions.map(({ id, value, flag, countryName, code }) => {
    return {
      key: id,
      value,
      flag,
      code: `+${code}`,
      text: `${countryName} (+${code})`,
    };
  });

  const [selectedCountry, setSelectedCountry] = useState(undefined);
  useEffect(() => {
    const country = options.find(({ value }) => value === country_code);
    setSelectedCountry(country);
  }, [codeOptions, country_code]);

  /**
   * When the user changes the values, emit them to the parent
   */
  useEffect(() => {
    if (selectedCountry || phone) {
      onChange({
        phone,
        country_code: selectedCountry?.value,
        code: selectedCountry?.code,
      });
    }
  }, [selectedCountry, phone]);

  return (
    <Input
      fluid
      label={
        <Dropdown
          compact
          search={dummySearch}
          closeOnChange
          autoComplete='off'
          onChange={(_, { value }) => {
            setCountryCode(value);
          }}
          defaultValue={country_code}
          disabled={disabled}
          trigger={
            selectedCountry ? (
              <span>
                <Flag name={selectedCountry.flag} />
                {selectedCountry.code}
              </span>
            ) : (
              ''
            )
          }
          options={options}
          className='input-phone'
        />
      }
      labelPosition='left'
      placeholder={placeholder.phone}
      defaultValue={phone}
      onChange={(_, { value }) => {
        setPhone(value);
      }}
      disabled={disabled}
    />
  );
};

InputPhone.propTypes = {
  codeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      flag: PropTypes.string.isRequired,
      countryName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func,
  defaultValue: PropTypes.shape({
    phone: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    country_code: PropTypes.string.isRequired,
  }),
  placeholder: PropTypes.shape({
    code: PropTypes.string,
    phone: PropTypes.string,
  }),
  disabled: PropTypes.bool,
};

InputPhone.defaultProps = {
  onChange: f => f,
  defaultValue: undefined,
  placeholder: { code: 'Code', phone: 'Phone' },
  disabled: false,
};

export default InputPhone;
