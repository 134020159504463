import { object } from 'yup';
import {
  address,
  address_with_same_as,
  employer,
} from '@tg/core/components/systems/Form/validations';

export default object().shape({
  registered_address: address,
  primary_address: address_with_same_as,
  employer,
});
