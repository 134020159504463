import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Tooltip, Button, Heading } from '@tg/core/components';
import { useTranslation } from 'react-i18next';

const CalendarControls = ({ dispatch, month, year }) => {
  const { t } = useTranslation(['calendar']);

  return (
    <>
      <Tooltip
        content={
          <span className='text-sm'>{t('calendar:previous_month')}</span>
        }
      >
        <button
          type='button'
          onClick={() => dispatch({ type: 'decrementMonth' })}
          aria-label='Prev'
          className='mr-0.5 w-8 h-8 flex items-center justify-center focus:outline-none hover:bg-gray-200 focus:ring rounded-full'
        >
          <div className='relative top-px  text-gray-600'>
            <Icon name='chevron left' />
          </div>
        </button>
      </Tooltip>

      <Tooltip
        content={<span className='text-sm'>{t('calendar:next_month')}</span>}
      >
        <button
          type='button'
          onClick={() => dispatch({ type: 'incrementMonth' })}
          aria-label='Next'
          className='w-8 h-8 flex items-center justify-center focus:outline-none hover:bg-gray-200 focus:ring rounded-full'
        >
          <div className='relative top-px left-px text-gray-600'>
            <Icon name='chevron right' />
          </div>
        </button>
      </Tooltip>

      <div className='ml-4'>
        <Heading level='h4' element='h4' zeropad>
          {t(`dateTime:months.${month}.full`)} {year}
        </Heading>
      </div>

      <div className='ml-auto'>
        <Button
          onClick={() => dispatch({ type: 'setToToday' })}
          color='tertiary'
          size='small'
        >
          {t('calendar:today')}
        </Button>
      </div>
    </>
  );
};

CalendarControls.propTypes = {
  dispatch: PropTypes.func.isRequired,
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
};

CalendarControls.defaultProps = {};

export default CalendarControls;
