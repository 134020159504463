import React, { FC } from 'react';
import { Segment } from '@tg/core/components';
import authBgPng from '@tg/core/assets/images/auth_bg.png';

const AuthLayout: FC = ({ children }) => {
  return (
    <div className='Auth h-screen bg-cover' data-cy='auth-wrapper'>
      <div className='p-5 -mt-10 sm:-mt-16 md:pt-10 md:pb-5 md:pl-20 bg-right bg-no-repeat'>
        <div className='md:flex items-start justify-center max-w-screen-3xl mx-auto'>
          <div className='w-full md:w-3/5 lg:w-1/2'>
            <Segment size='lg'>
              <div className='flex justify-center items-center'>
                <div className='w-full sm:w-96'>{children}</div>
              </div>
            </Segment>
          </div>
          <div className='hidden md:block w-1/2'>
            <img alt='' src={authBgPng} className='w-full max-w-2xl' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
