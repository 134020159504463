import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, ButtonBar } from '@tg/core/components';
import { object } from 'yup';
import { useTranslation } from 'react-i18next';
import { jobTitle } from '@tg/core/components/organisms/SalaryList/Validation';

const Component = ({ data, onSubmit, onCancel }) => {
  const { t } = useTranslation(['job_titles']);

  const { getFieldProps, handleSubmit, errors } = Form.useForm({
    validationSchema: object().shape({
      job_title: jobTitle,
    }),
  });

  const { effective_date, job_title } = data();

  return (
    <Modal title={t('job_titles:add_job_title')} onClose={onCancel} isOpen>
      <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
        <Modal.Content>
          <Form.TextField
            {...getFieldProps({
              id: 'job_title',
              name: 'job_title',
            })}
            defaultValue={job_title}
            required
          />
          <div style={{ width: '225px' }}>
            <Form.DateField
              {...getFieldProps({
                id: 'job_title',
                name: 'effective_date',
              })}
              defaultValue={effective_date}
              required
            />
          </div>
        </Modal.Content>
        <ButtonBar>
          <div className='space-x-2'>
            <Button color='secondary' onClick={onCancel}>
              Cancel
            </Button>
            <Button type='submit'>Save</Button>
          </div>
        </ButtonBar>
      </Form>
    </Modal>
  );
};

Component.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  data: PropTypes.func,
};

Component.defaultProps = {
  data: () => ({
    effective_date: null,
    gross_salary: null,
    currency: {
      id: null,
    },
  }),
};

export default Component;
