/* eslint-disable import/prefer-default-export */
import i18n from '@tg/core/localisation/i18n';
import { Currency } from 'src/types';
import { formatNumber } from './numberHelpers';
import symbols from './currencySymbols';

export const formatValue = ({
  value,
  currency,
  showName = true,
  type,
  subtype,
}: {
  value: number | string;
  currency: Currency;
  showName?: boolean;
  type?: string;
  subtype?: string;
}): string => {
  const symbol = symbols[currency.code] || '';
  const formattedValue = formatNumber(value, subtype);
  const currencyName = i18n.t(`collections:currencies.${currency.code}`);

  const parts = [`${type !== 'salary' ? symbol : ''}${formattedValue}`];
  parts.push(showName ? currencyName : currency.code);

  return parts.join(' ');
};
