import React, { useState, useEffect } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { useResource } from '@tg/core/hooks';
import {
  Container,
  PrivateRoute,
  ErrorBoundary,
  LoadingSpinner,
} from '@tg/core/components';
import Contract from './EmployeeContract';
import ContractPayslips from './EmployeeContractPayslips';
import ContractBenefits from './EmployeeContractBenefits';
import Settings from './EmployeeSettings';
import Dashboard from './Dashboard';
import TimeOff from '../TimeOff/Employee/TimeOff';

const EmployeeRoutes = () => {
  const { data } = useResource(
    {
      url: `employees/contracts`,
    },
    true,
  );

  // Get the first contracts ID, this is the users current contract
  const [contractId, setContractId] = useState(null);
  useEffect(() => {
    if (data) {
      setContractId(data[0].id);
    }
  }, [data]);

  if (!contractId) {
    return (
      <div className='absolute inset-0 flex items-center justify-center my-4 bg-white bg-opacity-25'>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <Container width='md'>
        <ErrorBoundary>
          <Switch>
            <PrivateRoute
              exact
              path='/'
              component={() => <Dashboard contractId={contractId} />}
            />
            <PrivateRoute
              path='/payslips'
              component={() => <ContractPayslips contractId={contractId} />}
            />
            <PrivateRoute
              path='/contracts'
              component={() => <Contract contractId={contractId} />}
            />
            <PrivateRoute
              path='/benefits'
              component={() => <ContractBenefits contractId={contractId} />}
            />
            <PrivateRoute
              path='/time-off'
              component={() => <TimeOff contractId={contractId} />}
            />
            <PrivateRoute path='/settings' component={Settings} />
            <Route path='/profile'>
              <Redirect to='/settings' />
            </Route>
            <Route path='*'>
              <Redirect to='/not-found' />
            </Route>
          </Switch>
        </ErrorBoundary>
      </Container>
    </>
  );
};

export default EmployeeRoutes;
