import React from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '@tg/core/store/modules/session';
import { useResource } from '@tg/core/hooks';
import {
  Form,
  Segment,
  LanguageSelector,
  Button,
  ButtonBar,
} from '@tg/core/components';
import { useTranslation } from 'react-i18next';
import validationSchema from './validation';

const OfficerProfile = () => {
  const { t } = useTranslation(['forms', 'profile']);

  const dispatch = useDispatch();
  const { data, patchResource, isFetching, errors } = useResource(
    { url: 'officers' },
    true,
  );

  const onSuccess = response => {
    dispatch(actions.fetchUserSuccess(response.data.user));
  };

  const onSubmit = formData => {
    patchResource({ formData, onSuccess });
  };

  const { handleSubmit, getFieldProps } = Form.useForm({
    validationSchema,
  });

  const { user } = data || {};
  const { first_name, last_name, email, phone, job_title } = user || {};

  return (
    <>
      <div className='mb-8' data-cy='officer-profile'>
        <Segment heading={t('profile:my_profile')} flush>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            isFetching={isFetching}
            errors={errors}
          >
            <div className='grid gap-x-6 grid-cols-2 gap-4 px-4 mb-6'>
              <Form.TextField
                {...getFieldProps({ id: 'officer', name: 'first_name' })}
                defaultValue={first_name}
                required
              />
              <Form.TextField
                {...getFieldProps({ id: 'officer', name: 'last_name' })}
                defaultValue={last_name}
                required
              />
              <Form.TextField
                {...getFieldProps({ id: 'officer', name: 'job_title' })}
                defaultValue={job_title}
                required
              />
              <Form.TextField
                {...getFieldProps({ id: 'officer', name: 'email' })}
                defaultValue={email}
                disabled
              />
              <Form.PhoneField
                {...getFieldProps({ id: 'officer', name: 'phone' })}
                defaultValue={phone}
                required
              />
            </div>

            <ButtonBar>
              <Button type='submit'>{t('forms:buttons.save')}</Button>
            </ButtonBar>
          </Form>
        </Segment>
      </div>
      <div className='mb-8'>
        <Segment heading={t('profile:language')}>
          <LanguageSelector
            onChange={lang => {
              patchResource({
                formData: {
                  officer: {
                    preferences: {
                      user_language: lang,
                    },
                  },
                },
              });
            }}
          />
        </Segment>
      </div>
    </>
  );
};

export default OfficerProfile;
