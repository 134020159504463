import React, { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useCombinedRefs } from '@tg/core/hooks';

interface Props {
  onDrop: (files: File[]) => void;
  multiple?: boolean;
  disabled?: boolean;
  accept?: boolean;
}

const InputFile: FC<Props> = React.forwardRef(function InputFile(
  { onDrop, multiple = false, disabled = false },
  ref,
) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled,
  });
  const { t } = useTranslation(['forms']);

  const { ref: inputRef, ...inputProps } = getInputProps();

  return (
    <div {...getRootProps()}>
      <input
        {...inputProps}
        multiple={multiple}
        ref={useCombinedRefs(inputRef, ref)}
      />
      <div className='p-4 border-dashed border-2 border-gray-500 bg-gray-100 rounded text-center'>
        {isDragActive
          ? t('forms:file_upload.dragging')
          : t('forms:file_upload.default')}
      </div>
    </div>
  );
});

export default InputFile;
