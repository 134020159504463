import React, { FC } from 'react';
import { InputIdentifiers, LabelledInput } from '@tg/core/components';
import { Control, Controller } from 'react-hook-form';
import { InputIdentifierValue } from '@tg/core/components/molecules/InputIdentifiers/InputIdentifiers';

interface Props {
  defaultValue: InputIdentifierValue[];
  getFieldProps: unknown;
  formContext: 'employee' | 'employer';
  domicileId: string;
  control: Control;
  name: string;
  errors: unknown;
  id: string;
  disabled?: boolean;
}

const IdentifiersField: FC<Props> = ({
  defaultValue,
  domicileId,
  formContext,
  control,
  name,
  errors,
  id,
  disabled = false,
  // required,
}) => {
  const fieldId = `${id}.${name}`;

  return (
    <Controller
      render={props => (
        <InputIdentifiers
          formContext={formContext}
          domicileId={domicileId}
          errors={errors}
          defaultValue={defaultValue}
          render={({ label, id: inputId, children, error, required }) => (
            <LabelledInput
              label={label}
              id={inputId}
              error={error}
              required={required}
            >
              {children}
            </LabelledInput>
          )}
          {...props}
        />
      )}
      control={control}
      name={fieldId}
      disabled={disabled}
      // required={required}
    />
  );
};

export default IdentifiersField;
