import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Form, Button, Message } from '@tg/core/components';
import { object, string } from 'yup';
import { useResource } from '@tg/core/hooks';

const InvalidToken = () => {
  const { t } = useTranslation(['auth', 'forms']);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [messageEmail, setMessageEmail] = useState('');

  const validationSchema = object().shape({
    invalid_token: object().shape({
      email: string().email().required(),
    }),
  });

  const { handleSubmit, getFieldProps } = Form.useForm({ validationSchema });

  const { postResource } = useResource({ url: `auth/request-activation` });

  const onSubmit = values => {
    setShowSuccessMessage(false);
    const { email } = values.invalid_token;

    postResource({
      formData: {
        email,
      },
      onSuccess: () => {
        setShowSuccessMessage(true);
        setMessageEmail(email);
      },
    });
  };

  return (
    <>
      <Heading level='h1'>{t('activate.title')}</Heading>
      <p>{t('auth:invalid_token.description')}</p>
      <p>{t('auth:invalid_token.enter_email')}</p>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.TextField
          {...getFieldProps({ id: 'invalid_token', name: 'email' })}
          required
        />
        <Button type='submit'>{t('forms:buttons.submit')}</Button>
      </Form>

      {showSuccessMessage && (
        <div className='mt-4'>
          <Message type='success'>
            <p
              /* eslint-disable react/no-danger */
              dangerouslySetInnerHTML={{
                __html: t('auth:invalid_token.reset_success', {
                  interpolation: { escapeValue: false },
                  email: messageEmail,
                }),
              }}
              /* eslint-enable react/no-danger */
            />
          </Message>
        </div>
      )}
    </>
  );
};

export default InvalidToken;
