import React, { FC } from 'react';
import { Control } from 'react-hook-form';
import TextField from '../../Fields/TextField';
import CollectionField from '../../Fields/CollectionField';
import IdentifiersField from '../../Fields/IdentifiersField';

interface Props {
  defaultValues?: unknown;
  getFieldProps: unknown;
  control: Control;
  domicileId: string;
}
const Employer: FC<Props> = ({
  defaultValues,
  getFieldProps,
  control,
  domicileId,
}) => {
  return (
    <>
      <div className='grid gap-x-4 grid-cols-2'>
        <div className='col-span-2 mb-4'>
          <TextField
            {...getFieldProps({ id: 'employer', name: 'name' })}
            required
            defaultValue={defaultValues?.name}
          />
        </div>

        {/* e.g. 'Company Number', dependany on domicile */}
        <IdentifiersField
          {...getFieldProps({ id: 'employer', name: 'identifiers' })}
          formContext='employer'
          defaultValue={defaultValues?.identifiers}
          domicileId={domicileId}
          control={control}
        />

        {/* Industry Sectory e.g. 'banking', 'construction', 'food' */}
        <CollectionField
          {...getFieldProps({ id: 'employer', name: 'industry_sector_id' })}
          resource='industry_sectors'
          control={control}
          required
          search
          defaultValue={defaultValues?.industry_sector_id}
          useName
        />
      </div>
    </>
  );
};

export default Employer;
