import React, { FC } from 'react';
import classNames from 'classnames';

interface Props {
  url: string;
  size?: 'small' | 'medium';
}

const Flag: FC<Props> = ({ url, size = 'medium' }) => {
  return (
    <span
      className={classNames('block', {
        'w-6': size === 'small',
        'w-8': size === 'medium',
      })}
    >
      <span
        className='block relative h-0 overflow-hidden'
        /* 5:3 ratio */
        style={{ paddingBottom: '60%' }}
      >
        <img
          src={url}
          alt='Flag'
          className='absolute inset-0 object-cover w-full h-full'
          width='32px'
        />
        <div className='absolute inset-0 shadow-inner' />
      </span>
    </span>
  );
};

export default Flag;
