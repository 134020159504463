import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Message } from '@tg/core/components';
// import { RESOURCE_STATUS } from '@config/constants';

const AuthErrors = ({ error }) => {
  const { t } = useTranslation('auth');

  return error ? (
    <div className='mb-4'>
      <Message type='error'>{t(`errors.${error.error}`)}</Message>
    </div>
  ) : null;
};

AuthErrors.propTypes = {
  error: PropTypes.shape(),
};

AuthErrors.defaultProps = {
  error: null,
};

export default AuthErrors;
