import React, { FC } from 'react';

interface Props {
  items: string[];
}

/**
 * A list laid out inline with seperators.
 * e.g. one | two | three
 */
const ListInline: FC<Props> = ({ items }) => {
  const sanitisedList = items.filter(i => !!i);

  return (
    <ul>
      {sanitisedList.map((item, index) => {
        const isLast = index === sanitisedList.length - 1;
        return (
          <li key={item} className='inline-block'>
            {item}
            {!isLast && <span className='mx-1.5'>&#183;</span>}
          </li>
        );
      })}
    </ul>
  );
};

export default ListInline;
