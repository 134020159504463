/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from 'react';
import type { FC, ReactElement } from 'react';
import { Input } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Identifier } from 'src/types';
import { useAppSelector } from '@tg/core/hooks';

// The shape of identifier this component deals with
export interface InputIdentifierValue {
  code: Identifier['code'];
  name: Identifier['name'];
  value: Identifier['value'];
}

interface Props {
  formContext: 'employer' | 'employee';
  domicileId: string;
  defaultValue: InputIdentifierValue[];
  onChange: (val: InputIdentifierValue[]) => void;
  errors?: {
    message: {
      key: string;
      values: string[];
    };
  };
  disabled: boolean;
  render: ({ label, id, required, error, children }) => ReactElement;
}

const InputIdentifiers: FC<Props> = ({
  formContext,
  domicileId,
  onChange,
  errors,
  defaultValue = [],
  disabled = false,
  render,
}) => {
  const { t } = useTranslation(['collections', 'forms']);

  /*
  identifier_types example:
  [
    { code: 'NIN', name: 'NI No.', description: 'National Insurance Number', validator: [REGEX_STRING] }
  ]

  identifiers example:
  [
    { code: 'NIN', name: 'NIN', value: 'JA 74 32 09 C' }
  ]
  */

  const domicile = useAppSelector(state => {
    return state.collections?.domiciles?.byId[domicileId];
  });

  const identifier_types: Identifier[] = useMemo(
    () => (domicile ? domicile[`${formContext}_identifiers`] : []),
    [domicile, formContext],
  );

  const [identifiers, setIdentifiers] = useState<Identifier[]>(
    identifier_types.map((identifier_type, index) => ({
      ...identifier_type,
      value: defaultValue[index]?.value || null,
    })),
  );

  // This can happen when a component calls setState inside useEffect,
  // but useEffect either doesn't have a dependency array, or one of
  // the dependencies changes on every render.
  useEffect(() => {
    if (identifier_types.length) {
      setIdentifiers(
        identifier_types.map((identifier_type, index) => ({
          ...identifier_type,
          value: identifiers[index]?.value || null,
        })),
      );
    }
  }, [identifier_types, setIdentifiers]);

  useEffect(() => {
    const newVal = identifiers.map(({ code, name, value }) => ({
      code,
      name,
      value,
    }));
    onChange(newVal);
  }, [identifiers]);

  return (
    <>
      {identifier_types.map((identifier_type, index) => {
        return render({
          label: t([identifier_type.description]),
          id: `input.${identifier_type.code}`,
          error:
            errors &&
            t(`forms:validations.${errors.message.key}`, errors.message.values),
          required: !disabled,
          children: (
            <Input
              id={`input.${identifier_type.code}`}
              onChange={(_, { value }) => {
                setIdentifiers(
                  identifier_types.map((x, i) =>
                    i === index ? { ...x, value } : x,
                  ),
                );
              }}
              defaultValue={identifiers[index]?.value || null}
              disabled={disabled}
              fluid
            />
          ),
        });
      })}
    </>
  );
};

export default InputIdentifiers;
