import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Heading } from '@tg/core/components';
import TextField from '../../Fields/TextField';

const BankDetails = ({ getFieldProps, defaultValues }) => {
  const { t } = useTranslation(['forms']);

  const { account_name, account_iban, account_swift } = defaultValues || {};

  return (
    <fieldset>
      <Heading element='legend' level='h4'>
        {t('forms:labels.bank_details.heading')}
      </Heading>

      <p className='mb-4'>{t('forms:labels.bank_details.description')}</p>

      <div className='grid gap-4 grid-cols-3'>
        <TextField
          {...getFieldProps({ id: 'bank_details', name: 'account_name' })}
          required
          defaultValue={account_name}
        />
        <TextField
          {...getFieldProps({ id: 'bank_details', name: 'account_iban' })}
          required
          defaultValue={account_iban}
        />
        <TextField
          {...getFieldProps({ id: 'bank_details', name: 'account_swift' })}
          required
          defaultValue={account_swift}
        />
      </div>
    </fieldset>
  );
};

BankDetails.propTypes = {
  getFieldProps: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({
    account_iban: PropTypes.string,
    account_name: PropTypes.string,
    account_swift: PropTypes.string,
  }).isRequired,
};

BankDetails.defaultProps = {};

export default BankDetails;
