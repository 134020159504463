import React, { FC } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { selectSessionUser } from '@tg/core/store/modules/session';
import { PrivateRoute, ErrorBoundary } from '@tg/core/components';
import { useAppSelector } from '@tg/core/hooks';
import { useSelector } from 'react-redux';
import { getPermission } from '@tg/core/utils/permisssionHelpers';
import permissions from '@tg/core/constant';
import WelcomePage from '../../Layouts/WelcomePage';
import OfficerWelcome from './OfficerWelcome';
import Dashboard from './Dashboard';
import EmployerWelcome from './EmployerWelcome';
import OfficerSettings from './OfficerSettings';
import Payroll from './Payroll';
import TimeOff from '../TimeOff/Officer/TimeOff';
import Contracts from './Contracts';
import Billing from './Invoices';
import Documents from './Documents';
import CreateAdministrator from './Administrator';

const OfficerRoot: FC = () => {
  const user = useAppSelector(state => selectSessionUser(state));

  const accesslist = useSelector(state => state.access.roles);

  if (user && user.company) {
    if (!user.welcome_completed)
      return (
        <WelcomePage>
          <OfficerWelcome />
        </WelcomePage>
      );
    if (!user.company.welcome_completed)
      return (
        <WelcomePage>
          <EmployerWelcome />
        </WelcomePage>
      );
    return (
      <>
        <ErrorBoundary>
          <Switch>
            <PrivateRoute exact path='/' component={Dashboard} />
            <PrivateRoute
              path={['/employees/:employeeId', '/employees']}
              component={Contracts}
            />
            <PrivateRoute path='/documents' component={Documents} />
            <PrivateRoute
              path='/administrator'
              component={() =>
                getPermission(
                  accesslist,
                  permissions.MAIN_NAV_OFFICERS_USERS_MANAGEMENT,
                ) ? (
                  <CreateAdministrator />
                ) : (
                  <Redirect to='/access-denied' />
                )
              }
            />
            <PrivateRoute path='/invoices' component={Billing} />
            <PrivateRoute path='/payroll' component={Payroll} />
            {/* <PrivateRoute path='/time-off' component={TimeOff} /> */}
            <PrivateRoute
              path='/time-off'
              component={() =>
                getPermission(
                  accesslist,
                  permissions.TIME_OFF_CALENDAR_VIEW,
                ) ? (
                  <TimeOff />
                ) : (
                  <Redirect to='/access-denied' />
                )
              }
            />
            <PrivateRoute path='/settings' component={OfficerSettings} />
            <Route path='/company'>
              <Redirect to='/settings/company' />
            </Route>
            <Route path='*'>
              <Redirect to='/not-found' />
            </Route>
          </Switch>
        </ErrorBoundary>
      </>
    );
  }

  return null;
};
export default OfficerRoot;
