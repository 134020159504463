import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Heading } from '@tg/core/components';

const AccessDenied = () => {
  const { t } = useTranslation('errors');

  return (
    <>
      <Container>
        <div className='text-center'>
          <Heading element='h1' level='h2'>
            {t('access_denied.access')}
          </Heading>
          <p>{t('access_denied.message')}</p>
        </div>
      </Container>
    </>
  );
};

export default AccessDenied;
