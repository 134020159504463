import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Segment,
  Heading,
  List,
  Avatar,
  StepsBullets,
  Skeleton,
} from '@tg/core/components';
import { Link } from 'react-router-dom';
import { Contract, Employee } from 'src/types';
import MoreModal from './MoreModal';

interface Props {
  data: Contract[];
  loading: boolean;
}

const OfficerDashboard: FC<Props> = ({ data, loading }) => {
  const { t } = useTranslation(['onboarding']);
  const [modalOpen, setModalOpen] = useState(false);

  const checkComplete = (contract: Contract) =>
    contract.employee.identity_check_completed_at &&
    contract.employee_signature_requested_at &&
    contract.employee_signed_at;

  const incomplete = data
    ? data.filter(contract => {
        if (!checkComplete(contract)) {
          return contract;
        }
        return false;
      })
    : [];

  const complete = data
    ? data.filter(contract => {
        if (checkComplete(contract)) {
          return contract;
        }
        return false;
      })
    : [];

  const getListItems = (employeeId: Employee['id'], contractData: Contract) => {
    if (!contractData) return [];

    const {
      employee: {
        user: { full_name, initials, email },
        identity_check_completed_at,
      },
      employee_signature_requested_at,
      employee_signed_at,
    } = contractData;

    const getBulletStatus = (step: number) => {
      switch (step) {
        case 1: {
          return identity_check_completed_at ? 'complete' : 'current';
        }
        case 2: {
          if (employee_signature_requested_at) {
            return 'complete';
          }
          if (identity_check_completed_at) {
            return 'current';
          }
          return null;
        }
        case 3: {
          if (employee_signed_at) return 'complete';
          if (identity_check_completed_at && employee_signature_requested_at) {
            return 'current';
          }
          return null;
        }

        default:
          throw new Error(`Step ${step} doesn't exist`);
      }
    };

    const getSubheading = () => {
      if (employee_signed_at) {
        // return t('onboarding:');
        return 'Contract Signed';
      }
      if (employee_signature_requested_at) {
        // return t('onboarding:');
        return 'Contract Sent';
      }
      if (identity_check_completed_at) {
        // return t('onboarding:');
        return 'ID Checked';
      }
      // return t('onboarding:');
      return 'Employee Created';
    };

    return [
      <Link to={`employees/${employeeId}`} className='inline-block w-full'>
        <Avatar
          size='small'
          user={{ full_name, initials, email }}
          subHeading={getSubheading()}
        />
      </Link>,
      <div className='flex justify-end mr-2'>
        <StepsBullets
          steps={[
            {
              title: t('onboarding:table_headers.data_collection'),
              status: getBulletStatus(1),
            },
            {
              title: t('onboarding:table_headers.sent_signature'),
              status: getBulletStatus(2),
            },
            {
              title: t('onboarding:table_headers.complete'),
              status: getBulletStatus(3),
            },
          ]}
        />
      </div>,
    ];
  };

  return (
    <Segment heading={t('onboarding:title')}>
      <div className='space-y-4'>
        {loading && (
          <div className='space-y-3 mb-4'>
            <Skeleton width='90%' />
            <Skeleton width='70%' />
          </div>
        )}
        {!!incomplete.length && (
          <section>
            <Heading level='h5' element='h3' zeropad>
              <span className='text-gray-500'>
                {t('onboarding:incomplete')}
              </span>
            </Heading>

            <List
              headings={[{}, { width: '70px' }]}
              noBorder
              items={[...incomplete].splice(0, 3).map(({ id, ...rest }) => ({
                id,
                columns: getListItems(id, rest),
              }))}
            />
          </section>
        )}
        {data && !data.length && (
          <div className='text-center p-4'>{t('onboarding:not_found')}</div>
        )}
        {!!complete.length && (
          <section>
            <Heading level='h5' element='h3' zeropad>
              <span className='text-gray-500'>{t('onboarding:complete')}</span>
            </Heading>
            <List
              noBorder
              items={[...complete].splice(0, 4).map(({ id, ...rest }) => ({
                id,
                columns: getListItems(id, rest),
              }))}
            />
          </section>
        )}
        {(!!incomplete.length || !!complete.length) && (
          <Button color='tertiary' fluid onClick={() => setModalOpen(true)}>
            {t('onboarding:view_all')}
          </Button>
        )}
      </div>
      <MoreModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        complete={complete}
        incomplete={incomplete}
        getListItems={getListItems}
      />
    </Segment>
  );
};

export default OfficerDashboard;
