import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Heading, Avatar, Form, Button } from '@tg/core/components';
import { mixed, object, ref, string } from 'yup';
import { ASSET_URLS } from '@tg/core/config/constants';
import AuthErrors from '../../AuthErrors';

const SetPassword = ({ user, onSubmit, apiError }) => {
  const { t } = useTranslation(['auth', 'forms']);

  const validationSchema = object().shape({
    create_password: object().shape({
      password: string().min(8).required(),
      password_again: string().oneOf([ref('password')], {
        key: 'password_match',
      }),
    }),
    accept_terms: object().shape({
      terms: mixed().required({ key: 'accept_terms.terms' }),
      privacy: mixed().required({ key: 'accept_terms.privacy' }),
    }),
  });

  const { handleSubmit, getFieldProps } = Form.useForm({ validationSchema });

  return (
    <>
      <Heading level='h1'>{t('activate.title')}</Heading>
      <div className='mb-8'>{user && <Avatar user={user} email />}</div>
      <p>{t('activate.description')}</p>

      <AuthErrors error={apiError} />

      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className='space-y-2'>
          <Form.PasswordField
            {...getFieldProps({ id: 'create_password', name: 'password' })}
            required
          />
          <Form.PasswordField
            {...getFieldProps({
              id: 'create_password',
              name: 'password_again',
            })}
            required
          />
          <div className='py-4'>
            <Form.CheckboxField
              {...getFieldProps({
                id: 'accept_terms',
                name: 'terms',
              })}
              required
              customLabel={
                /* eslint-disable jsx-a11y/label-has-associated-control */
                /* eslint-disable react/no-danger */
                <label
                  dangerouslySetInnerHTML={{
                    __html: t('forms:labels.accept_terms.terms', {
                      interpolation: { escapeValue: false },
                      terms_url: ASSET_URLS.terms_of_service,
                    }),
                  }}
                />
                /* eslint-enable jsx-a11y/label-has-associated-control */
                /* eslint-enable react/no-danger */
              }
            />

            <Form.CheckboxField
              {...getFieldProps({
                id: 'accept_terms',
                name: 'privacy',
              })}
              required
              customLabel={
                /* eslint-disable jsx-a11y/label-has-associated-control */
                /* eslint-disable react/no-danger */
                <label
                  dangerouslySetInnerHTML={{
                    __html: t('forms:labels.accept_terms.privacy', {
                      interpolation: { escapeValue: false },
                      privacy_url: ASSET_URLS.privacy_policy,
                    }),
                  }}
                />
                /* eslint-enable jsx-a11y/label-has-associated-control */
                /* eslint-enable react/no-danger */
              }
            />
          </div>
          <Button type='submit'>{t('activate.submitButton')}</Button>
        </div>
      </Form>
    </>
  );
};

SetPassword.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  apiError: PropTypes.string,
};

SetPassword.defaultProps = {
  apiError: null,
};

export default SetPassword;
