export const formatPhone = phoneObj => {
  if (!phoneObj || !Object.keys(phoneObj).length) return '-';
  return `${phoneObj.code} ${phoneObj.phone}`;
};

export const formatSalary = (num = 0, config = {}) => {
  const { precision = 2, decimal = '.', separator = ',' } = config;
  const nStr = String(Number(num).toFixed(precision));
  const dpos = nStr.indexOf('.');
  let nStrEnd = '';
  let nStrStart = nStr;
  if (dpos !== -1) {
    nStrEnd = `${decimal}${nStr.substring(dpos + 1, nStr.length)}`;
    nStrStart = nStr.substring(0, dpos);
  }
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(nStrStart)) {
    nStrStart = nStrStart.replace(rgx, `$1${separator}$2`);
  }
  return `${nStrStart}${nStrEnd}`;
};

export const formatGrossSalary = (num = '0', config = {}) => {
  const { decimal = '.', separator = ',' } = config;

  // Split the number into integer and decimal parts
  const parts = num.split('.');
  let integerPart = parts[0] || '0';
  const decimalPart = parts[1] || '';

  // Format the integer part with thousands separators
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(integerPart)) {
    integerPart = integerPart.replace(rgx, `$1${separator}$2`);
  }

  // Combine the integer and decimal parts back together
  const formattedNumber = decimalPart
    ? `${integerPart}${decimal}${decimalPart}`
    : integerPart;

  return formattedNumber;
};

export const toCamel = (str: string) =>
  str.toLowerCase().replace(/_\w/g, m => m[1].toUpperCase());

export const capitalise = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};
