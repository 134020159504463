import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Button, Message } from '@tg/core/components';
import { useResource } from '@tg/core/hooks';
import PropTypes from 'prop-types';

const ExpiredToken = ({ token }) => {
  const { t } = useTranslation(['auth', 'forms']);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const { getResource } = useResource({
    url: `auth/request-activation/${token}`,
  });

  const sendLink = () => {
    setShowSuccessMessage(false);

    getResource({
      onSuccess: () => {
        setShowSuccessMessage(true);
      },
      onError: err => {
        // eslint-disable-next-line no-console
        console.error(err);
      },
    });
  };

  return (
    <>
      <Heading level='h1'>{t('activate.title')}</Heading>
      <p>{t('auth:expired_token.description')}</p>
      {showSuccessMessage ? (
        <div className='mt-4'>
          <Message type='success'>
            <p>{t('auth:expired_token.reset_success')}</p>
          </Message>
        </div>
      ) : (
        <Button onClick={sendLink}>{t('auth:expired_token.submit')}</Button>
      )}
    </>
  );
};

ExpiredToken.propTypes = {
  token: PropTypes.string.isRequired,
};

export default ExpiredToken;
