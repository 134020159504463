import React, { FC, useEffect, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { actions } from '@tg/core/store/modules/collections';
import { SelectInput } from '@tg/core/components';
import { useAppDispatch, useAppSelector } from '@tg/core/hooks';
import { SelectOption } from '../SelectInput/SelectInput';

interface Props {
  onChange?: (val: string) => void;
  resource: 'domiciles' | 'dialcodes' | 'address_countries';
  multiple?: boolean;
  search?: boolean;
  useName?: boolean;
  clearable?: boolean;
  inputRef?: Ref<HTMLInputElement>;
  status?: boolean;
}

const CollectionInput: FC<Props> = ({
  multiple,
  resource,
  onChange,
  search,
  useName,
  status,
  ...rest
}) => {
  const { t } = useTranslation('collections');

  const collection = useAppSelector(
    state => state.collections[resource] || null,
  );
  const dispatch = useAppDispatch();
  const resourceKey = resource.match(/_countries|domiciles$/)
    ? 'countries'
    : resource;

  useEffect(() => {
    if (!collection) dispatch(actions.fetchCollection({ resource }));
  }, [resource]);

  const showFlags = resourceKey === 'countries';

  const collections = collection?.byId;
  let options;
  if (collections && status) {
    options = Object.keys(collections).map(key => {
      const { id, code, name } = collections[key];
      return {
        text: `${code} (${name})`,
        value: id,
        key: id,
        flag: showFlags ? code.toLowerCase() : null,
      } as SelectOption;
    });
  } else {
    options = Object.keys(collections).map(key => {
      const { id, code, name } = collections[key];
      return {
        text: useName ? name : t(`${resourceKey}.${code}`),
        value: id,
        key: id,
        flag: showFlags ? code.toLowerCase() : null,
      } as SelectOption;
    });
  }

  // Sort A-Z by name. Even if the backend already did this, if would only be for
  // english language, we need to sort again for other locales
  options = options.sort(({ text: textA }, { text: textB }) => {
    if (textA < textB) return -1;
    if (textA > textB) return 1;
    return 0;
  });

  return (
    collection && (
      <SelectInput
        multiple={multiple}
        search={search}
        onChange={(value: string) => {
          onChange(value);
        }}
        options={options}
        {...rest}
      />
    )
  );
};

export default CollectionInput;
