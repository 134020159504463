import React, { FC } from 'react';
import { useResource } from '@tg/core/hooks';
import {
  List,
  EmptySet,
  StaticAssetActions,
  LoadingSpinner,
} from '@tg/core/components';
import type { Document } from 'src/types';
import { useTranslation } from 'react-i18next';

interface Props {
  contractId: string;
}

interface Invoice {
  document: Document;
  id: string;
  month: number;
  visible: boolean;
  year: number;
}

const ContractInvoices: FC<Props> = ({ contractId }) => {
  const { t } = useTranslation(['payslips', 'dateTime']);

  const { data, isFetching } = useResource<Invoice[]>(
    { url: `employers/contracts/${contractId}/contractor_invoices` },
    true,
  );

  if (!data) {
    return (
      <div className='w-full flex items-center justify-center'>
        <LoadingSpinner />
      </div>
    );
  }

  const listItems = data
    .filter(({ visible }) => visible)
    .map(({ id, document, month, year }) => {
      return {
        id,
        columns: [
          `${t(`dateTime:months.${month}.full`)} ${year}`,
          <StaticAssetActions url={document.url} />,
        ],
      };
    });

  return data.length === 0 && !isFetching ? (
    <EmptySet heading='No Invoices Found' />
  ) : (
    <List
      items={listItems}
      headings={[{ text: 'Date' }, { text: '', align: 'right' }]}
    />
  );
};

export default ContractInvoices;
