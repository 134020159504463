import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useResource } from '@tg/core/hooks';
import { BenefitsList } from '@tg/core/components';

const ContractBenefits = ({ contractId }) => {
  const [page, setPage] = useState(1);

  const { data, pagination } = useResource(
    {
      url: `employers/contracts/${contractId}/benefits?page=${page}&pagesize=10`,
    },
    true,
  );

  return (
    data && (
      <BenefitsList
        collection={data}
        setPage={setPage}
        pagination={pagination}
      />
    )
  );
};

ContractBenefits.propTypes = {
  contractId: PropTypes.string.isRequired,
};

export default ContractBenefits;
