import * as Sentry from '@sentry/react';

const { SENTRY_DSN, SENTRY_ENV } = process.env;

const SENTRY_ACTIVE =
  process.env.SENTRY_ACTIVE &&
  process.env.SENTRY_ACTIVE.toLocaleUpperCase() === 'TRUE';

if (SENTRY_ACTIVE) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
  });
}

export const logException = error => {
  if (SENTRY_ACTIVE) {
    Sentry.captureException(error);
  } else {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
