import React from 'react';
import PropTypes from 'prop-types';

const Table = ({ data, headings }) => {
  // We can't use `text-${heading.align}` as purgeCSS wouldn't recognise it
  const alignCss = {
    left: 'text-left',
    right: 'text-right',
  };

  return (
    <table className='w-full'>
      <thead className='bg-gray-100 text-gray-700 text-sm leading-none'>
        <tr>
          {headings.map(heading => (
            <th
              className={`py-2 ${alignCss[heading.align || 'left']}`}
              key={heading.key}
            >
              {heading.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className='leading-2'>
        {data.map((row, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <tr key={index}>
            {headings.map(({ key, align, contentClassName }) => {
              const content = row[key];
              return (
                <td
                  className={`py-1 ${alignCss[align || 'left']} ${
                    contentClassName || ''
                  }`}
                  key={`${key + content}`}
                >
                  {content}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  headings: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      align: PropTypes.oneOf(['left', 'right']),
      contentClassName: PropTypes.string,
    }),
  ).isRequired,
};

// Table.defaultProps = {
//   actions: null,
// };

export default Table;
