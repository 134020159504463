import React, { FC, useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  getInputFormat,
  formatYMD,
  convertUTCToLocalDate,
  convertLocalToUTCDate,
} from '@tg/core/utils/datetimeHelpers';
import { Context } from '@tg/core/components';

export interface Props {
  onChange: (date: string) => void;
  value: string;
  placeholder: string;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  startDate?: Date;
  endDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  autoComplete?: string;
}

/**
 * Dates are input and output in the `YYYY-MM-DD` string format
 */
const InputDate: FC<Props> = React.forwardRef(function InputDate(
  {
    onChange,
    value,
    placeholder,
    autoComplete,
    selectsStart,
    selectsEnd,
    startDate,
    endDate,
    minDate,
    maxDate,
  },
  ref,
) {
  const [date, setDate] = useState(value && new Date(value));

  useEffect(() => {
    if (date) {
      const newDate = date ? formatYMD(date) : '';
      onChange(newDate);
    }
  }, [date, onChange]);

  const { lang } = useContext(Context);

  return (
    <DatePicker
      ref={ref}
      selected={convertUTCToLocalDate(date)}
      onChange={(val: Date) => setDate(convertLocalToUTCDate(val))}
      placeholderText={placeholder}
      dateFormat={getInputFormat(lang)}
      autoComplete={autoComplete}
      selectsStart={selectsStart}
      selectsEnd={selectsEnd}
      startDate={convertUTCToLocalDate(startDate)}
      endDate={convertUTCToLocalDate(endDate)}
      maxDate={maxDate}
      minDate={minDate}
    />
  );
});

export default InputDate;
