import axios from 'axios';

const { ROOT_API } = process.env;

export const callCancelRequest = async (url, token) => {
  const option = {
    method: 'patch',
    url: `${ROOT_API}/${url}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  let retValue;
  try {
    const response = await axios(option);
    retValue = response;
  } catch (error) {
    const { response: data } = error;
    retValue = data;
  }
  return retValue;
};
