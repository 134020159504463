import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Message,
  Heading,
  Avatar,
  List,
  Button,
  Segment,
  Skeleton,
} from '@tg/core/components';
import { Planner, PlannerEvent } from 'src/Routes/TimeOff/types';
import { addDays, isWithinInterval } from 'date-fns';
import { formatDateRange } from '@tg/core/utils/datetimeHelpers';
import { User } from 'src/types';
import { capitalise } from '@tg/core/utils/stringHelpers';

interface UpcomingEvent {
  user: User;
  event: PlannerEvent;
}

interface Props {
  loading?: boolean;
  data: Planner[];
}

const OfficerDashboardTimeOff: FC<Props> = ({ loading, data }) => {
  const { t } = useTranslation(['time_off', 'dashboard']);

  const pendingEventsCount =
    data &&
    data.reduce((acc, planner) => {
      const pending = planner.events?.filter(
        ({ status }) => status === 'pending',
      );
      return acc + pending.length;
    }, 0);

  const today = new Date();

  const next30Days: UpcomingEvent[] = data
    ? data.reduce((acc, planner) => {
        const upcoming = planner.events.filter(({ start_date }) =>
          isWithinInterval(new Date(start_date), {
            start: addDays(today, 8),
            end: addDays(today, 30),
          }),
        );
        upcoming.forEach(event => {
          if (
            event.status !== 'rejected' &&
            event.status !== 'cancellation_approved'
          ) {
            acc.push({
              user: planner?.employee?.user,
              event,
            });
          }
        });
        return acc;
      }, [])
    : [];
  const next7Days: UpcomingEvent[] = data
    ? data.reduce((acc, planner) => {
        const upcoming = planner.events.filter(({ start_date }) =>
          isWithinInterval(new Date(start_date), {
            start: today,
            end: addDays(today, 7),
          }),
        );
        upcoming.forEach(event => {
          if (
            event.status !== 'rejected' &&
            event.status !== 'cancellation_approved'
          ) {
            acc.push({
              user: planner?.employee?.user,
              event,
            });
          }
        });
        return acc;
      }, [])
    : [];

  /**
   * Sorts by start date, required for accessible dom order and for mobile view
   */
  const sortEventsByDate = (events: UpcomingEvent[]): UpcomingEvent[] => {
    function compareStartDate(a: UpcomingEvent, b: UpcomingEvent) {
      if (a.event.start_date < b.event.start_date) {
        return -1;
      }
      if (a.event.start_date > b.event.start_date) {
        return 1;
      }
      return 0;
    }

    return events.sort(compareStartDate);
  };

  return (
    <Segment heading={t('page_titles:time_off')}>
      <div
        style={{ minHeight: '160px' }}
        className='flex flex-col justify-between'
      >
        {!!pendingEventsCount && (
          <div className='mb-4'>
            <Message type='info'>
              <div className='flex justify-between'>
                <p>
                  {t('time_off:officer_dashboard.pending_requests_summary', {
                    count: pendingEventsCount,
                  })}
                </p>
                <div className='-mr-4 pl-2'>
                  <Button to='/time-off' size='small' color='tertiary'>
                    {t('time_off:approvals.view')}
                  </Button>
                </div>
              </div>
            </Message>
          </div>
        )}

        {loading ? (
          <div className='space-y-2'>
            <Skeleton width='70%' />
            <Skeleton width='40%' />
            <Skeleton width='60%' />
          </div>
        ) : (
          <>
            {!!next7Days.length && (
              <div className='mb-4'>
                <Heading level='h5' zeropad>
                  {t('dashboard:time_off.next_7_days')}
                </Heading>
                <List
                  headings={[{ text: '' }, { text: '', align: 'right' }]}
                  noBorder
                  items={sortEventsByDate(next7Days).map(({ user, event }) => ({
                    id: 'event.id',
                    columns: [
                      <Avatar
                        user={user}
                        size='small'
                        subHeading={formatDateRange({
                          start_date: event.start_date,
                          end_date: event.end_date,
                        })}
                      />,
                      <div className='text-sm text-gray-500'>
                        {capitalise(event.event_type)}
                      </div>,
                    ],
                  }))}
                />
              </div>
            )}
            {!!next30Days.length && (
              <div className='mb-4'>
                <Heading level='h5' zeropad>
                  {t('dashboard:time_off.next_30_days')}
                </Heading>
                <List
                  headings={[{ text: '' }, { text: '', align: 'right' }]}
                  noBorder
                  items={sortEventsByDate(next30Days).map(
                    ({ user, event }) => ({
                      id: 'event.id',
                      columns: [
                        <Avatar
                          user={user}
                          size='small'
                          subHeading={formatDateRange({
                            start_date: event.start_date,
                            end_date: event.end_date,
                          })}
                        />,
                        <div className='text-sm text-gray-500'>
                          {capitalise(event.event_type)}
                        </div>,
                      ],
                    }),
                  )}
                />
              </div>
            )}

            {!next30Days.length && !next7Days.length && (
              <div className='py-10 w-full flex items-center justify-center'>
                <span className='font-medium text-gray-600'>
                  {t('time_off:officer_dashboard.no_upcoming_events')}
                </span>
              </div>
            )}
          </>
        )}

        <div className='mt-4'>
          <Button to='/time-off' color='tertiary' fluid>
            {t('dashboard:view_all')}
          </Button>
        </div>
      </div>
    </Segment>
  );
};

export default OfficerDashboardTimeOff;
