import React from 'react';
import { useTranslation } from 'react-i18next';
import { useResource } from '@tg/core/hooks';
import { Segment, LanguageSelector } from '@tg/core/components';
import UserDetails from './Profile/components/UserDetails';
import EmployeeDocuments from '../../EmployeeDocuments';

const EmployeeProfile = () => {
  const { t } = useTranslation(['forms', 'profile']);

  // NOTE: this causes a console warning 'Can't perform a React state update on an unmounted component'
  // because changing the language, remounts this component before the patch has finished. It seems to
  // be ok though
  const { patchResource } = useResource({ url: `employees` });

  return (
    <>
      <div className='mb-8'>
        <UserDetails />
      </div>
      <div className='mb-8'>
        <Segment>
          <EmployeeDocuments />
        </Segment>
      </div>
      <div className='mb-8'>
        <Segment heading={t('profile:language')}>
          <LanguageSelector
            onChange={lang => {
              patchResource({
                formData: {
                  employee: {
                    preferences: {
                      user_language: lang,
                    },
                  },
                },
              });
            }}
          />
        </Segment>
      </div>
    </>
  );
};

export default EmployeeProfile;
