import React, { FC, useState, useEffect } from 'react';
import {
  Message,
  List,
  Avatar,
  Heading,
  Breadcrumbs,
  PaginationControl,
  Button,
  ButtonBar,
  Skeleton,
} from '@tg/core/components';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useHistory, Link, useParams } from 'react-router-dom';
import { useResource } from '@tg/core/hooks';
import { Contract } from 'src/types';
import { formatValue } from '@tg/core/utils/currencyHelpers';

import { formatDate } from '@tg/core/utils/datetimeHelpers';
import ExpensesModal from '../components/ExpensesModal';
import ViewReceiptModal from '../components/ViewReceiptModal';

interface Row {
  id: string;
  bonuses: string;
  commisions: string;
  completed_at: string;
  contract: Contract;
  created_at: string;
  deductions: string;
  expenses: string;
  notes: string;
  month: number;
  receipts: File[];
  submitted_at: string | null;
  year: number;
}
const OfficerPayrollSingle: FC = () => {
  const { t } = useTranslation(['page_titles', 'dateTime', 'collections']);

  const { id: submission_id } = useParams<{ id: string }>();

  const [page, setPage] = useState(1);

  const { data, isFetching, getResource, pagination } = useResource<Row[]>(
    {
      url: `employers/payroll_adjustments/${submission_id}/sheets?page=${page}`,
    },
    true,
  );

  const [month, setMonth] = useState<number>();
  const [year, setYear] = useState<number>();
  useEffect(() => {
    if (data && data.length) {
      setMonth(data[0].month);
      setYear(data[0].year);
    }
  }, [data]);

  const { data: allSheetsData } = useResource<unknown[]>(
    { url: 'employers/payroll_adjustments?pagesize=100' },
    true,
  );
  const [submittedDate, setSubmittedDate] = useState<string>(null);
  const [lockedDate, setLockedDate] = useState<string>(null);
  useEffect(() => {
    if (allSheetsData) {
      const thisSheet = allSheetsData.find(({ id }) => id === submission_id);
      setSubmittedDate(thisSheet?.submitted_at);
      setLockedDate(thisSheet?.locked_at);
    }
  }, [allSheetsData, submission_id]);

  const { patchResource: submitSheet } = useResource<Row[]>({
    url: `employers/payroll_adjustments/${submission_id}/submit`,
  });
  const history = useHistory();
  const onSubmit = () => {
    submitSheet({
      onSuccess: () => {
        history.push({ pathname: `/payroll` });
        // console.log('done');
      },
    });
  };

  if (data && !data.length) {
    return (
      <Message type='error' title='Not found'>
        <p>
          This Payroll Input either doesn&apos;t exist or contains no Employees
        </p>
        <Link to='/payroll'>Back to list</Link>
      </Message>
    );
  }

  return (
    <>
      <Helmet title={t('page_titles:payroll')} />
      <div className='mb-2'>
        <Breadcrumbs
          items={[{ to: '/payroll', text: t('page_titles:payroll') }]}
        />
      </div>
      <Heading level='h3'>
        {typeof month === 'number' &&
          year &&
          `${t(`dateTime:months.${month}.full`)} ${year}`}
      </Heading>
      <div className='mb-4'>
        {allSheetsData && submittedDate && (
          <Message type='info'>
            Last submitted on {formatDate({ dateStr: submittedDate })}
          </Message>
        )}
        {allSheetsData && lockedDate && (
          <Message type='info'>
            Locked at {formatDate({ dateStr: lockedDate })}
          </Message>
        )}
        {allSheetsData && !lockedDate && !submittedDate && (
          <Message type='warning'>
            Payroll input should be completed by the 10th of each month
          </Message>
        )}
      </div>

      {isFetching && (
        <div className='mt-4 space-y-4'>
          <Skeleton width='80%' />
          <Skeleton width='90%' />
          <Skeleton width='60%' />
        </div>
      )}

      {data && !!data.length && (
        <List
          type='payroll'
          headings={[
            { text: '' },
            { text: 'Current Salary', width: '105px' },
            { text: 'Expenses', width: '105px' },
            { text: 'Bonuses', width: '105px' },
            { text: 'Commission', width: '105px' },
            { text: '', width: '60px' },
          ]}
          items={data.map(
            ({
              id,
              contract,
              contract: {
                current_salary: { currency, approve, gross_salary },
                employee: { user, domicile },
              },
              bonuses,
              commisions,
              expenses,
              receipts,
              notes,
            }) => ({
              id,
              columns: [
                <Link to={`/employees/${contract.id}`}>
                  <Avatar user={user} size='small' subHeading={domicile.name} />
                </Link>,
                <div className='break-words'>
                  {gross_salary === '0.0' || !approve
                    ? '-'
                    : formatValue({
                        value: gross_salary,
                        currency,
                        showName: false,
                        type: 'salary',
                        subtype: 'grossSalary',
                      })}
                </div>,
                <div className='break-words'>
                  <>
                    {expenses === '0.0'
                      ? '-'
                      : formatValue({
                          value: expenses,
                          currency,
                          showName: false,
                          type: 'salary',
                        })}
                  </>
                  <div className='flex justify-between text-sm'>
                    {!!receipts.length && (
                      <>
                        <ViewReceiptModal
                          receipts={receipts}
                          userFullName={user.full_name}
                          emp_id={id}
                          sub_id={submission_id}
                          getList={() => getResource()}
                        />
                      </>
                    )}
                  </div>
                </div>,
                <div className='break-words'>
                  {bonuses === '0.0'
                    ? '-'
                    : formatValue({
                        value: bonuses,
                        currency,
                        showName: false,
                        type: 'salary',
                      })}
                </div>,
                <div className='break-words'>
                  {commisions === '0.0'
                    ? '-'
                    : formatValue({
                        value: commisions,
                        currency,
                        showName: false,
                        type: 'salary',
                      })}
                </div>,
                <>
                  {!lockedDate && (
                    <ExpensesModal
                      payrollId={id}
                      submission_id={submission_id}
                      userFullName={user.full_name}
                      currency={currency}
                      emp_id={id}
                      defaultValues={{
                        bonuses,
                        commisions,
                        expenses,
                        gross_salary,
                        receipts: receipts || [],
                        notes: notes || '',
                      }}
                      getList={() => getResource()}
                    />
                  )}
                </>,
              ],
            }),
          )}
        />
      )}
      {pagination && (
        <div className='mt-6'>
          <PaginationControl {...pagination} onPageChange={setPage} />
        </div>
      )}
      {lockedDate ? (
        <p className='mt-2'>
          <strong>
            Submissions have been locked by a Teamed administrator. Please
            contact{' '}
            <a href='mailto:payroll@teamed.global'>payroll@teamed.global</a> if
            any further changes are required.
          </strong>
        </p>
      ) : (
        <div className='mt-6'>
          <ButtonBar type='border'>
            <div className='text-right'>
              <Button
                type='button'
                onClick={() => onSubmit()}
                loading={isFetching}
              >
                Submit to Teamed
              </Button>
            </div>
          </ButtonBar>
        </div>
      )}
    </>
  );
};

export default OfficerPayrollSingle;
