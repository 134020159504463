const permissions = {
  MAIN_NAV_DASHBOARD: 'Main Nav - Dashboard',
  MAIN_NAV_EMPLOYEES: 'Main Nav - Employees',
  MAIN_NAV_TIME_OFF: 'Main Nav - Time Off',
  MAIN_NAV_PAYROLL: 'Main Nav - Payroll',
  MAIN_NAV_INVOICES: 'Main Nav - Invoices',
  MAIN_NAV_DOCUMENTS: 'Main Nav-Documents',
  MAIN_NAV_OFFICERS_USERS_MANAGEMENT: 'Main Nav - Officers/Users Management',
  DASHBOARD_WELCOME_CARD: 'Dashboard - Welcome card',
  DASHBOARD_TIME_OFF_CARD: 'Dashboard - Time off card',
  DASHBOARD_LATEST_INVOICE_CARD: 'Dashboard - Latest invoice Card',
  DASHBOARD_LOCALIZATION_CARD: 'Dashboard - Localization card',
  DASHBOARD_EMPLOYEES_CARD: 'Dashboard - Employees card',
  DASHBOARD_STAFF_ONBOARDING_CARD: 'Dashboard - Staff Onboarding card',
  EMPLOYEES_ADD_EMPLOYEE: 'Employees - Add Employee',
  EMPLOYEES_VIEW_EMPLOYEES_LIST: 'Employees - View Employees list',
  EMPLOYEES_EMPLOYEE_DETAILS_PROFILE: 'Employees - Employee details: Profile',
  EMPLOYEES_EMPLOYEE_DETAILS_CONTRACT: 'Employees - Employee details: Contract',
  EMPLOYEES_EMPLOYEE_DETAILS_CONTRACT_VIEW_DOWNLOAD:
    'Employees - Employee details: Contract view/Download',
  EMPLOYEES_SALARY_HISTORY: 'Employees - Salary History',
  EMPLOYEES_CONTRACT_JOB_TITLE: 'Employees - Contract Job Title',
  EMPLOYEES_EMPLOYEE_DETAILS_BENEFITS: 'Employees - Employee details: Benefits',
  EMPLOYEES_EMPLOYEE_DETAILS_PAYSLIPS: 'Employees - Employee details: Payslips',
  EMPLOYEES_EMPLOYEE_DETAILS_PAYSLIPS_VIEW_DOWNLOAD:
    'Employees - Employee details: Payslips view/Download',
  DOCUMENTS_DOWNLOAD: 'Documents-Download',
  EMPLOYEES_SEARCH: 'Employees - Search',
  EMPLOYEES_ADVANCED_SEARCH: 'Employees -  Advanced Search',
  TIME_OFF_PENDING_REQUESTS_CARD: 'Time off - Pending Requests card',
  TIME_OFF_CALENDAR_VIEW: 'Time Off - Calendar view',
  PAYROLL_MONTHLY_LIST_VIEW: 'Payroll - Monthly list view',
  PAYROLL_EMAIL_RECIPIENTS: 'Payroll - Email Recipients',
  PAYROLL_MONTHLY_DETAIL_VIEW_EDIT_AND_SUBMIT_ABILITY:
    'Payroll - Monthly detail view (w/edit and Submit ability)',
  INVOICES_LIST_VIEW_MONTHLY: 'Invoices - List view (monthly)',
  INVOICES_VIEW_DOWNLOAD: 'Invocies - View/Download',
  INVOICES_EMAIL_RECIPIENTS: 'Invoices - Email Recipients',
  MANAGER_USERS_ADMINISTRATORS_VIEW_USERS_ADMINS:
    'Manager Users/Administrators - View Users/Admins ',
  MANAGER_USERS_ADMINISTRATORS_ADD_USERS:
    'Manager Users/Administrators - Add Users',
  VIEW_PROFILE: 'View Profile',
  NOTIFICATIONS: 'Notifications',
};

export default permissions;
