import i18n from '@tg/core/localisation/i18n';
import permissions from '@tg/core/constant';
import { getPermission } from '@tg/core/utils/permisssionHelpers';

const getPageTitle = page => i18n.t(`page_titles:${page}`);

const getRoutes = (role, accesslist) => {
  const routes = {
    employee: [
      { to: '/payslips', text: getPageTitle('payslips') },
      { to: '/time-off', text: getPageTitle('time_off') },
      { to: '/contracts', text: getPageTitle('contract') },
      { to: '/benefits', text: getPageTitle('benefits') },
    ],
    officer: [
      { to: '/employees', text: getPageTitle('employees') },
      ...(getPermission(accesslist, permissions.TIME_OFF_CALENDAR_VIEW)
        ? [{ to: '/time-off', text: getPageTitle('time_off') }]
        : []),
      { to: '/payroll', text: getPageTitle('payroll') },
      ...(getPermission(accesslist, permissions.MAIN_NAV_INVOICES)
        ? [{ to: '/invoices', text: getPageTitle('invoices') }]
        : []),
      ...(getPermission(accesslist, permissions.MAIN_NAV_DOCUMENTS)
        ? [{ to: '/documents', text: getPageTitle('documents') }]
        : []),

      ...(getPermission(
        accesslist,
        permissions.MAIN_NAV_OFFICERS_USERS_MANAGEMENT,
      )
        ? [{ to: '/administrator', text: getPageTitle('Administrator') }]
        : []),
    ],
  };
  return routes[role];
};

export default getRoutes;
