export default {
  AUD: '$',
  CAD: '$',
  CZK: 'Kč', // post number
  EUR: '€',
  GBP: '£',
  HRK: 'kn', // post number
  INR: '₹',
  PLN: 'zł', // post number
  RON: 'L',
  TRY: '₺',
  USD: '$',
  ZAR: 'R ',
};
