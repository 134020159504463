import React, { FC, Fragment } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Icon } from '@tg/core/components';

export interface BreadcrumbItem {
  to: LinkProps['to'];
  text: string;
}

interface Props {
  items: BreadcrumbItem[];
}

const getItem = (item: BreadcrumbItem) => {
  const { to, text } = item;
  if (to) {
    return <Link to={to}>{text}</Link>;
  }
  return <span>{text}</span>;
};

const Breadcrumbs: FC<Props> = ({ items }) => {
  return (
    <nav
      className='leading-none flex items-center text-sm font-medium'
      aria-label='Breadcrumb'
    >
      {items.map((item, index) => (
        <Fragment key={item.text}>
          {getItem(item)}
          {index + 1 !== items.length && (
            <span className='mx-3 mt-0.5 text-xs leading-1 '>
              <Icon name='chevron right' />
            </span>
          )}
        </Fragment>
      ))}
    </nav>
  );
};

export default Breadcrumbs;
