import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Segment, Skeleton, Button } from '@tg/core/components';
import type { Payslip } from 'src/types';
import { getActiveStorageUrl, getDownloadUrl } from '@tg/core/utils/urlHelpers';

interface Props {
  payslip: Payslip;
  loading: boolean;
}

const LatestPayslip: FC<Props> = ({ payslip, loading }) => {
  const { t } = useTranslation(['dashboard']);

  return (
    <Segment heading={t('dashboard:payslips.title')}>
      {loading && (
        <div className='space-y-4 mb-4'>
          <Skeleton />
        </div>
      )}
      {!loading && !payslip && (
        <div className='text-center p-4'>
          {t('dashboard:payslips.not_found')}
        </div>
      )}
      {payslip && (
        <div className='-mt-3 mb-4 flex justify-between items-center'>
          <div className='text-gray-800 font-semibold text-3xl leading-relaxed'>
            {t(`dateTime:months.${payslip.month}.full`)} {payslip.year}
          </div>
          <div className='space-x-2'>
            <Button
              color='tertiary'
              size='small'
              href={getActiveStorageUrl(payslip.document.url)}
            >
              {t('dashboard:payslips.view')}
            </Button>
            <Button
              color='tertiary'
              size='small'
              href={getDownloadUrl(getActiveStorageUrl(payslip.document.url))}
            >
              {t('dashboard:payslips.download')}
            </Button>
          </div>
        </div>
      )}
      <Button color='tertiary' fluid to='/payslips'>
        {t('dashboard:payslips.view_all')}
      </Button>
    </Segment>
  );
};

export default LatestPayslip;
