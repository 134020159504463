import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Button,
  Heading,
  PaginationControl,
  SearchInput,
  LabelledInput,
  CollectionInput,
} from '@tg/core/components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getPermission } from '@tg/core/utils/permisssionHelpers';
import permissions from '@tg/core/constant';
import ContractsListItem from './ContractsListItem';
import { Checkbox, Dropdown } from 'semantic-ui-react';

const ContractsList = ({
  data,
  isFetching,
  searchTerm,
  onSearchTermChange,
  countryFilter,
  onCountryFilterChange,
  pagination,
  onPaginationChange,
  options,
  onOptionFilterChange,
}) => {
  const { t } = useTranslation(['employees', 'forms', 'search', 'page_titles']);

  const activeId = useParams().employeeId;
  const accesslist = useSelector(state => state.access.roles);

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const handleOptionFilter = key => {
    const updateList = options.map(option =>
      option.key === key ? { ...option, checked: !option.checked } : option,
    );
    onOptionFilterChange(updateList);
  };

  const optionsList = options?.map(option => (
    <Dropdown.Item key={option.key}>
      <Checkbox
        label={option.text}
        checked={option.checked}
        onChange={() => handleOptionFilter(option.key)}
      />
    </Dropdown.Item>
  ));
  const toggleFilters = () => {
    const newState = !showAdvancedFilters;
    setShowAdvancedFilters(newState);
    // Remove any applied filters
    if (newState === false) {
      onCountryFilterChange([]);
      onOptionFilterChange([
        { key: 'eor', value: 'eor', text: 'EOR', checked: true },
        {
          key: 'payroll_only',
          value: 'po',
          text: 'Payroll Only',
          checked: true,
        },
        { key: 'contractor', value: 'cnt', text: 'Contractor', checked: true },
      ]);
    }
  };

  return (
    <div>
      <Helmet title={t('page_titles:employees')} />
      <header className='pb-5 mb-5 border-b border-gray-300'>
        <div
          className='flex grid gap-2.5 items-center justify-between mb-2.5'
          style={{
            gridTemplateColumns: '1fr auto',
          }}
        >
          <Heading level='h3' zeropad element='h1'>
            {t('employees:list.heading')}
          </Heading>
          {getPermission(accesslist, permissions.EMPLOYEES_ADD_EMPLOYEE) && (
            <Button to='/employees/add'>
              {t('employees:list.add_button')}
            </Button>
          )}
        </div>

        <div>
          <div
            className='grid gap-5 mt-1'
            style={{
              gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
            }}
          >
            {getPermission(accesslist, permissions.EMPLOYEES_SEARCH) && (
              <LabelledInput
                label={t('search.label')}
                id='employee-search-input'
              >
                <SearchInput
                  defaultValue={searchTerm}
                  onChange={event => {
                    onSearchTermChange(event.target.value);
                  }}
                  placeholder={t('search.placeholder')}
                  loading={isFetching}
                  autoComplete='off'
                />
              </LabelledInput>
            )}

            {showAdvancedFilters && (
              <div>
                <LabelledInput
                  label={t('select_country.label')}
                  id='employee-country-input'
                  spreadProps={false}
                >
                  <CollectionInput
                    resource='domiciles'
                    defaultValue={countryFilter}
                    onChange={selectedIds => {
                      onCountryFilterChange(selectedIds);
                    }}
                    clearable
                    search
                    placeholder={t('select_country.placeholder')}
                    useName
                  />
                </LabelledInput>
                <div style={{ marginBlock: '10px' }}></div>
                <div
                  style={{
                    fontSize: '0.875rem',
                    lineHeight: '1.25rem',
                    marginBottom: '0.25rem',
                    color: '#1f2937',
                    fontWeight: 700,
                  }}
                >
                  Select Options
                </div>
                <Dropdown
                  search
                  selection
                  text={
                    options?.filter(item => item?.checked == true)?.length !== 0
                      ? options
                          ?.filter((item, index, arr) => item?.checked == true)
                          ?.map(itm => itm?.text)
                          ?.join(', ')
                      : 'Select options...'
                  }
                  style={{ width: '100%' }}
                >
                  <Dropdown.Menu>{optionsList}</Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>

          {getPermission(accesslist, permissions.EMPLOYEES_ADVANCED_SEARCH) && (
            <div className='text-right mt-1 text-sm'>
              <button
                onClick={toggleFilters}
                type='button'
                className='underline'
              >
                {showAdvancedFilters ? t('search:hide') : t('search:show')}
              </button>
            </div>
          )}
        </div>
      </header>

      {data && !data.length && !isFetching && (
        <div className='text-center mt-12'>{t('search.noResults')}</div>
      )}

      {data && (
        <ul data-cy='contracts-list'>
          {data.map(
            ({
              id,
              contractor,
              employee: {
                user: { full_name, initials },
                domicile,
              },
            }) => (
              <li key={id}>
                <Link to={`/employees/${id}`}>
                  <ContractsListItem
                    data={{ id, full_name, initials, domicile }}
                    active={id === activeId}
                    isContractor={contractor}
                  />
                </Link>
              </li>
            ),
          )}
        </ul>
      )}

      <div>
        {pagination && (
          <PaginationControl
            {...pagination}
            onPageChange={onPaginationChange}
          />
        )}
      </div>
    </div>
  );
};

ContractsList.propTypes = {
  data: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  searchTerm: PropTypes.string.isRequired,
  onSearchTermChange: PropTypes.func.isRequired,
  countryFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCountryFilterChange: PropTypes.func.isRequired,
  pagination: PropTypes.object,
  onPaginationChange: PropTypes.func.isRequired,
};

ContractsList.defaultProps = {
  pagination: null,
};

export default ContractsList;
