import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container } from '@tg/core/components';
import { useResource, useAppSelector } from '@tg/core/hooks';
import { Planner } from 'src/Routes/TimeOff/types';

import { selectSessionUser } from '@tg/core/store/modules/session';
import { Contract, Invoice } from 'src/types';
import { useDispatch, useSelector } from 'react-redux';
import permissions from '@tg/core/constant';
import { getPermission } from '@tg/core/utils/permisssionHelpers';
import Onboarding from './components/Onboarding';
import Employees from './components/Employees';
import TimeOff from './components/TimeOff/TimeOff';
import LatestInvoice from './components/LatestInvoice';
import Support from '../../../tgcore/components/organisms/Dashboard/Cards/Support';
import { Item as SupportCardItem } from '../../../tgcore/components/organisms/Dashboard/Cards/Support/Support';
import Hello from '../../../tgcore/components/organisms/Dashboard/Cards/Hello';
// import CompanyProfile from './components/CompanyProfile';

import dotsTopRight from './dots-top-right.svg';
import dotsBottomLeft from './dots-bottom-left.svg';

interface DomicileCount {
  count: number;
}

const OfficerDashboard: FC = () => {
  const user = useAppSelector(state => selectSessionUser(state));
  const dispatch = useDispatch();

  const getId = useSelector(state => state.access.rolesId);
  const accesslist = useSelector(state => state.access.roles);
  const KeyContact = 'Key Contact';

  const defaultRole = user?.roles?.sort((a, b) => {
    const roleA = a.name.toUpperCase();
    const roleB = b.name.toUpperCase();

    // If one of the roles is the specific role, prioritize it
    /* eslint no-else-return: "error" */
    if (roleA === KeyContact.toUpperCase()) {
      return -1;
    } else if (roleB === KeyContact.toUpperCase()) {
      return 1;
    }

    if (roleA < roleB) {
      return -1;
    }
    if (roleA > roleB) {
      return 1;
    }
    return 0;
  });

  const getDefaultRole = defaultRole && defaultRole[0]?.id;
  const seleteId = getId || getDefaultRole;

  if (!getId) {
    dispatch({
      type: 'ADD_ROLE_ID',
      payload: seleteId,
    });
  }

  /**
   * Employee Count Card
   */
  const { data: domicileCounts, isFetching: employee_loading } = useResource<
    DomicileCount[]
  >({ url: 'employers/contract_domicile_counts' }, true);
  const employee_count =
    domicileCounts &&
    domicileCounts.reduce((acc, { count }) => {
      return acc + count;
    }, 0);

  /**
   * Time Off Card
   */
  const today = new Date();
  const [plannerData, setPlannerData] = useState([] as Planner[]);

  const { data: plannersData, isFetching: plannerLoading } = useResource<
    Planner[]
  >({ url: `employers/planners` }, true);

  /**
   * Onboarding Card
   */
  const { data: contractsData, isFetching: contractsLoading } = useResource<
    Contract[]
  >(
    {
      url: `employers/contracts?pagination=false&eor=true&payroll_only=true&contractor=true`,
    },
    true,
  );

  /**
   * Invoice Card
   */
  const { data: invoiceData, isFetching: invoiceLoading } = useResource<
    Invoice[]
  >({ url: `employers/invoices` }, true);

  /**
   * Hello card
   */

  const { data: getAccessData } = useResource(
    { url: `employers/${seleteId}/permissions` },
    true,
  );

  if (getAccessData) {
    dispatch({
      type: 'ADD_ACCESS',
      payload: getAccessData,
    });
  }

  /**
   * 'Support' card
   */
  const supportItems = [
    {
      title: 'Want Teamed in another language?',
      description:
        'Did you know that Teamed is available in French as well as English?',
      button: {
        to: '/settings',
        text: 'Update Language',
      },
    },
  ] as SupportCardItem[];

  useEffect(() => {
    if (plannersData?.length) {
      const plannerYears = [] as Planner[];
      plannersData.forEach(plannerInfo => {
        plannerInfo.planner_years.forEach(plannerYear => {
          plannerYears.push({
            employee: plannerInfo.employee,
            ...plannerYear,
          });
        });
      });

      setPlannerData(plannerYears);
    }
  }, [plannersData]);

  return (
    <Container width='lg'>
      <Helmet title='Dashboard' />
      <div className='relative flex flex-col md:grid gap-4 lg:gap-6 pb-10 grid-cols-12'>
        <img
          src={dotsTopRight}
          alt=''
          className='absolute right-0 top-0 -mt-5 -mr-5 hidden md:block'
        />
        <img
          src={dotsBottomLeft}
          alt=''
          className='absolute bottom-0 left-0 hidden md:block'
        />

        <div className='col-span-6 lg:col-span-7 space-y-6'>
          <Hello user={user} />
          {getPermission(accesslist, permissions.DASHBOARD_TIME_OFF_CARD) && (
            <TimeOff data={plannerData} loading={plannerLoading} />
          )}
          {getPermission(
            accesslist,
            permissions.DASHBOARD_LATEST_INVOICE_CARD,
          ) && (
            <LatestInvoice
              invoice={invoiceData && invoiceData[0]}
              loading={invoiceLoading}
            />
          )}
          <Support items={supportItems} />
        </div>
        <div className='row-span-2 col-span-6 lg:col-span-5 space-y-6'>
          {getPermission(accesslist, permissions.DASHBOARD_EMPLOYEES_CARD) && (
            <Employees
              count={employee_count}
              loading={employee_loading}
              countries={domicileCounts}
            />
          )}
          {getPermission(
            accesslist,
            permissions.DASHBOARD_STAFF_ONBOARDING_CARD,
          ) && <Onboarding data={contractsData} loading={contractsLoading} />}
          {/* <CompanyProfile /> */}
        </div>
      </div>
    </Container>
  );
};

export default OfficerDashboard;
