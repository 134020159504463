import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Avatar, Flag, ListInline } from '@tg/core/components';

const ContractsListItem = ({
  data: { full_name, initials, domicile },
  active,
  isContractor,
}) => {
  const { t } = useTranslation(['collections', 'employees']);

  const subHeadingParts = [domicile.name];
  if (isContractor) {
    subHeadingParts.push(t('employees:contractor'));
  }

  return (
    <div className='block relative rounded-md mb-2.5 bg-white px-0 py-2.5 shadow'>
      {active && (
        <div className='bg-light-blue w-14 absolute left-0 inset-y-0 rounded-tl-md rounded-bl-md' />
      )}
      <div className='ml-8 relative'>
        <Avatar
          user={{ full_name, initials }}
          subHeading={<ListInline items={subHeadingParts} />}
        />

        <div className='absolute top-0 bottom-0 flex items-center right-4'>
          <Flag url={domicile.flag} />
        </div>
      </div>
    </div>
  );
};

ContractsListItem.propTypes = {
  data: PropTypes.shape({
    full_name: PropTypes.string,
    initials: PropTypes.string,
    domicile: PropTypes.shape({
      name: PropTypes.string,
      flag: PropTypes.string,
      code: PropTypes.string,
    }),
  }).isRequired,
  active: PropTypes.bool,
  isContractor: PropTypes.bool,
};

ContractsListItem.defaultProps = {
  active: false,
  isContractor: false,
};

export default ContractsListItem;
