import i18n from '@tg/core/localisation/i18n';
import { isValid } from 'date-fns';
import { DaysShort } from 'src/Routes/TimeOff/types';

export const convertUTCToLocalDate = (date: Date): Date => {
  if (!date) {
    return date;
  }
  const localDate = new Date(date);
  return new Date(
    localDate.getUTCFullYear(),
    localDate.getUTCMonth(),
    localDate.getUTCDate(),
  );
};

export const convertLocalToUTCDate = (date: Date): Date => {
  if (!date) {
    return date;
  }
  const utcDate = new Date(date);
  return new Date(
    Date.UTC(utcDate.getFullYear(), utcDate.getMonth(), utcDate.getDate()),
  );
};

export const handleTimeZone = (date: Date): Date => {
  let d;
  if (date.getTimezoneOffset() > 0) {
    d = convertUTCToLocalDate(date);
  } else {
    d = convertLocalToUTCDate(date);
  }
  return d;
};

export const zeroPad = (n: number): string => {
  return `00${n}`.slice(-2);
};

export const formatDate = ({
  dateStr,
  incTime = false,
  monthName = null, // 'full' | 'abbr'
  showYear = true,
  showMonth = true,
}: {
  dateStr: string;
  monthName?: 'full' | 'abbr';
  incTime?: boolean;
  showYear?: boolean;
  showMonth?: boolean;
}): string => {
  if (!dateStr) return '';

  const obj = handleTimeZone(new Date(dateStr));

  if (!isValid(obj)) return '';

  const day = zeroPad(obj.getUTCDate());
  const year = obj.getFullYear();
  const time = incTime
    ? ` ${zeroPad(obj.getHours())}:${zeroPad(obj.getMinutes())}`
    : '';

  if (!monthName)
    return `${day} / ${zeroPad(obj.getMonth() + 1)} / ${year}${time}`;

  const parts = [day];
  if (showMonth) {
    parts.push(i18n.t(`dateTime:months.${obj.getMonth()}.${monthName}`));
  }
  if (showMonth && showYear) {
    parts.push(String(year));
  }
  return parts.join(' ');
};

export const formatYMD = (date: Date): string => {
  return `${date.getUTCFullYear()}-${zeroPad(date.getUTCMonth() + 1)}-${zeroPad(
    date.getUTCDate(),
  )}`;
};

export const getInputFormat = (lang: string): string => {
  switch (lang) {
    default:
      return 'dd/MM/yyyy';
  }
};

export const formatLocaleDate = (dateStr = '1970-01-01') =>
  formatDate({ dateStr });

export const formatDateRange = ({
  start_date,
  end_date,
}: {
  start_date: string;
  end_date: string;
}): string => {
  const dates = [];
  const monthName = 'full';

  const singleDayRange = start_date === end_date || !end_date;
  const equalYears =
    new Date(start_date).getFullYear() === new Date(end_date).getFullYear();
  const equalMonths =
    equalYears &&
    new Date(start_date).getMonth() === new Date(end_date).getMonth();

  // start
  dates.push(
    formatDate({
      dateStr: start_date,
      monthName,
      showYear: singleDayRange || !equalYears,
      showMonth: singleDayRange || !equalMonths,
    }),
  );

  if (end_date && !singleDayRange) {
    dates.push(formatDate({ dateStr: end_date, monthName }));
  }

  return dates.join(' - ');
};

export const getLastDateOfMonth = ({
  year,
  month,
}: {
  year: number;
  month: number;
}): Date => {
  // get the '0' day of the **next** month, this looks back to the last day of
  // the month we are displaying
  return new Date(year, month + 1, 0);
};

export const daysOfTheWeek = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
] as DaysShort[];
