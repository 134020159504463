/**
 * This is the 'default' theme for tgCore components
 * You can set it as your apps theme using a context or
 * use another theme specific to your app
 */
export const colors = {
  black: '#1f2a37',
  white: '#FFFFFF',
  primary: '#F63855',
  secondary: '#0EBAF4',
  darkBlue: '#0d2950',
  backgroundGrey: '#F4F4F6',
  borderGrey: '#d8d8d8',
  textGrey: '#606060',
};

/**
 * Variations of the base colours
 * made using https://javisperez.github.io/tailwindcolorshades/#/?Radical%20Red=f63855&tv=1
 */
export const shades = {
  primary: {
    50: '#FFF5F7',
    100: '#FEEBEE',
    200: '#FDCDD5',
    300: '#FBAFBB',
    400: '#F97488',
    500: '#F63855',
    600: '#DD324D',
    700: '#942233',
    800: '#6F1926',
    900: '#4A111A',
  },
  secondary: {
    50: '#F3FCFE',
    100: '#E7F8FE',
    200: '#C3EEFC',
    300: '#9FE3FB',
    400: '#56CFF7',
    500: '#0EBAF4',
    600: '#0DA7DC',
    700: '#087092',
    800: '#06546E',
    900: '#043849',
  },
  green: {
    50: '#F9FCF5',
    100: '#F4FAEC',
    200: '#E2F2CF',
    300: '#D1E9B2',
    400: '#AFD979',
    500: '#8CC93F',
    600: '#7EB539',
    700: '#547926',
    800: '#3F5A1C',
    900: '#2A3C13',
  },
  black: {
    50: '#F3F4F6',
    100: '#E7EAEE',
    200: '#C3CAD3',
    300: '#9EA9B9',
    400: '#566985',
    500: '#0D2950',
    600: '#0C2548',
    700: '#081930',
    800: '#061224',
    900: '#040C18',
  },
  yellow: {
    50: '#FFFBF4',
    100: '#FFF7E8',
    200: '#FFEBC6',
    300: '#FEDFA3',
    400: '#FEC85E',
    500: '#FDB019',
    600: '#E49E17',
    700: '#986A0F',
    800: '#724F0B',
    900: '#4C3508',
  },
};

export const shadow = {
  default: '0 0 20px 0 rgba(0,0,0,0.1)',
};

export const radius = {
  default: '6px',
  button: '2em',
};
