import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon, LabelledInput } from '@tg/core/components';
import { useCombinedRefs } from '@tg/core/hooks';

const PasswordField = ({
  required,
  name,
  errors,
  id,
  translationKey,
  disabled,
  defaultValue,
  register,
  autoComplete,
}) => {
  const { t } = useTranslation('forms');

  const label = t(`labels.${translationKey}`);
  const placeholder = t(`placeholders.${translationKey}`);
  const fieldId = `${id}.${name}`;

  // Show/Hide password functionality
  const [revealPw, setRevealPw] = useState(false);
  const [cursorPos, setCursorPos] = useState(0);
  const inputRef = useRef();
  const icon = revealPw ? 'eye slash' : 'eye';

  /**
   * Each time revealPw changes, re-focus the input and restore the cursor position
   */
  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.focus();
    inputRef.current.setSelectionRange(cursorPos, cursorPos);
  }, [revealPw]);

  return (
    <LabelledInput
      label={label}
      id={fieldId}
      required={required && !disabled}
      error={
        errors && t(`validations.${errors.message.key}`, errors.message.values)
      }
      spreadProps={false}
    >
      <div className='relative'>
        <input
          id={fieldId}
          required={required}
          type={revealPw ? 'text' : 'password'}
          name={fieldId}
          placeholder={placeholder}
          defaultValue={defaultValue}
          disabled={disabled}
          ref={useCombinedRefs(inputRef, register({ required }))}
          autoComplete={autoComplete}
        />

        <button
          type='button'
          onClick={() => {
            setCursorPos(inputRef.current.selectionStart);
            setRevealPw(!revealPw);
          }}
          className='absolute right-2.5 bottom-0 top-0 cursor-pointer focus:outline-none focus:ring'
        >
          <Icon name={icon} />
        </button>
      </div>
    </LabelledInput>
  );
};

PasswordField.propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  id: PropTypes.string.isRequired,
  translationKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  register: PropTypes.any.isRequired,
  autoComplete: PropTypes.oneOf(['new-password', 'current-password']),
};

PasswordField.defaultProps = {
  required: false,
  disabled: false,
  errors: null,
  defaultValue: '',
  autoComplete: null,
};

export default PasswordField;
