import gb from './en-GB';
import fr from './fr-FR';
// import de from './de-DE';
// import es from './es-ES';

export const messages = {
  'en-GB': gb,
  'fr-FR': fr,
  // 'de-DE': de,
  // 'es-ES': es,
};
