import React from 'react';
import PropTypes from 'prop-types';
import SalaryList from '@tg/core/components/organisms/SalaryList';

const ContractSalaries = ({ contractId, current_salary }) => {
  return contractId ? (
    <SalaryList contractId={contractId} currentSalary={current_salary} />
  ) : null;
};

ContractSalaries.propTypes = {
  contractId: PropTypes.string.isRequired,
  current_salary: PropTypes.object.isRequired,
};

export default ContractSalaries;
