import { toCamel } from './stringHelpers';

type Verb = 'FETCH' | 'UPDATE' | 'CREATE' | 'DESTROY';
type NamespacedAction = string;
export interface Action {
  type: string;
  payload: {
    data?: unknown;
    meta?: ResponseMeta;
    errors?: string[];
    response?: {
      error: string;
    };
  };
}

export interface ResponseMeta {
  current_page: number | null;
  next_page: number | null;
  prev_page: number | null;
  total_count: number | null;
  total_pages: number | null;
}

export const createPrefix = (
  prefix: string,
): ((name: string) => NamespacedAction) => {
  return name => `tgweb/${prefix}/${name}`;
};
export const createAction = (type: NamespacedAction) => {
  return (payload: unknown): Action => ({ type, payload });
};

export const createActions = ({
  resource,
  verbs = [],
  prefix = '',
}: {
  resource: string;
  prefix?: string;
  verbs?: Verb[];
}): {
  [key: string]: string | ((payload: unknown) => Action);
} => {
  const p = createPrefix(prefix);
  return verbs
    .reduce((acc, verb) => {
      return [
        ...acc,
        `${verb}_${resource}`.toUpperCase(),
        `${verb}_${resource}_SUCCESS`.toUpperCase(),
        `${verb}_${resource}_FAILURE`.toUpperCase(),
      ];
    }, [])
    .reduce((acc, type) => {
      const namespaced = p(type);
      acc[type] = namespaced;
      acc[toCamel(type)] = createAction(namespaced);
      return acc;
    }, {});
};
