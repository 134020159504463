import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { InputDate, LabelledInput } from '@tg/core/components';

const DateField = ({
  required,
  name,
  errors,
  defaultValue,
  id,
  translationKey,
  control,
  customLabel,
  ...props
}) => {
  const { t } = useTranslation('forms');

  const label = customLabel || t(`labels.${translationKey}`);
  const fieldId = `${id}.${name}`;

  // eslint-disable-next-line react/prop-types
  const { ref, ...inputProps } = props;

  return (
    <LabelledInput
      id={fieldId}
      label={label}
      error={errors && t(`validations.${errors.message.key}`)}
      required={required}
    >
      <Controller
        as={<InputDate {...inputProps} />}
        control={control}
        name={fieldId}
        id={fieldId}
        placeholder={t('placeholders.contract.start_date')}
        defaultValue={defaultValue}
      />
    </LabelledInput>
  );
};

DateField.propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  id: PropTypes.string.isRequired,
  translationKey: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  customLabel: PropTypes.node,
  control: PropTypes.any.isRequired,
};

DateField.defaultProps = {
  errors: null,
  defaultValue: '',
  customLabel: null,
  required: false,
};

export default DateField;
