import React, { FC, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Logo, Container } from '@tg/core/components';
import classNames from 'classnames';
import UserMenu from './components/UserMenu';

interface HeaderLink {
  to: string;
  text: string;
}

interface Props {
  showLinks?: boolean;
  fixed?: boolean;
  links: HeaderLink[];
}

const Header: FC<Props> = ({ links, showLinks = false, fixed = true }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className='mb-10 sm:mb-16 h-16'>
      <header
        className={classNames('bg-white border-b border-gray-200', {
          fixed,
          'inset-x-0': fixed,
          'top-0': fixed,
          'z-10': fixed,
        })}
        data-cy='app-header'
      >
        <Container width='xl'>
          <div className=' flex items-center justify-between'>
            {/* <!-- Mobile menu button--> */}
            {showLinks && (
              <div className='flex items-center sm:hidden h-16 w-18'>
                <button
                  className='text-dark-blue focus:outline-none focus:ring'
                  type='button'
                  aria-expanded={mobileMenuOpen}
                  onClick={() => {
                    setMobileMenuOpen(!mobileMenuOpen);
                  }}
                  aria-label='Open main menu'
                >
                  <svg
                    className='h-10 w-10'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    aria-hidden='true'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d={
                        mobileMenuOpen
                          ? 'M6 18L18 6M6 6l12 12'
                          : 'M4 6h16M4 12h16M4 18h16'
                      }
                    />
                  </svg>
                </button>
              </div>
            )}
            <div className='flex-1 flex items-center justify-center sm:items-stretch sm:justify-start'>
              {/* Logo */}
              <div
                className='flex-shrink-0 flex items-center h-16'
                style={{ maxWidth: '140px' }}
              >
                <Link to='/' className='focus:outline-none focus-visible:ring'>
                  <Logo />
                </Link>
              </div>

              {/* Menu Items */}
              {showLinks && links && (
                <ul
                  className='hidden sm:flex sm:w-full sm:px-4 md:ml-8'
                  data-cy='main-nav-links'
                >
                  {links.map(({ to, text }) => (
                    <li
                      className='flex-1 relative'
                      style={{ maxWidth: '180px' }}
                      key={text}
                    >
                      <NavLink
                        to={to}
                        className={classNames(
                          // Tailwind Styles
                          'h-16 text-current px-3 text-sm font-medium flex items-center justify-center',
                          'hover:text-dark-blue',
                          'focus:outline-none focus-visible:ring',
                          // For the active item underline
                          'Header__link',
                        )}
                        activeClassName='Header__link--active'
                      >
                        {text}
                      </NavLink>

                      <div className='Header__link__bar h-1 bg-dark-blue mx-auto absolute inset-x-0 bottom-0' />
                    </li>
                  ))}
                </ul>
              )}
            </div>
            {showLinks && <UserMenu />}
          </div>
        </Container>

        {/* Mobile menu */}
        <div
          className={classNames('sm:hidden', {
            block: mobileMenuOpen,
            hidden: !mobileMenuOpen,
          })}
        >
          <ul className='px-2 pt-2 pb-3 space-y-1 border-t border-gray-200'>
            {links &&
              links.map(({ to, text }) => (
                <li className='flex items-center' key={text}>
                  <NavLink
                    to={to}
                    className='px-3 py-2 w-full font-medium text-current'
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {text}
                  </NavLink>
                  <div className='header-link__bar h-1 bg-dark-blue mx-auto absolute inset-x-0 bottom-0' />
                </li>
              ))}
          </ul>
        </div>
      </header>
    </div>
  );
};

export default Header;
