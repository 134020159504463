import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { EmptySet, List, PaginationControl } from '@tg/core/components';
import { formatDate } from '@tg/core/utils/datetimeHelpers';

const BenefitsList = ({ collection, setPage, pagination }) => {
  const { t } = useTranslation(['forms', 'benefits', 'collections']);

  return collection.length ? (
    <>
      <List
        headings={[
          {
            text: t('labels.employee_benefit.employee_benefit_type_id'),
            width: '200px',
          },
          { text: t('labels.employee_benefit.description') },
          { text: t('labels.contract_benefit.start_date'), width: '150px' },
        ]}
        items={collection.map(
          ({ id, start_date, name, description, benefit_type }) => ({
            id,
            columns: [
              <span className='whitespace-normal text-sm'>
                {benefit_type.name}
              </span>,
              <span className='whitespace-normal text-sm'>
                <div className='font-semibold'>{name}</div>
                <span
                  /* eslint-disable react/no-danger */
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                  /* eslint-enable react/no-danger */
                />
              </span>,
              <span className='text-sm'>
                {formatDate({ dateStr: start_date })}
              </span>,
            ],
          }),
        )}
      />
      {pagination && (
        <div>
          <PaginationControl {...pagination} onPageChange={setPage} />
        </div>
      )}
    </>
  ) : (
    <EmptySet
      heading={t('benefits:empty')}
      description={t('benefits:empty_description')}
    />
  );
};

BenefitsList.propTypes = {
  collection: PropTypes.array.isRequired,
  pagination: PropTypes.bool.isRequired,
  setPage: PropTypes.func.isRequired,
};

export default BenefitsList;
