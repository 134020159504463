import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { InputRadio } from '@tg/core/components';

const RadioField = ({
  required,
  name,
  errors,
  defaultChecked,
  radioValue,
  translationKey,
  customLabel,
  register,
}) => {
  const { t } = useTranslation('forms');

  const label = customLabel || t(`labels.${translationKey}`);
  const fieldId = `${name}.${radioValue}`;

  return (
    <Form.Field required={required} error={!!errors}>
      {/* <div> required for styling, blame semantic-ui-react  */}
      <div>
        <InputRadio
          label={label}
          id={fieldId}
          radioValue={radioValue}
          defaultChecked={defaultChecked}
          ref={register({ required })}
          name={name}
        />
        {errors && (
          <p>{t(`validations.${errors.message.key}`, errors.message.values)}</p>
        )}
      </div>
    </Form.Field>
  );
};

RadioField.propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  translationKey: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
  radioValue: PropTypes.string.isRequired,
  customLabel: PropTypes.node,
  register: PropTypes.func.isRequired,
};

RadioField.defaultProps = {
  errors: null,
  defaultChecked: false,
  customLabel: null,
  required: false,
};

export default RadioField;
