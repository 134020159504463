import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Avatar, Segment, Icon } from '@tg/core/components';
import { Link } from 'react-router-dom';
import { getWeekOfMonth } from 'date-fns';
import {
  getLastDateOfMonth,
  daysOfTheWeek,
} from '@tg/core/utils/datetimeHelpers';
import { capitalise } from '@tg/core/utils/stringHelpers';
import Events from './Events';
import PublicHolidays from './PublicHolidays';
import Controls from './Controls';
import EventDetailsModal from './EventDetailsModal';
import { CalendarData, CalendarDay, PlannerEvent, Planner } from '../../types';

export function dateReducer(
  state: {
    year: number;
    month: number;
  },
  action: {
    type: 'incrementMonth' | 'decrementMonth' | 'setToToday';
  },
): { month: number; year: number } {
  const today = new Date();

  switch (action.type) {
    case 'incrementMonth':
      return {
        month: state.month === 11 ? 0 : state.month + 1,
        year: state.month === 11 ? state.year + 1 : state.year,
      };
    case 'decrementMonth':
      return {
        month: state.month === 0 ? 11 : state.month - 1,
        year: state.month === 0 ? state.year - 1 : state.year,
      };
    case 'setToToday':
      return {
        month: today.getMonth(),
        year: today.getFullYear(),
      };
    default:
      throw new Error();
  }
}

interface CalendarProps {
  year: number;
  month: number;
  dispatch: ({ type }: { type: string }) => void;
  renderEvents: boolean; // Allows control over when to render data. Helpful when the calendar year and data year don't match
  data: CalendarData;
  loading?: boolean;
  wrapDays?: boolean;
  allowEditingEvents?: boolean;
  event_types?: any;
  isFetching?: any;
  currentYearData?: Planner;
  onEditEventClick?: (event: PlannerEvent) => void;
  approveRejectButton?: (
    {
      event,
      contractId,
      year,
    }: {
      event: PlannerEvent;
      contractId: Planner['contract_id'];
      year: Planner['year'];
    },
    onClose: () => void,
  ) => void;
  onSuccess?: (data: Planner) => void;
}

/**
 * The current month and year are controlled by the parent but this file exports
 * a reducer to help you out with updateing that.
 */
const Calendar: FC<CalendarProps> = ({
  year,
  month,
  data,
  dispatch,
  renderEvents,
  event_types,
  isFetching,
  wrapDays,
  loading,
  onEditEventClick,
  allowEditingEvents,
  approveRejectButton,
  onSuccess,
  calendarYearData,
  calendarYearsData,
  userType,
}) => {
  const { t } = useTranslation(['time_off', 'dateTime']);

  // The date on the last day is the number of days in that month
  const daysInMonth = getLastDateOfMonth({ year, month }).getDate();

  const days = [] as CalendarDay[];
  const [defaultColor, setDefaultColor] = useState(null);

  Array.from(Array(daysInMonth)).forEach((_, index) => {
    const contractedDays = [0, 1, 2, 3, 4]; // Monday to Friday
    const date = new Date(year, month, index + 1);
    const day = date.getDay();

    days.push({
      date: index + 1,
      day: daysOfTheWeek[day],
      dayTranslated: t(`dateTime:days.abbr.${day}`),
      week: getWeekOfMonth(date, {
        weekStartsOn: 1,
      }),
      contracted: contractedDays.includes(day - 1),
      isToday: date.toDateString() === new Date().toDateString(),
    });
  });

  // For the 'More Details' modal
  const [viewingEvent, setViewingEvent] = useState(
    undefined as
      | undefined
      | {
          event: PlannerEvent;
          contractId: Planner['contract_id'];
          year: Planner['year'];
        },
  );
  const [plan_year, setPlannerYear] = useState(null);

  useEffect(() => {
    if (calendarYearData && viewingEvent && userType !== 'employee') {
      const filteredCalData = calendarYearData.find(singleCalData =>
        singleCalData.planner_years.some(
          item => item.id === viewingEvent.event.planner_year_id,
        ),
      );
      const currPlYear = filteredCalData.planner_years.find(
        planeYear => planeYear.id === viewingEvent.event.planner_year_id,
      ).year;

      setPlannerYear(currPlYear);
    }
  }, [calendarYearData, viewingEvent]);

  useEffect(() => {
    if (event_types?.find(val => val.contract_id)) {
      const uniqueColor = event_types
        .reduce((acc, obj) => {
          return acc.concat(obj.planneryear_leaves);
        }, [])
        .filter(
          (planneryear_leaves, index, self) =>
            index ===
            self.findIndex(
              event => event.leave_type === planneryear_leaves.leave_type,
            ),
        );

      setDefaultColor(uniqueColor);
    } else setDefaultColor(event_types);
  }, [event_types]);

  return (
    <>
      <div className='flex mb-8'>
        <div className='w-full'>
          <div className='flex items-center mb-4' data-cy='calendar-header'>
            <Controls dispatch={dispatch} month={month} year={year} />
          </div>

          <Segment flush>
            {wrapDays && (
              <div className='grid grid-cols-7 text-right border-b border-gray-300 text-gray-500'>
                {daysOfTheWeek.map((_, index) => (
                  <div
                    key={t(`dateTime:days.abbr.${index === 6 ? 0 : index + 1}`)}
                    className='pr-4 py-1'
                  >
                    {t(`dateTime:days.abbr.${index === 6 ? 0 : index + 1}`)}
                  </div>
                ))}
              </div>
            )}
            <div className='flex relative'>
              {loading && (
                <div className='absolute top-0 left-0 p-2 text-gray-300'>
                  <Icon
                    name='circle notch'
                    size='lg'
                    className='animate-spin'
                  />
                </div>
              )}
              {!wrapDays && (
                <div className='hidden md:flex flex-col pt-11'>
                  {Object.keys(data)?.length &&
                    Object.keys(data).map(contract_id => {
                      const { user, holiday_entitlement, totalEntitlement } =
                        data[contract_id];
                      const used =
                        holiday_entitlement?.holiday !== undefined
                          ? holiday_entitlement?.holiday
                          : 0;
                      const totalDays =
                        totalEntitlement?.holiday !== undefined
                          ? totalEntitlement?.holiday
                          : 0;
                      return (
                        <div
                          className='px-2 py-4 max-w-xs border-gray-200 border-t'
                          key={contract_id}
                        >
                          <Link to={`employees/${contract_id}`}>
                            <Avatar
                              user={user}
                              size='small'
                              subHeading={`${used} of ${totalDays} days taken`}
                            />
                          </Link>
                        </div>
                      );
                    })}
                </div>
              )}
              <div
                className={classNames(
                  'md:grid md:overflow-x-auto md:overflow-y-hidden p-2 md:p-0 relative w-full',
                  {
                    'border-b': !wrapDays,
                    'md:bg-gray-300': true,
                    'gap-px': true,
                    'rounded-bl-md': wrapDays,
                    'rounded-br-md': true,
                    'rounded-tr-md': !wrapDays,
                  },
                )}
                style={
                  wrapDays
                    ? {
                        gridTemplateColumns:
                          '[Mon] 1fr [Tue] 1fr [Wed] 1fr [Thu] 1fr [Fri] 1fr [Sat] 1fr [Sun] 1fr',
                      }
                    : {
                        gridTemplateRows: `44px repeat(${
                          Object.keys(data).length
                        }, 72px)`,
                        gridTemplateColumns: `minmax(auto, 20rem) repeat(${days.length}, 50px)`,
                      }
                }
              >
                {/* Column headers (days) */}
                {days.map(
                  ({ day, dayTranslated, date, week, contracted, isToday }) => (
                    <div
                      key={date}
                      className={classNames('hidden md:block bg-white', {
                        'bg-gray-100': !contracted,
                      })}
                      style={
                        wrapDays
                          ? {
                              gridColumn: `${day}`,
                              gridRow: `${week}`,
                            }
                          : {
                              gridColumn: date + 1,
                              gridRow: '1 / -1',
                              minHeight: '120px',
                            }
                      }
                    >
                      <div
                        className={classNames(
                          'py-1 px-2 text-xs text-semibold text-gray-400',
                          {
                            'h-20 text-right': wrapDays,
                            'text-center': !wrapDays,
                            'border-b border-gray-200': !wrapDays,
                          },
                        )}
                      >
                        {!wrapDays && (
                          <span className='block'>{dayTranslated}</span>
                        )}
                        <span
                          className={classNames(
                            'inline-flex',
                            'text-center h-5 w-5 items-center justify-center rounded-full',
                            {
                              'bg-red-600 text-white ': isToday,
                            },
                          )}
                        >
                          {date}
                        </span>
                      </div>
                    </div>
                  ),
                )}

                {!loading && renderEvents && (
                  <>
                    <Events
                      month={month}
                      year={year}
                      data={data}
                      layout={wrapDays ? 'grid' : 'row'}
                      onEventClick={setViewingEvent}
                      plan_year={plan_year}
                    />
                    <PublicHolidays
                      data={data}
                      month={month}
                      year={year}
                      layout={wrapDays ? 'grid' : 'row'}
                    />
                  </>
                )}
              </div>
            </div>
          </Segment>

          <EventDetailsModal
            event={viewingEvent?.event}
            contractId={viewingEvent?.contractId}
            year={viewingEvent?.year}
            plan_year={plan_year}
            onClose={() => setViewingEvent(null)}
            onEditClick={() => onEditEventClick(viewingEvent?.event)}
            allowEditing={allowEditingEvents}
            onSuccess={onSuccess}
            approveRejectButton={
              viewingEvent &&
              approveRejectButton &&
              (onClose => approveRejectButton(viewingEvent, onClose))
            }
          />
        </div>
      </div>
      <div className='pb-8'>
        {/* Colours Key */}
        <div className='container max-w-5xl '>
          <ul className='flex flex-wrap  mb-4'>
            {isFetching?.length === 0 && event_types ? (
              defaultColor?.map(({ id, leave_type, colour_code }) => (
                <li className='mr-4 flex items-center py-2 px-2 mb-2' key={id}>
                  <span
                    className='mr-2 w-6 h-6 rounded'
                    style={{ backgroundColor: colour_code }}
                  />
                  {capitalise(leave_type)}
                </li>
              ))
            ) : (
              <span className='font-semibold'>
                {t(`time_off:event_details.no_planner`)}
              </span>
            )}
          </ul>
        </div>
        {/* Event Status Key */}
        <div className='flex pb-4'>
          <div className='mr-4'>
            <Icon name='check circle outline' />
            <span className='ml-2'>{t(`time_off:event_status.approved`)}</span>
          </div>
          <div className='mr-4'>
            <Icon name='clock outline' />
            <span className='ml-2'>{t(`time_off:event_status.pending`)}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Calendar;
