import React, { FC } from 'react';
import { Dropdown } from 'semantic-ui-react';

export interface SelectOption {
  key: string;
  text: string;
  value: string | number;
}

interface Props {
  onChange: (
    val: string | number | boolean | (string | number | boolean)[],
  ) => void;
  options: SelectOption[];
  multiple?: boolean;
  search?: boolean;
  id?: string;
  placeholder?: string;
}

const SelectInput: FC<Props> = ({
  multiple = false,
  options = [],
  onChange,
  id,
  search,
  placeholder,
  ...rest
}) => {
  return (
    <Dropdown
      multiple={multiple}
      selection
      onChange={(_, { value }) => {
        onChange(value);
      }}
      options={options}
      fluid
      id={id}
      search={search}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default SelectInput;
