import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useResource } from '@tg/core/hooks';
import { PayslipsList, PageHeader } from '@tg/core/components';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const EmployeeContractPayslips = ({ contractId }) => {
  const { t } = useTranslation(['page_titles']);
  const [page, setPage] = useState(1);

  const { data, pagination } = useResource(
    {
      url: `employees/contracts/${contractId}/payslips?page=${page}&pagesize=10`,
    },
    true,
  );

  return (
    <>
      <Helmet title={t('page_titles:payslips')} />
      <PageHeader heading={t('page_titles:payslips')} />
      {data && (
        <PayslipsList
          collection={data}
          setPage={setPage}
          pagination={pagination}
        />
      )}
    </>
  );
};

EmployeeContractPayslips.propTypes = {
  contractId: PropTypes.string.isRequired,
};

export default EmployeeContractPayslips;
