import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';

/**
 * Renders the tab header / links
 * Active tab and tab content should be managed by the parent component
 */
const Tabs = ({ items, onItemClick, activeItem }) => {
  return (
    <Menu widths={items.length} data-cy='tabs'>
      {items.map(({ text, id }) => (
        <Menu.Item
          name={id}
          key={id}
          active={activeItem === id}
          onClick={(_, { name }) => onItemClick(name)}
        >
          {text}
        </Menu.Item>
      ))}
    </Menu>
  );
};

Tabs.propTypes = {
  items: PropTypes.array,
  onItemClick: PropTypes.func.isRequired,
  activeItem: PropTypes.string,
};

Tabs.defaultProps = {
  items: [],
  activeItem: null,
};

export default Tabs;
