import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { actions } from '@tg/core/store/modules/session';
import { Form, Heading, Button, Message } from '@tg/core/components';
import { useResource } from '@tg/core/hooks';
import { object, ref, string } from 'yup';
import AuthErrors from '../AuthErrors';

const ResetPassword = () => {
  const { t } = useTranslation('auth');

  const { token } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [apiError, setApiError] = useState(null);

  const validationSchema = object().shape({
    create_password: object().shape({
      password: string().min(8).required(),
      password_again: string().oneOf([ref('password')], {
        key: 'password_match',
      }),
    }),
  });

  const { handleSubmit, getFieldProps } = Form.useForm({ validationSchema });

  const { patchResource, isFetching } = useResource({
    url: `auth/reset-password/${token}`,
  });
  const onSubmit = formData => {
    patchResource({
      formData: { ...formData.create_password },
      onSuccess: response => {
        dispatch(actions.createSession({ token: response.token }));
        history.replace({ pathname: '/' });
      },
      onError: error => {
        dispatch(actions.destroySession());
        setApiError(error.response || { error: 'unknown' });
      },
    });
  };

  return (
    <>
      <Helmet title={t('reset.title')} />
      <Heading level='h1'>{t('reset.title')}</Heading>

      {token ? (
        <>
          <p>{t('reset.description')}</p>

          <AuthErrors error={apiError} />

          <Form onSubmit={handleSubmit(onSubmit)} isFetching={isFetching}>
            <Form.PasswordField
              {...getFieldProps({ id: 'create_password', name: 'password' })}
              required
              autoComplete='new-password'
            />
            <Form.PasswordField
              {...getFieldProps({
                id: 'create_password',
                name: 'password_again',
              })}
              required
              autoComplete='new-password'
            />
            <Button type='submit'>{t('reset.submitButton')}</Button>
          </Form>
        </>
      ) : (
        <>
          <div className='mb-4'>
            <Message type='error'>{t('reset.noTokenError')}</Message>
          </div>
          <Link to='/'>{t('reset.backLinkText')}</Link>
        </>
      )}
    </>
  );
};

export default ResetPassword;
