import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, ButtonBar } from '@tg/core/components';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmModal: FC<Props> = ({ children, isOpen, onClose, onConfirm }) => {
  const { t } = useTranslation(['forms']);

  return (
    <Modal title='Confirm' isOpen={isOpen} width='xs' onClose={onClose}>
      <Modal.Content>{children}</Modal.Content>
      <ButtonBar>
        <div className='space-x-2'>
          <Button onClick={onClose} color='secondary'>
            {t('forms:buttons.modal.cancel')}
          </Button>
          <Button onClick={onConfirm}>
            {t('forms:buttons.modal.confirm')}
          </Button>
        </div>
      </ButtonBar>
    </Modal>
  );
};

export default ConfirmModal;
