import messages from './messages.json';
import forms from './forms.json';
import collections from './collections.json';
import errors from './errors.json';
import dateTime from './dateTime.json';

export default {
  forms,
  collections,
  errors,
  dateTime,
  ...messages,
};
