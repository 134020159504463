import React from 'react';
import PropTypes from 'prop-types';
import { Button, Heading, EmptySet } from '@tg/core/components';
import createSvg from '@tg/core/assets/images/add_person.svg';
import personSvg from '@tg/core/assets/images/person.svg';
import { useTranslation } from 'react-i18next';

const LandingPage = ({ empty }) => {
  const { t } = useTranslation('employees');

  return (
    <div>
      {empty ? (
        <div className='text-center mx-auto max-w-sm'>
          <img alt='' src={createSvg} className='mx-auto mb-4' />
          <Heading level='h2' centered>
            {t('list.add_heading')}
          </Heading>
          <Button primary to='/employees/add'>
            {t('list.add_button')}
          </Button>
        </div>
      ) : (
        <EmptySet heading={t('list.select')} image={personSvg} />
      )}
    </div>
  );
};

LandingPage.propTypes = {
  empty: PropTypes.bool.isRequired,
};

export default LandingPage;
