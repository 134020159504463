import React from 'react';
import PropTypes from 'prop-types';
import { Icon as SemanticIcon } from 'semantic-ui-react';

const Icon = ({ size, name, ...props }) => {
  const semanticSizes = {
    xxs: 'mini',
    xs: 'tiny',
    sm: 'small',
    md: undefined,
    lg: 'large',
    xl: 'big',
    xxl: 'huge',
    xxxl: 'massive',
  };

  return (
    <SemanticIcon name={name} size={semanticSizes[size]} {...props} fitted />
  );
};

Icon.propTypes = {
  size: PropTypes.oneOf(['md', 'lg', 'xl', 'sm', 'xs']),
  name: PropTypes.string.isRequired,
};

Icon.defaultProps = {
  size: 'md',
};

export default Icon;
