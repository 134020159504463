/* eslint-disable react/prop-types */
import React, { FC, ComponentType } from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { selectSessionAuthToken } from '@tg/core/store/modules/session';
import { useAppSelector } from '@tg/core/hooks';

interface Props {
  component: ComponentType<RouteComponentProps>;
  redirectPath?: string;
}

const PrivateRoute: FC<Props> = ({
  component: Component,
  redirectPath = '/auth/login',
  ...rest
}) => {
  const hasSession = !!useAppSelector(state => selectSessionAuthToken(state));
  const allowed = hasSession;

  return (
    <Route
      {...rest}
      render={routeProps =>
        allowed ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: redirectPath,
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
