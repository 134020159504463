import { array, boolean, number, object, setLocale, string } from 'yup';
import { isDate, isAfter } from 'date-fns';

setLocale({
  mixed: {
    default: { key: 'field_invalid' },
    required: { key: 'field_required' },
  },
  string: {
    email: { key: 'email_invalid' },
    min: ({ min }) => ({ key: 'field_too_short', values: { min } }),
    max: ({ max }) => ({ key: 'field_too_long', values: { max } }),
  },
});

// const address = object().shape({
//   line_one: string().required(),
//   line_two: string(),
//   city: string(),
//   state: string(),
//   zip: string().required(),
//   country_id: string().required(),
// });

export const phoneRequired = object().test(
  'phone-test',
  { key: 'phone_invalid' },
  ({ country_code, phone }) => {
    // console.log({ country, phone });
    if (!country_code || !phone) return false;
    return (
      country_code.match(/^[A-Z]{2}$/) && phone.match(/^[\d-\s.)(]{1,20}$/)
    );
  },
);

export const phoneOptional = object().test(
  'phone-test',
  { key: 'phone_invalid' },
  ({ country_code, phone }) => {
    // If there's a phone number, we also need a country
    if (phone) {
      if (!country_code) return false;
      return (
        country_code.match(/^[A-Z]{2}$/) && phone.match(/^[\d-\s.)(]{1,20}$/)
      );
    }
    return true;
  },
);

export const dateValidation = string().test(
  'is-date-string',
  { key: 'field_invalid' },
  value => isDate(new Date(value)),
);

export const endDateValidation = dateValidation.when(
  'start_date',
  (start_date, schema) => {
    return schema.test(
      'is-after-date',
      { key: 'end_date_before_start_date' },
      value => {
        if (!value) return true;
        if (value === start_date) return true;
        return isAfter(new Date(value), new Date(start_date));
      },
    );
  },
);

export const user = object().shape({
  first_name: string().required(),
  last_name: string().required(),
  email: string().email(),
  phone: phoneRequired,
});

export const address = object().shape({
  line_one: string().required(),
  line_two: string(),
  city: string(),
  state: string(),
  zip: string().required(),
  country_id: string().required(),
});

export const address_with_same_as = object().shape({
  same_as_registered: boolean(),
  line_one: string().when('same_as_registered', {
    is: false,
    then: string().required(),
  }),
  line_two: string(),
  city: string(),
  state: string(),
  zip: string().when('same_as_registered', {
    is: false,
    then: string().required(),
  }),
  country_id: string().when('same_as_registered', {
    is: false,
    then: string().required(),
  }),
});

export const employer = object().shape({
  name: string().required(),
  industry_sector_id: string().required(),
  identifiers: array()
    .default([])
    .test('identifier-test', { key: 'field_required' }, identifiers =>
      identifiers.every(
        x => x.value && x.value.match(x.validator || /[a-zA-Z\d]/),
      ),
    ),
});

const schemas = {
  address,
  address_with_same_as,
  employer,
  officer: user.shape({
    job_title: string().required(),
  }),
  officer_create: user.shape({
    email: string().email().required(),
    job_title: string().required(),
  }),
  employee_create: user.shape({
    email: string().email().required(),
    domicile_id: string().required(),
  }),
  employee: user.shape({
    identifiers: array()
      .default([])
      .test('identifier-test', { key: 'field_required' }, identifiers =>
        identifiers.every(
          x => x.value && x.value.match(x.validator || /[a-zA-Z\d]/),
        ),
      ),
  }),
  bank_details: object().shape({
    account_name: string().required(),
    account_iban: string().required(),
    account_swift: string().required(),
  }),
  contract: object().shape({
    job_title: string().required(),
    contract_type_id: string().required(),
    attendance_type_id: string().required(),
    start_date: string()
      .nullable()
      .test('is-date-string', { key: 'field_invalid' }, value =>
        isDate(new Date(value)),
      )
      .required(),
    end_date: string()
      .nullable()
      .when('fixed_term', {
        is: 'true',
        then: string()
          .required()
          .test('is-date-string', { key: 'field_invalid' }, value =>
            isDate(new Date(value)),
          )
          .when('start_date', (start_date, schema) => {
            return schema.test(
              'is-after-date',
              { key: 'end_date_before_start_date' },
              value => isAfter(new Date(value), new Date(start_date)),
            );
          }),
      }),
    currency_id: string().required(),
    gross_salary: number().typeError({ key: 'number_required' }),
  }),
  emergency_contact: object({
    name: string(),
    phone: phoneOptional,
  }),
};

export const getValidationSchema = fieldsets => {
  const schema = fieldsets.reduce((acc, { id, fieldset }) => {
    acc[id] = schemas[fieldset];
    return acc;
  }, {});
  return object().shape(schema);
};
