import React, { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import classNames from 'classnames';
import { LoadingSpinner } from '@tg/core/components';

interface Props {
  to?: LinkProps['to'];
  onClick?: () => void;
  href?: string;
  type?: 'button' | 'submit';
  fluid?: boolean;
  size?: 'small' | 'medium' | 'large';
  color?: 'primary' | 'secondary' | 'tertiary';
  loading?: boolean;
}

const Button: FC<Props> = React.forwardRef(function Button(
  {
    to,
    onClick,
    href,
    children,
    size = 'medium',
    type = 'button',
    fluid,
    color = 'primary',
    loading,
    ...props
  },
  ref,
) {
  const sizesMap = {
    small: 'text-xs px-4 py-2',
    medium: 'text-sm px-6 py-3',
    large: 'text-lg px-6 py-3',
  };
  const colorsMap = {
    primary: [
      'uppercase',
      'font-bold',
      'rounded-full',
      'bg-red-500',
      'text-white',
      'hover:bg-red-600',
      'hover:text-white',
    ],
    secondary: [
      'uppercase',
      'font-bold',
      'rounded-full',
      'border-2',
      'border-dark-blue',
      'text-dark-blue',
      'hover:border-dark-blue-light',
      'hover:text-dark-blue-light',
    ],
    tertiary: [
      'rounded-md',
      'font-semibold',
      'border',
      'border-gray-300',
      'text-gray-700',
      'bg-white',
      'leading-4',
      'font-medium',
      'hover:bg-gray-50',
    ],
    quaternary: [
      'font-bold',
      'bg-red-500',
      'text-white',
      'hover:bg-red-600',
      'hover:text-white',
      'rounded-md',
    ],
  };

  const buttonClassName = classNames(
    [
      'inline-block',
      'relative',
      'text-center',
      'leading-none',
      'transition-colors',
      'transition-opacity',
      'focus:outline-none',
      'focus:ring',
      'disabled:opacity-50',
      'disabled:pointer-events-none',
      ...colorsMap[color],
      sizesMap[size],
    ],
    {
      'w-full': fluid,
    },
  );

  if (to) {
    return (
      <Link to={to} className={buttonClassName} ref={ref}>
        {children}
      </Link>
    );
  }

  // For download links
  if (href) {
    return (
      <a href={href} className={buttonClassName}>
        {children}
      </a>
    );
  }

  /* eslint-disable react/button-has-type */
  return (
    <button
      type={type}
      className={buttonClassName}
      ref={ref}
      onClick={onClick}
      {...props}
    >
      <span className={classNames({ invisible: loading })}>{children}</span>

      {loading && (
        <span
          className='absolute inset-0 flex items-center justify-center'
          aria-label='Loading'
        >
          <LoadingSpinner />
        </span>
      )}
    </button>
  );
});

export default Button;
