import React, { FC, useState } from 'react';
import { Button, ButtonBar, Modal } from '@tg/core/components';
import { useTranslation } from 'react-i18next';
import { Document } from 'src/types';
import { ResourceOutput } from '@tg/core/hooks/useResource';
import FilePicker from './FilePicker';

interface Props {
  receipts: Document[];
  userFullName: string;
  emp_id: number;
  sub_id: number;
  getList: ResourceOutput<FormData>['getResource'];
}

const ViewReceiptsModal: FC<Props> = ({
  receipts,
  userFullName,
  emp_id,
  sub_id,
  getList,
}) => {
  const { t } = useTranslation(['payroll', 'forms']);

  const [isOpen, setIsOpen] = useState(false);

  const handleCheck = () => {
    getList({ onSuccess: () => setIsOpen(false) });
  };

  return (
    <>
      <button
        type='button'
        onClick={() => setIsOpen(true)}
        className='underline'
      >
        {t('payroll:files_attached', {
          count: receipts.length,
        })}
      </button>
      <Modal
        isOpen={isOpen}
        title={
          userFullName
            ? t('payroll:files_attached_modal_title', {
                fullName: userFullName,
              })
            : ''
        }
        width='sm'
        onClose={() => setIsOpen(false)}
      >
        <Modal.Content>
          <FilePicker
            defaultValue={receipts}
            getEmployeeId={emp_id}
            getSubId={sub_id}
            handleCheck={handleCheck}
            disabled
          />
        </Modal.Content>
        <ButtonBar>
          <Button onClick={handleCheck}>Close</Button>
        </ButtonBar>
      </Modal>
    </>
  );
};

export default ViewReceiptsModal;
