import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Heading } from '@tg/core/components';
import TextField from '../../Fields/TextField';
import PhoneField from '../../Fields/PhoneField';
import CollectionField from '../../Fields/CollectionField';
import IdentifiersField from '../../Fields/IdentifiersField';

const Employee = ({
  mode,
  defaultValues,
  getFieldProps,
  domicileId,
  disableEmail,
  heading,
}) => {
  const { t } = useTranslation(['forms']);

  return (
    <fieldset>
      <Heading element='legend' level='h4'>
        {heading || t('forms:labels.employee.heading')}
      </Heading>

      <div className='grid gap-x-4 grid-cols-2'>
        <TextField
          {...getFieldProps({ id: 'employee', name: 'first_name' })}
          required
          defaultValue={defaultValues.first_name}
        />
        <TextField
          {...getFieldProps({ id: 'employee', name: 'last_name' })}
          required
          defaultValue={defaultValues.last_name}
        />

        {mode === 'add' && (
          <div className='col-span-2 pb-4'>
            <CollectionField
              {...getFieldProps({
                name: 'domicile_id',
                id: 'employee',
              })}
              resource='domiciles'
              required
              search
              useName
            />
          </div>
        )}

        <TextField
          {...getFieldProps({ id: 'employee', name: 'email' })}
          disabled={disableEmail}
          required
          defaultValue={defaultValues.email}
        />
        <PhoneField
          {...getFieldProps({ id: 'employee', name: 'phone' })}
          required
          defaultValue={defaultValues.phone}
        />
      </div>

      {mode === 'edit' && domicileId && (
        <IdentifiersField
          formContext='employee'
          domicileId={domicileId}
          {...getFieldProps({ id: 'employee', name: 'identifiers' })}
          defaultValue={defaultValues.identifiers}
        />
      )}
    </fieldset>
  );
};

Employee.propTypes = {
  mode: PropTypes.oneOf(['add', 'edit']).isRequired,
  getFieldProps: PropTypes.func.isRequired,
  domicileId: PropTypes.string,
  disableEmail: PropTypes.bool,
  defaultValues: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    phone: PropTypes.object,
    email: PropTypes.string,
    identifiers: PropTypes.array,
  }),
  heading: PropTypes.string,
};

Employee.defaultProps = {
  disableEmail: false,
  defaultValues: {
    first_name: '',
    last_name: '',
    phone: { code: '', number: '' },
    email: '',
    identifiers: null,
  },
  heading: null,
  domicileId: null,
};

export default Employee;
