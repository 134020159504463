import React, { useState } from 'react';
import { ConfirmModal } from '@tg/core/components';

/**
 * A Confirm Modal that runs a callback when confirmed and closes when not
 * returns a method to open the modal and the modal component to be rendered in parent
 */
export default (callback: () => void, message: string): unknown => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => {
    setIsOpen(true);
  };
  const cancel = () => setIsOpen(false);
  const ok = () => {
    setIsOpen(false);
    callback();
  };

  const Confirm = () => {
    return (
      <ConfirmModal isOpen={isOpen} onClose={cancel} onConfirm={ok}>
        {message}
      </ConfirmModal>
    );
  };

  return {
    open,
    Confirm,
  };
};
