import React, { FC } from 'react';
import { useResource } from '@tg/core/hooks';
import { Segment, Form, Button, ButtonBar } from '@tg/core/components';
import { useTranslation } from 'react-i18next';
import type { Address, AddressWithSameAs, Identifier } from 'src/types';
import validationSchema from './validation';

interface FormData {
  employer: {
    name: string;
    identifiers: Identifier[];
    industry_sector_id: string;
  };
  primary_address: AddressWithSameAs;
  registered_address: Address;
}

const CompanyInfo: FC = () => {
  const { t } = useTranslation(['forms', 'settings']);

  const { data, patchResource, isFetching } = useResource(
    { url: `employers` },
    true,
  );

  const onSubmit = (formData: FormData) => {
    // Remove same_as_registered from the primary address (where it live in the UI) on to
    // registered_address.use_as_contact_address (where it lives on the backend)
    const { same_as_registered, ...primary_address } = formData.primary_address;

    const patchData = {
      ...formData,
      primary_address,
      registered_address: {
        ...formData.registered_address,
        use_as_contact_address: same_as_registered,
      },
    };

    if (!Object.keys(primary_address).length) {
      delete patchData.primary_address;
    }

    patchResource({
      formData: patchData,
    });
  };

  const { handleSubmit, getFieldProps, control, watch } =
    Form.useForm<FormData>({
      validationSchema,
    });

  const { addresses, domicile_id: domicileId, ...employer } = data || {};

  const registered_address: Address =
    addresses && addresses.find(x => x.address_type === 'registered');

  const existingPrimaryAdd: Address =
    addresses && addresses.find(x => x.address_type === 'trading');

  const primary_address: AddressWithSameAs = existingPrimaryAdd
    ? {
        ...existingPrimaryAdd,
        same_as_registered: false,
      }
    : {
        same_as_registered: true,
      };

  const defaultSameAsRegistered = primary_address.same_as_registered;
  const watchSameAs = watch(
    `primary_address.same_as_registered`,
    defaultSameAsRegistered,
  );

  return (
    <Segment flush heading={t('settings:company_profile')}>
      <Form onSubmit={handleSubmit(onSubmit)} isFetching={isFetching}>
        <div className='px-4 mb-6 space-y-6' style={{ minHeight: '200px' }}>
          <Form.Sets.Employer
            getFieldProps={getFieldProps}
            control={control}
            domicileId={domicileId}
            defaultValues={{
              name: employer?.name,
              identifiers: employer?.identifiers,
              industry_sector_id: employer?.industry_sector_id,
            }}
          />
          <Form.Sets.Address
            id='registered_address'
            getFieldProps={getFieldProps}
            control={control}
            defaultValues={registered_address}
            heading={t('forms:labels.address.heading_registered')}
          />
          <Form.Sets.Address
            id='primary_address'
            getFieldProps={getFieldProps}
            control={control}
            defaultValues={primary_address}
            heading={t('forms:labels.address.heading_contact')}
            showSameAsCheckbox
            isSameAs={watchSameAs}
          />
        </div>
        <ButtonBar>
          <Button type='submit'>{t('forms:buttons.save')}</Button>
        </ButtonBar>
      </Form>
    </Segment>
  );
};

export default CompanyInfo;
