import jwtDecode from 'jwt-decode';
import actions from './actions';

export const initialState = {
  aut: '',
  role_desc: '',
  role_id: '',
  user_id: '',
  user: {},
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.CREATE_SESSION: {
      const { token } = payload;
      const { role_desc, role_id, user_id } = jwtDecode(token);
      return {
        ...state,
        aut: token || '',
        role_desc,
        role_id,
        user_id,
      };
    }

    case actions.DESTROY_SESSION:
    case actions.FETCH_USER_FAILURE:
      return initialState;

    case actions.FETCH_USER_SUCCESS:
      return {
        ...state,
        user: payload,
      };

    default:
      return state;
  }
};

export default reducer;
