import React, { FC, useState } from 'react';
import PropTypes from 'prop-types';
import { useResource } from '@tg/core/hooks';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import {
  ContractDocumentsList,
  Heading,
  DisplayDetails,
  PageHeader,
  Segment,
} from '@tg/core/components';
import { formatValue } from '@tg/core/utils/currencyHelpers';
import { formatDate } from '@tg/core/utils/datetimeHelpers';
import { Contract, Document } from 'src/types';

interface Props {
  contractId: string;
}

const EmployeeContract: FC<Props> = ({ contractId }) => {
  const { t } = useTranslation(['contract', 'page_titles']);

  const [page, setPage] = useState(1);

  const {
    data: documents,
    isFetching: documentsLoading,
    pagination,
  } = useResource<Document[]>(
    {
      url: `employees/contracts/${contractId}/documents?page=${page}&pagesize=10`,
    },
    true,
  );

  const { data: contract } = useResource<Contract>(
    { url: `employees/contracts/${contractId}` },
    true,
  );

  let salary = '';
  if (contract) {
    const { gross_salary, currency } = contract.current_salary;
    salary = formatValue({
      value: gross_salary,
      currency,
      type: 'salary',
      subtype: 'grossSalary',
    });
  }

  const { line_manager, start_date, contract_type, attendance_type } =
    contract || {};

  return (
    <>
      <Helmet title={t('page_titles:contract')} />
      <PageHeader heading={t('page_titles:contract')} />
      <div className='mb-8'>
        <Segment>
          <div className='mb-8 mt-2.5'>
            <>
              <Heading level='h3' zeropad>
                {contract ? contract.employer.name : '-'}
              </Heading>
              <div className='mt-1'>{contract ? contract.job_title : '-'}</div>
            </>
          </div>

          <div className='mb-2'>
            <DisplayDetails
              details={[
                {
                  title: t('contract:contract_type'),
                  value: contract_type ? contract_type.name : '',
                  width: '50%',
                },
                {
                  title: t('contract:attendance_type'),
                  value: attendance_type ? attendance_type.name : '',
                  width: '50%',
                },
                {
                  title: t('contract:gross_salary'),
                  value: salary,
                  width: '50%',
                },
                {
                  title: t('contract:start_date'),
                  value: start_date ? formatDate({ dateStr: start_date }) : '',
                  width: '50%',
                },
                {
                  title: t('contract:line_manager'),
                  value: line_manager ? line_manager.full_name : '',
                  width: '50%',
                },
              ]}
            />
          </div>
          <h3 className='font-medium text-gray-500 mb-2 m-0 text-base'>
            {t('documents.heading')}
          </h3>
          <ContractDocumentsList
            collection={documents}
            loading={documentsLoading}
            pagination={pagination}
            setPage={setPage}
          />
        </Segment>
      </div>
    </>
  );
};

EmployeeContract.propTypes = {
  contractId: PropTypes.string.isRequired,
};

export default EmployeeContract;
