import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Segment, Heading, Avatar } from '@tg/core/components';
import { SessionUser } from 'src/types';

interface Props {
  user: SessionUser;
}

const Hello: FC<Props> = ({ user }) => {
  const { t } = useTranslation(['dashboard']);

  return (
    <Segment className='flex'>
      <Avatar user={user} showName={false} />
      <div className='ml-4'>
        <Heading level='h3' element='h1' zeropad>
          <span>{t('dashboard:hello', { first_name: user.first_name })}</span>
        </Heading>
        <p className='text-gray-500 text-lg font-medium'>
          {t('dashboard:welcome')}
        </p>
      </div>
    </Segment>
  );
};

export default Hello;
