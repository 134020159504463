import React from 'react';
import PropTypes from 'prop-types';
import { Modal as SemanticModal } from 'semantic-ui-react';

/* eslint-disable react/prop-types */
const Content = ({ children }) => (
  <SemanticModal.Content scrolling className='p-6'>
    {children}
  </SemanticModal.Content>
);
/* eslint-enable react/prop-types */

/**
 * A wrapper for semantic-ui-react's modal component.
 * NOTE: this component is not accessible and should be replaced
 */
const Modal = ({ title, children, isOpen, onClose, width }) => {
  const semanticUISizes = {
    xss: 'mini',
    xs: 'tiny',
    sm: 'small',
    md: undefined,
    lg: 'large',
    xl: 'fullscreen',
  };

  return (
    <SemanticModal
      onClose={onClose}
      open={isOpen}
      data-cy='modal'
      size={semanticUISizes[width]}
    >
      <header className='font-semibold pt-6 px-6 text-xl'>{title}</header>
      {children}
    </SemanticModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  width: PropTypes.oneOf(['xs', 'sm', 'md']),
};

Modal.defaultProps = {
  title: null,
  width: 'md',
};

Modal.Content = Content;

export default Modal;
