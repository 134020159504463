import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getInputFormat } from '@tg/core/utils/datetimeHelpers';
import { Context } from '@tg/core/components';

const DateField = ({ onChange, value, placeholder }) => {
  const handleChange = date => {
    onChange(date);
  };

  const { lang } = useContext(Context);

  return (
    <DatePicker
      onChange={handleChange}
      selected={value}
      placeholderText={placeholder}
      dateFormat={getInputFormat(lang)}
    />
  );
};

DateField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.instanceOf(Date),
  placeholder: PropTypes.string,
};

DateField.defaultProps = {
  onChange: f => f,
  value: null,
  placeholder: '',
};

export default DateField;
