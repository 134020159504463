import React from 'react';
import PropTypes from 'prop-types';
import { Form, Modal } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CollectionInput, Button, DateField } from '@tg/core/components';
import { useTranslation } from 'react-i18next';
import { salary } from './Validation';

const AddEditModal = ({ data, onSubmit, onCancel }) => {
  const { register, handleSubmit, errors, control } = useForm({
    resolver: yupResolver(salary),
  });
  const { t } = useTranslation(['salary_history']);

  const {
    effective_date,
    gross_salary,
    currency: { id: currency_id },
  } = data();

  return (
    <Modal as={Form} onSubmit={handleSubmit(onSubmit)} onClose={onCancel} open>
      <Modal.Header>Add Salary</Modal.Header>
      <Modal.Content>
        <Form.Field required error={!!errors.gross_salary}>
          <label htmlFor='gross_salary'>
            {t('salary_history:gross_salary')}
          </label>
          <input
            name='gross_salary'
            type='text'
            defaultValue={gross_salary}
            ref={register}
          />
          {errors.gross_salary && (
            <p style={{ color: 'red' }}>Number Required</p>
          )}
        </Form.Field>

        <Form.Field required error={!!errors.currency_id}>
          <label htmlFor='currency_id'>Currency</label>
          <Controller
            as={CollectionInput}
            status
            control={control}
            name='currency_id'
            defaultValue={currency_id}
            rules={{ required: 'required' }}
            resource='currencies'
            label='code'
          />
          {errors.currency_id && <p style={{ color: 'red' }}>Required value</p>}
        </Form.Field>
        <div style={{ width: '225px' }}>
          <Form.Field required error={!!errors.effective_date}>
            <label htmlFor='effective_date'>Effective from</label>
            <Controller
              as={DateField}
              control={control}
              name='effective_date'
              defaultValue={
                effective_date ? new Date(effective_date) : new Date()
              }
              rules={{ required: 'required' }}
            />
            {errors.effective_date && (
              <p style={{ color: 'red' }}>Required value</p>
            )}
          </Form.Field>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div className='space-x-2'>
          <Button color='secondary' onClick={onCancel}>
            Cancel
          </Button>
          <Button type='submit'>Save</Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

AddEditModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  data: PropTypes.func,
};

AddEditModal.defaultProps = {
  data: () => ({
    effective_date: null,
    gross_salary: null,
    currency: {
      id: null,
    },
  }),
};

export default AddEditModal;
