import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { observableRequest } from '@tg/core/utils/requestHelpers';
import {
  reducer as collections,
  epic as collectionsEpic,
} from './modules/collections';
import { reducer as app, epic as bootstrapEpic } from './modules/bootstrap';
import { reducer as session, epic as sessionEpic } from './modules/session';
import { accessReducer } from './modules/session/accessReducer';

const isDev = () => {
  // return process.env.NODE_ENV !== 'production';
  return true;
};

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['session', 'access'],
};

const rootEpic = combineEpics(bootstrapEpic, collectionsEpic, sessionEpic);

const rootReducer = combineReducers({
  app,
  session,
  collections,
  access: accessReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const epicMiddleware = createEpicMiddleware({
  dependencies: { observableRequest },
});

const store = configureStore({
  reducer: persistedReducer,
  devTools: isDev(),
  middleware: [epicMiddleware],
});

epicMiddleware.run(rootEpic);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
