import React, { FC, ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { PageHeader, PrivateRoute, Icon } from '@tg/core/components';
import {
  NavLink,
  RouteComponentProps,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

// import Password from './pages/Password';
import About from './pages/About';

interface Page {
  title: string;
  to: string;
  component: ComponentType<RouteComponentProps>;
  icon: string;
}

interface Props {
  pages: Page[];
  profileComponent?: JSX.Element;
}

const Settings: FC<Props> = ({ pages }) => {
  const { t } = useTranslation(['settings', 'page_titles']);
  const { path } = useRouteMatch();

  const allPages = [...pages];
  allPages.push({
    title: t('settings:about'),
    to: '/about',
    component: About,
    icon: 'info circle',
  });

  return (
    <div>
      <Helmet title={t('page_titles:settings')} />
      <PageHeader heading={t('page_titles:settings')} />
      <div className='lg:grid lg:grid-cols-12 lg:gap-x-5 mb-8'>
        <aside className='mb-6 lg:col-span-3'>
          <nav className='space-y-1'>
            {allPages.map(({ title, to, icon }) => (
              <NavLink
                to={`${path}${to}`}
                key={title}
                className='flex items-center px-3 py-2 text-sm font-semibold rounded-md text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                activeClassName='bg-gray-200 text-dark-blue-light'
                exact
              >
                <span className='mr-4'>
                  <Icon name={icon} />
                </span>
                {title}
              </NavLink>
            ))}
          </nav>
        </aside>
        <div className='space-y-6 lg:col-span-9'>
          <Switch>
            {allPages.map(({ title, to, component }) => (
              <PrivateRoute
                exact
                key={title}
                path={`${path}${to}`}
                component={component}
              />
            ))}
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Settings;
