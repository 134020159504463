import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectSessionUser, actions } from '@tg/core/store/modules/session';
import { Avatar, Icon, Menu as DropdownMenu } from '@tg/core/components';
import logoutSvg from '@tg/core/assets/images/logout.svg';
import { useResource, useWindowSize } from '@tg/core/hooks';
import { useTranslation } from 'react-i18next';

const UserMenu: FC = () => {
  const { t } = useTranslation(['auth', 'profile']);
  const { deleteResource } = useResource({ url: `auth/logout` });

  const user = useSelector(state => selectSessionUser(state));
  const dispatch = useDispatch();
  const history = useHistory();
  const Location = useLocation();
  const getId = useSelector(state => state.access.rolesId);

  const onLogoutClick = () => {
    deleteResource({
      onSuccess: () => {
        dispatch(actions.destroySession());
        dispatch({ type: 'RESET_ACCESS' });
        history.replace({ pathname: '/' });
      },
    });
  };

  const windowSize = useWindowSize();
  const showLargeUserMenu = windowSize.width > 1180;

  const onRoleClick = e => {
    const id = e.target.value;
    dispatch({
      type: 'ADD_ROLE_ID',
      payload: id,
    });
    history.push({ pathname: '/' });
    if (Location.pathname === '/') {
      window.location.reload();
    }
  };
  const { roles } = user;
  const newItems = [];
  const tempArr = [];
  if (roles) {
    roles.forEach(item => {
      if (item.name === 'Key Contact' || item.name === 'Line Manager') {
        newItems.push({
          text: item?.name,
          value: item?.id,
          onClick: onRoleClick,
        });
      } else {
        tempArr.push(item);
      }
    });
    tempArr.forEach(item => {
      newItems.push({
        text: item?.name,
        value: item?.id,
        onClick: onRoleClick,
      });
    });
  }

  const userMenuItems = [
    {
      text: t('profile:view'),
      to: '/settings',
    },
    {
      text: t('logout'),
      onClick: onLogoutClick,
      show: !showLargeUserMenu,
    },
  ].filter(({ show }) => show !== false);

  const roleValue = newItems?.find(
    item => parseInt(getId, 10) === item.value && item.text !== 'Employee',
  )?.text;

  if (newItems?.length > 1) {
    userMenuItems.push(...newItems);
  }

  return (
    <div className='flex w-18 sm:w-auto' data-cy='main-nav-user-menu'>
      <DropdownMenu
        items={userMenuItems}
        header={
          !showLargeUserMenu && (
            <div className='px-3 py-4 border-b border-gray-200'>
              <div className='font-bold truncate text-base'>
                {user.full_name}
              </div>
              <div className='text-sm leading-none truncate'>
                {user.job_title || user.email}
              </div>
            </div>
          )
        }
      >
        <div className='max-w-xs'>
          <Avatar
            showName={showLargeUserMenu}
            user={user}
            roleValue={roleValue}
          />
        </div>
        <span className='ml-2 self-center'>
          <Icon name='chevron down' />
        </span>
      </DropdownMenu>
      {showLargeUserMenu && (
        <button
          type='button'
          onClick={onLogoutClick}
          className='ml-8 rounded-full focus:outline-none focus-visible:ring'
        >
          <img alt='' src={logoutSvg} />
        </button>
      )}
    </div>
  );
};

export default UserMenu;
