import React, { FC } from 'react';
import { Container } from '@tg/core/components';
import { useTranslation } from 'react-i18next';
import Settings from '../../../Layouts/Settings';
import OfficerProfile from './pages/OfficerProfile';
import CompanyProfile from './pages/CompanyProfile/index';

const EmployeeSettings: FC = () => {
  const { t } = useTranslation(['settings']);

  return (
    <Container width='lg'>
      <Settings
        pages={[
          {
            title: t('settings:profile'),
            to: '/',
            icon: 'user',
            component: () => <OfficerProfile />,
          },
          {
            title: t('settings:company_profile'),
            to: '/company',
            icon: 'address card outline',
            component: () => <CompanyProfile />,
          },
        ]}
      />
    </Container>
  );
};

export default EmployeeSettings;
