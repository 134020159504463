import React, { FC, useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Control, Controller } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '@tg/core/hooks';
import { actions } from '@tg/core/store/modules/collections';
import { InputPhone, LabelledInput } from '@tg/core/components';
import type { PhoneNumber } from 'src/types';

interface CountryCodeOption {
  id: string;
  value: string;
  flag: string;
  countryName: string;
}

const getCodeOptions = ({ dialCodes, countryCodes }): CountryCodeOption[] => {
  const options = Object.values(dialCodes).map(({ id, code, dialcode }) => ({
    id,
    value: code,
    code: dialcode,
    flag: code.toLowerCase(),
    countryName: countryCodes[id]?.name,
  }));
  return options.sort((a, b) => {
    if (a.countryName < b.countryName) return -1;
    return a.countryName > b.countryName ? 1 : 0;
  });
};

interface Props {
  name: string;
  id: string;
  translationKey: string;
  defaultValue: PhoneNumber;
  required?: boolean;
  disabled?: boolean;
  errors?: unknown;
  control: Control;
}

const PhoneField: FC<Props> = ({
  name,
  id,
  translationKey,
  control,
  defaultValue = { code: null, phone: null, country_code: null },
  disabled = false,
  required = false,
  errors,
}) => {
  const { t } = useTranslation('forms');

  const label = t(`labels.${translationKey}`);
  const fieldId = `${id}.${name}`;

  const dialCodesCollection = useAppSelector(
    state => state.collections.dialcodes || null,
  );
  const employeeCountriesCollection = useAppSelector(
    state => state.collections.domiciles || null,
  );
  const dispatch = useAppDispatch();

  /**
   * If we don't have these collections, get them
   */
  useEffect(() => {
    if (!dialCodesCollection)
      dispatch(actions.fetchCollection({ resource: 'dialcodes' }));
    if (!employeeCountriesCollection)
      dispatch(actions.fetchCollection({ resource: 'domiciles' }));
  }, [dialCodesCollection, dispatch, employeeCountriesCollection]);

  const [codeOptions, setCodeOptions] = useState([] as CountryCodeOption[]);
  useEffect(() => {
    const options = getCodeOptions({
      dialCodes: dialCodesCollection?.byId || [
        { id: 0, value: '0', code: '0' },
      ],
      countryCodes: employeeCountriesCollection?.byId || [
        { value: '0', name: '0' },
      ],
    });

    setCodeOptions(options);
  }, [dialCodesCollection, employeeCountriesCollection]);

  // Takes '+44' or '44' and returns 'GB'
  const getCountryFromCode = (code: string) => {
    if (!code) return '';
    const allCodes = Object.values(dialCodesCollection.byId) as {
      id: string;
      dialcode: string;
      code: string;
    }[];
    const selected = allCodes.find(({ dialcode }) => {
      // e.g is it '44' OR '+44'
      return dialcode === code || dialcode === code.substring(1);
    });

    if (!selected) return '';

    return selected.code;
  };

  /**
   * Not all 'defaultValues' will have a `country_code` value so try and find it
   * from the `code`, this wont always work because US and Canada both use +1
   */
  const defaultWithCode: PhoneNumber = {
    phone: defaultValue.phone,
    country_code:
      defaultValue.country_code || getCountryFromCode(defaultValue.code),
    code: defaultValue.code,
  };

  return (
    <Form.Field required={required && !disabled} error={!!errors}>
      <LabelledInput
        id={fieldId}
        label={label}
        required={required && !disabled}
        error={errors && t(`validations.${errors.message.key}`)}
      >
        <Controller
          as={
            <InputPhone
              defaultValue={defaultWithCode}
              codeOptions={codeOptions}
            />
          }
          control={control}
          name={fieldId}
          placeholder={{ phone: 'Number', code: 'Code' }}
          disabled={disabled}
          defaultValue={defaultWithCode}
        />
      </LabelledInput>
    </Form.Field>
  );
};

export default PhoneField;
