export const RESOURCE_STATUS = {
  not_found: 'not_found',
  not_confirmed: 'not_confirmed',
  force_password_change: 'force_password_change',
  invalid_confirmation_token: 'invalid_confirmation_token',
  invalid_password_reset_token: 'invalid_password_reset_token',
  invalid_credentials: 'invalid_credentials',
  invalid_activation_token: 'invalid_activation_token',
  expired_activation_token: 'expired_activation_token',
};

// Temp change due to SSL certificate issues at Digital Ocean
// const staticAssetHost = 'https://static.teamed.global';
const staticAssetHost = 'https://static.teamed.global/legals/';

export const ASSET_URLS = {
  terms_of_service: `${staticAssetHost}2020-10-18/tos.pdf`,
  privacy_policy: `${staticAssetHost}2023-07-10/privacy_policy.pdf`,
};
