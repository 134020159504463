import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';

const CheckboxField = ({ onChange, id, ...rest }) => {
  return (
    <Checkbox
      id={id}
      onChange={(_, { checked }) => {
        onChange(checked);
      }}
      {...rest}
    />
  );
};

CheckboxField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  id: PropTypes.string,
};

CheckboxField.defaultProps = {
  value: '',
  id: null,
};

export default CheckboxField;
