import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, List, Modal } from '@tg/core/components';
import { Contract, Employee } from 'src/types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  getListItems: (employeeId: Employee['id'], contractData: Contract) => void;
  complete: unknown[];
  incomplete: unknown[];
}

const OfficerDashboard: FC<Props> = ({
  isOpen,
  onClose,
  complete,
  incomplete,
  getListItems,
}) => {
  const { t } = useTranslation(['onboarding']);
  const [modalTabs, setModalTabs] = useState([]);
  const [modalTab, setModalTab] = useState('');

  useEffect(() => {
    const modalTabsCopy = [];
    if (incomplete.length) {
      modalTabsCopy.push({
        text: t('onboarding:incomplete'),
        id: 'incomplete',
      });
    }
    if (complete.length) {
      modalTabsCopy.push({ text: t('onboarding:complete'), id: 'complete' });
    }
    setModalTabs(modalTabsCopy);
    setModalTab(modalTabsCopy[0]?.id);
  }, [incomplete, complete]);

  return (
    <>
      <Modal isOpen={isOpen} title={t('onboarding:title')} onClose={onClose}>
        <Modal.Content>
          <div className='mb-20'>
            <Tabs
              items={modalTabs}
              activeItem={modalTab}
              onItemClick={id => setModalTab(id)}
            />
          </div>
          {modalTab === 'incomplete' && incomplete?.length !== 0 && (
            <List
              noBorder
              items={incomplete.map(({ id, ...rest }, index) => ({
                id,
                columns: getListItems(id, rest),
              }))}
            />
          )}
          {modalTab === 'complete' && complete?.length !== 0 && (
            <List
              noBorder
              items={complete.map(({ id, ...rest }, index) => ({
                id,
                columns: getListItems(id, rest),
              }))}
            />
          )}
        </Modal.Content>
      </Modal>
    </>
  );
};

export default OfficerDashboard;
