/* eslint-disable import/prefer-default-export */
import i18n from '@tg/core/localisation/i18n';
import { formatGrossSalary } from './stringHelpers';

export const formatNumber = (
  number: string | number,
  subtype?: string,
): string => {
  const locale = i18n.language;
  const n =
    subtype === 'grossSalary'
      ? formatGrossSalary(String(number))
      : Number(number);

  return n.toLocaleString(locale);
};
