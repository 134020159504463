import { transformCollectionResponse } from '@tg/core/utils/dataTransformers';
import { actions as bootstrapActions } from '@tg/core/store/modules/bootstrap';
import actions from './actions';

const initialState = {
  domiciles: null,
  dialcodes: null,
  address_countries: null,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case bootstrapActions.BOOTSTRAP_APPLICATION_SUCCESS: {
      const {
        data: { collections },
      } = payload;
      return {
        ...state,
        ...Object.keys(collections).reduce((acc, key) => {
          acc[key] = transformCollectionResponse(collections[key]);
          return acc;
        }, {}),
      };
    }

    case actions.FETCH_COLLECTION_SUCCESS: {
      const { resource, data } = payload;
      return {
        ...state,
        [resource]: transformCollectionResponse(data),
      };
    }

    default:
      return state;
  }
};

export default reducer;
