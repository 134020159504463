import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Segment,
  Modal,
  Avatar,
  Button,
  List,
  LoadingSpinner,
} from '@tg/core/components';
import { capitalise } from '@tg/core/utils/stringHelpers';
import { formatDateRange } from '@tg/core/utils/datetimeHelpers';
import { Planner, PlannerEvent, CalendarData, User } from '../../types';
import ApproveRejectEvent from './ApproveRejectEvent';

interface PendingEvent {
  event: PlannerEvent;
  user: User;
  contractId: Planner['contract_id'];
}

interface Props {
  onUpdate: (p: Planner) => void;
  data: CalendarData;
  loading: boolean;
}
const PendingEventList: FC<Props> = ({ data, onUpdate, loading }) => {
  const { t } = useTranslation(['time_off']);

  // Get the Pending events from data
  const [pendingEvents, setPendingEvents] = useState([] as PendingEvent[]);
  useEffect(() => {
    if (data) {
      const newEvents = [] as PendingEvent[];
      Object.keys(data).forEach(contract_id => {
        const allEvents = data[contract_id].events;
        const userPendingEvents = allEvents?.filter(
          ({ status }) =>
            status === 'pending' || status === 'cancellation_pending',
        );
        userPendingEvents.forEach(event => {
          newEvents.push({
            contractId: contract_id,
            user: data[contract_id].user,
            event,
          });
        });
      });
      setPendingEvents(newEvents);
    }
  }, [data]);

  const [modalOpen, setModalOpen] = useState(false);

  /**
   * Returns either all the list items for the List component or a subset of them
   * @param {*} count
   */
  const getListItems = (count?: number) => {
    const listItems = count ? pendingEvents.slice(0, count) : pendingEvents;
    return listItems.map(({ contractId, event, user }) => ({
      id: event.id,
      columns: [
        <Avatar
          size='small'
          user={user}
          subHeading={`${capitalise(event.event_type)} | ${formatDateRange({
            start_date: event.start_date,
            end_date: event.end_date,
          })}`}
        />,
        <div className='flex justify-end'>
          <div
            className={`text-sm pt-2 mr-4 ${
              event.status === 'cancellation_pending'
                ? 'text-red'
                : 'text-gray-500'
            }`}
          >
            {t(`time_off:pending_status.${event.status}`)}
          </div>
          <ApproveRejectEvent
            onUpdate={onUpdate}
            event={event}
            year={event.planYear}
            contractId={contractId}
          />
        </div>,
      ],
    }));
  };
  const listHeadings = [{ text: '' }, { text: '', width: 'auto' }];

  return (
    <Segment heading={t('time_off:approvals.pending_requests')}>
      <div style={{ minHeight: '236px' }} className='flex'>
        {loading && (
          <div className='w-full flex items-center justify-center'>
            <LoadingSpinner />
          </div>
        )}

        {!loading && pendingEvents.length === 0 && (
          <div className='w-full flex items-center justify-center'>
            <span className='font-medium text-gray-600'>
              You&apos;re up to date! There are no pending events.
            </span>
          </div>
        )}

        {pendingEvents.length > 0 && (
          <div className='w-full'>
            <div className='mb-4' data-cy='pending-events-list'>
              <List noBorder headings={listHeadings} items={getListItems(3)} />
            </div>
            {pendingEvents.length > 3 && (
              <>
                <Button
                  type='button'
                  onClick={() => setModalOpen(true)}
                  color='tertiary'
                  fluid
                >
                  {t('time_off:approvals.view_all')}
                </Button>
              </>
            )}
          </div>
        )}
      </div>
      <Modal
        title={t('time_off:approvals.pending_requests')}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Modal.Content>
          <List noBorder headings={listHeadings} items={getListItems()} />
        </Modal.Content>
      </Modal>
    </Segment>
  );
};

export default PendingEventList;
