import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useResource, useWindowSize } from '@tg/core/hooks';
import { useSelector } from 'react-redux';
import { getPermission } from '@tg/core/utils/permisssionHelpers';
import permissions from '@tg/core/constant';
import AccessDenied from '@tg/core/components/organisms/AccessDenied';
import ContractsList from './ContractsList';
import CreateContract from './CreateContract';
import ShowContract from './ShowContract';
import LandingPage from './LandingPage';

const Contracts = () => {
  const [setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [countryFilter, setCountryFilter] = useState([]);
  const [options, setOptions] = useState([
    { key: 'eor', value: 'eor', text: 'EOR', checked: true },
    { key: 'payroll_only', value: 'po', text: 'Payroll Only', checked: true },
    { key: 'contractor', value: 'cnt', text: 'Contractor', checked: true },
  ]);

  const accesslist = useSelector(state => state.access.roles);

  // NOTE: would useEffect help here?
  let url = `employers/contracts?pagination=false`;
  if (searchTerm) {
    url += `&with_employee_name=${searchTerm}`;
  }
  if (countryFilter.length) {
    url += `&with_employee_domicile[]=${countryFilter}`;
  }
  options.forEach(option => {
    url += `&${option.key}=${option.checked ? 'true' : 'false'}`;
  });

  const {
    data,
    pagination,
    getResource: getContractsList,
    isFetching,
  } = useResource({ url }, true);

  const { employeeId } = useParams();

  // A check to ensure we never show the 'add your first employee' message when they arleady have one or more
  // Can happen when searching returns no results
  const [hasEmployees, setHasEmployees] = useState(undefined);
  useEffect(() => {
    // As soon as we can be sure we have employees, set it
    if (data && data.length) {
      setHasEmployees(true);
    }
  }, [data]);

  const listProps = {
    data: data || [],
    searchTerm,
    onSearchTermChange: val => setSearchTerm(val),
    countryFilter,
    onCountryFilterChange: val => setCountryFilter(val),
    options,
    onOptionFilterChange: val => setOptions(val),
    pagination,
    onPaginationChange: setPage,
    isFetching,
  };

  /**
   * On larger screens we show the employees list along side the employee detail
   * On smaller screens, the list is a different 'page' to the details
   */
  const windowSize = useWindowSize();
  const seperatePages = windowSize.width < 990;

  if (seperatePages) {
    const renderViewSeperate = () => {
      if (employeeId === 'add') {
        return <CreateContract onSuccess={getContractsList} />;
      }

      if (employeeId) {
        return <ShowContract employeeId={employeeId} showBreadcrumb />;
      }
      return <ContractsList {...listProps} />;
    };

    return <div className='px-5 pb-10'>{renderViewSeperate()}</div>;
  }

  const renderView = () => {
    if (employeeId === 'add') {
      if (!getPermission(accesslist, permissions.EMPLOYEES_ADD_EMPLOYEE)) {
        return <AccessDenied />;
      }
      return <CreateContract onSuccess={getContractsList} />;
    }
    if (employeeId) {
      return <ShowContract employeeId={employeeId} />;
    }
    if (hasEmployees !== true && hasEmployees !== undefined) {
      return <LandingPage empty />;
    }
    return <LandingPage empty={false} />;
  };

  return (
    <div className='Contracts flex w-full max-w-screen-3xl mx-auto'>
      <div className='Contracts__list flex-shrink-0'>
        <div className='fixed md:pt-10 xl:pt-18 px-5 pb-5 overflow-y-scroll overflow-x-hidden'>
          <ContractsList {...listProps} />
        </div>
      </div>

      <div
        className='p-12 bg-white flex-grow'
        style={{
          marginTop: '-70px',
          minWidth: '620px',
        }}
      >
        {renderView()}
      </div>
    </div>
  );
};

export default Contracts;
