import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useResource } from '@tg/core/hooks';
import { ContractDocumentsList } from '@tg/core/components';

const ContractDocuments = ({ contractId }) => {
  const [page, setPage] = useState(1);

  const { data, pagination } = useResource(
    {
      url: `employers/contracts/${contractId}/documents?page=${page}&pagesize=10`,
    },
    true,
  );

  return data ? (
    <ContractDocumentsList
      collection={data}
      setPage={setPage}
      pagination={pagination}
    />
  ) : null;
};

ContractDocuments.propTypes = {
  contractId: PropTypes.string.isRequired,
};

export default ContractDocuments;
