import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Icon, Avatar, Tooltip } from '@tg/core/components';
import { capitalise } from '@tg/core/utils/stringHelpers';
import {
  CalendarData,
  CalendarUser,
  PlannerEvent,
  Planner,
  EventBar,
} from '../../types';

import {
  createEventBarsForRow,
  createEventBarsForGrid,
} from './createEventBars';

interface EventsByMonthByUser {
  eventsByMonth: {
    [key: number]: EventBar[];
  };
  contract_id: CalendarUser['contract_id'];
}

interface CalendarEventsProps {
  data: CalendarData;
  month: number;
  year: number;
  layout: 'row' | 'grid';
  plan_year: number;
  onEventClick: ({
    event,
    contractId,
    year,
  }: {
    event: PlannerEvent;
    contractId: Planner['contract_id'];
    year: Planner['year'];
  }) => void;
}

const CalendarEvents: FC<CalendarEventsProps> = ({
  data,
  year,
  month,
  layout,
  onEventClick,
  plan_year,
}) => {
  const { t } = useTranslation(['time_off', 'dateTime']);

  const [transformedData, setTransformedDate] = useState(
    [] as EventsByMonthByUser[],
  );
  useEffect(() => {
    // Loops though eash USER in DATA
    const newData = Object.keys(data).map(contract_id => {
      const { events } = data[contract_id];
      const eventsByMonth =
        layout === 'grid'
          ? createEventBarsForGrid(events, year)
          : createEventBarsForRow(events, year);

      return {
        contract_id,
        eventsByMonth,
      };
    });

    setTransformedDate(newData);
  }, [data, layout, year]);

  /**
   * Sorts by start date, required for accessible dom order and for mobile view
   */
  const sortEventsByDate = (events: EventBar[]): EventBar[] => {
    function compareStartDate(a: EventBar, b: EventBar) {
      if (a.event.start_date < b.event.start_date) {
        return -1;
      }
      if (a.event.start_date > b.event.start_date) {
        return 1;
      }
      return 0;
    }

    return events.sort(compareStartDate);
  };

  const showText = ({
    calendarDays,
    halfDays,
  }: {
    calendarDays: EventBar['calendarDays'];
    halfDays: EventBar['halfDays'];
  }) => {
    if (layout === 'row') {
      if (calendarDays > 2) return true;
      return false;
    }
    if (halfDays.start || halfDays.end) {
      if (calendarDays > 2) return true;
      return false;
    }
    return true;
  };

  return (
    <>
      {transformedData?.length &&
        transformedData.map(({ eventsByMonth, contract_id }, employeeIndex) => (
          <React.Fragment key={contract_id}>
            {eventsByMonth &&
              eventsByMonth[month] &&
              eventsByMonth[month].length > 0 && (
                <>
                  {layout === 'row' && (
                    <div className='md:hidden my-4'>
                      <Avatar user={data[contract_id].user} />
                    </div>
                  )}
                  {sortEventsByDate(eventsByMonth[month]).map(
                    ({
                      startCol,
                      row,
                      calendarDays,
                      event,
                      stringDescription,
                      rounded,
                      halfDays,
                    }) => {
                      const yr =
                        plan_year && plan_year !== undefined ? plan_year : year;
                      return (
                        <div
                          key={`${contract_id}-${startCol}-${calendarDays}-${row}`}
                          style={
                            layout === 'grid'
                              ? {
                                  gridColumn: `${startCol} / span ${calendarDays}`,
                                  gridRow: `${row + 1} / ${row + 1} `,
                                }
                              : {
                                  gridColumn: `${
                                    (startCol as number) + 1
                                  } / span ${calendarDays}`,
                                  gridRowStart: employeeIndex + 2,
                                }
                          }
                          className={classNames(
                            'h-10 relative self-center mb-4 md:mb-0',
                            {
                              'md:px-0.5': rounded === 'all',
                              'md:pr-0.5': rounded === 'right' && !halfDays.end,
                              'md:pl-0.5':
                                rounded === 'left' && !halfDays.start,
                              'md:pl-6': halfDays.start && layout === 'row',
                              'md:pr-6': halfDays.end && layout === 'row',
                              'md:pl-16': halfDays.start && layout === 'grid',
                              'md:pr-16': halfDays.end && layout === 'grid',
                            },
                          )}
                        >
                          <Tooltip
                            content={
                              <div className='leading-1 text-sm'>
                                <div className='font-semibold'>
                                  {capitalise(event.event_type)}
                                </div>

                                <div>
                                  {stringDescription}
                                  {' | '}
                                  {t(`time_off:event_status.${event.status}`)}
                                </div>
                              </div>
                            }
                          >
                            <button
                              type='button'
                              onClick={() =>
                                onEventClick({
                                  event,
                                  contractId:
                                    data[contract_id].user.contract_id ||
                                    contract_id,
                                  year: event.planYear,
                                })
                              }
                              className={classNames(
                                'h-full w-full p-2 flex items-center leading-none hover:bg-opacity-90',
                                {
                                  rounded: rounded === 'all',
                                  'rounded-r': rounded === 'right',
                                  'rounded-l': rounded === 'left',
                                  'md:mt-2': layout === 'grid', // Clear the 'today' marker
                                  'striped-background':
                                    event.status === 'pending',
                                  'bg-opacity-20': event.status === 'rejected',
                                  'text-white': event.event_type !== 'sickday',
                                  'text-gray-700':
                                    event.event_type === 'sickday',
                                },
                              )}
                              style={{ backgroundColor: event.colour_code }}
                              aria-label={stringDescription}
                              data-cy='calendar-event-bar'
                            >
                              <div className='flex items-center w-full'>
                                <span
                                  className={classNames(
                                    'font-semibold text-sm',
                                    {
                                      'md:hidden': !showText({
                                        calendarDays,
                                        halfDays,
                                      }),
                                    },
                                  )}
                                >
                                  {capitalise(event.event_type)}
                                </span>
                                <span className='ml-auto flex items-center'>
                                  {event.status === 'approved' && (
                                    <Icon
                                      name='check circle outline'
                                      size='sm'
                                    />
                                  )}
                                  {event.status === 'pending' && (
                                    <Icon name='clock outline' size='sm' />
                                  )}
                                  {event.status === 'rejected' && (
                                    <Icon
                                      name='times circle outline'
                                      size='sm'
                                    />
                                  )}
                                </span>
                              </div>
                            </button>
                          </Tooltip>
                        </div>
                      );
                    },
                  )}
                </>
              )}
          </React.Fragment>
        ))}
    </>
  );
};

export default CalendarEvents;
