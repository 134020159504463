import React from 'react';
import { Heading } from '@tg/core/components';
import PropTypes from 'prop-types';

/**
 * A page title and description. Maintains styling across all pages
 */
const PageHeader = ({ heading, subHeading, description, action: Action }) => {
  return (
    <header className='mb-10'>
      <div className='mb-1 flex justify-between'>
        <Heading level='h3' element='h1' zeropad>
          {heading}
        </Heading>
        {Action}
      </div>
      {subHeading && <div className='text-gray-500 mb-4'>{subHeading}</div>}
      {description && <p>{description}</p>}
    </header>
  );
};

PageHeader.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string,
  description: PropTypes.string,
  action: PropTypes.node,
};

PageHeader.defaultProps = {
  subHeading: null,
  description: null,
  action: null,
};

export default PageHeader;
