import React, { FC, useCallback, useEffect, useState } from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import gb from 'date-fns/locale/en-GB';
import {
  Switch,
  Route,
  BrowserRouter as Router,
  useLocation,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  NotFound,
  ErrorBoundary,
  Context,
  Header,
  PrivateRoute,
} from '@tg/core/components';
import i18n from '@tg/core/localisation/i18n';
import {
  selectSessionAuthToken,
  selectSessionUser,
} from '@tg/core/store/modules/session';
import { useAppSelector } from '@tg/core/hooks';
import * as theme from '@tg/core/styles/theme';
import { useSelector } from 'react-redux';
import AccessDenied from '@tg/core/components/organisms/AccessDenied/AccessDenied';
import type { SessionUser } from 'src/types';
import Auth from '../Routes/Auth';
import OfficerRoutes from '../Routes/Officer';
import EmployeeRoutes from '../Routes/Employee';
import getRoutes from './routes';

// Date picker Locale
registerLocale('en-GB', gb);
setDefaultLocale('en-GB');

// Send a Google Analytics event on router / page change
function usePageViews() {
  const location = useLocation();
  useEffect(() => {
    if (typeof window.gtag !== 'function') return;
    window.gtag('event', 'page_view', {
      page_title: document.title,
      page_location: window.location.href,
      page_path: location.pathname,
      send_to: 'G-KFTX08M5PS',
    });
  }, [location]);
}

interface Props {
  isLoggedIn: boolean;
  user: SessionUser;
}

const Routes: FC<Props> = ({ isLoggedIn, user }) => {
  usePageViews();

  const RoleRoutes: FC<{ role: string }> = ({ role }) => {
    switch (role) {
      case 'officer':
        return <OfficerRoutes />;
      case 'employee':
        return <EmployeeRoutes />;
      default:
        return <div>Error Loading User</div>;
    }
  };

  return (
    <Switch>
      <Route path='/auth'>
        <Auth isLoggedIn={isLoggedIn} />
      </Route>
      <Route path='/not-found'>
        <NotFound />
      </Route>
      <Route path='/access-denied'>
        <AccessDenied />
      </Route>
      <PrivateRoute
        component={useCallback(
          () => (
            <RoleRoutes role={user.role} />
          ),
          [user],
        )}
      />
    </Switch>
  );
};

const App: FC = () => {
  const ready = useAppSelector(state => state.app.bootstrap);
  const sessionUser = useAppSelector(state => selectSessionUser(state));
  const isLoggedIn = !!useAppSelector(state => selectSessionAuthToken(state));
  const accesslist = useSelector(state => state.access.roles);

  /**
   * Set App Language
   */
  // If a user hasn't chosen a language, it will have been set based on their
  // domicile when the account was created
  const defaultLanguage = sessionUser.default_language;
  const userPreferences = sessionUser.preferences;
  useEffect(() => {
    // Set the users language to that set in thier preferences.
    // In most instances this wont do anything as the correct lang has already
    // been set via localStorage. See `detection` in localisation/i18n.js
    if (userPreferences?.user_language || defaultLanguage) {
      i18n.changeLanguage(userPreferences?.user_language || defaultLanguage);
    }
  }, [defaultLanguage, userPreferences]);

  /**
   * Update main nav links when the app language changes
   * It's a shame this isn't automagical...
   */
  const [navLinks, setNavLinks] = useState([]);
  i18n.on('languageChanged', () => {
    setNavLinks(getRoutes(sessionUser.role, accesslist));
  });
  useEffect(() => {
    setNavLinks(getRoutes(sessionUser.role, accesslist));
  }, [sessionUser, accesslist]);

  /**
   * Show main nav links only when the user is logged in
   */
  const [showLinks, setShowLinks] = useState(false);
  useEffect(() => {
    if (!isLoggedIn) {
      return setShowLinks(false);
    }
    // Hide links when entering profile or company info for the first time
    let welcomeComplete = sessionUser.welcome_completed;
    if (
      ready &&
      sessionUser.company &&
      !sessionUser.company.welcome_completed
    ) {
      welcomeComplete = false;
    }
    return setShowLinks(welcomeComplete);
  }, [ready, sessionUser, isLoggedIn]);

  return (
    <Context.Provider value={{ theme }}>
      <ErrorBoundary>
        <Helmet defaultTitle='Teamed' titleTemplate='%s | Teamed'>
          <html lang={i18n.language} />
        </Helmet>
        <Router>
          <Header showLinks={showLinks} links={navLinks} fixed={isLoggedIn} />
          <main className='pb-10'>
            {ready && <Routes isLoggedIn={isLoggedIn} user={sessionUser} />}
          </main>
        </Router>
      </ErrorBoundary>
    </Context.Provider>
  );
};

export default App;
