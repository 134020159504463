import React, { FC, useState } from 'react';
import {
  List,
  Status,
  PageHeader,
  Button,
  PaginationControl,
} from '@tg/core/components';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useResource } from '@tg/core/hooks';
import EmailRecipientsModal from '../components/EmailRecipientsModal';

interface Sheet {
  id: string;
  month: number;
  year: number;
  submitted_at: string | null;
  locked_at: string | null;
}

const OfficerPayrollList: FC = () => {
  const { t } = useTranslation(['page_titles', 'dateTime', 'payroll']);

  const [page, setPage] = useState(1);
  const { data, isFetching, pagination } = useResource<Sheet[]>(
    {
      url: `employers/payroll_adjustments?page=${page}`,
    },
    true,
  );

  const [emailModalOpen, setEmailModalOpen] = useState(false);

  const getStatus = ({ locked_at, submitted_at }) => {
    let status = {
      color: 'orange',
      text: 'Requires action',
    };

    // Payroll run is locked / finished, doesn't always mean the employer entered values
    if (locked_at) {
      status = {
        color: 'gray',
        text: 'Closed',
      };
    }

    // User submitted it with values
    if (submitted_at) {
      status = {
        color: 'green',
        text: 'Submitted',
      };
    }

    if (submitted_at && locked_at) {
      status = {
        color: 'green',
        text: 'Complete',
      };
    }
    return status;
  };

  return (
    <>
      <Helmet title={t('page_titles:payroll')} />
      <PageHeader
        heading={t('page_titles:payroll')}
        action={
          <Button
            size='small'
            color='secondary'
            onClick={() => setEmailModalOpen(true)}
            type='button'
          >
            {t('payroll:email_recipients.modal.title')}
          </Button>
        }
      />
      <EmailRecipientsModal
        isOpen={emailModalOpen}
        onClose={() => setEmailModalOpen(false)}
      />
      {/* payroll_notice_recipients */}
      {data && !!data.length && (
        <List
          headings={[{ text: 'Date' }, { text: 'Status', width: 'auto' }]}
          items={data.map(({ id, month, year, submitted_at, locked_at }) => ({
            id,
            to: `/payroll/${id}`,
            columns: [
              `${t(`dateTime:months.${month}.full`)} ${year}`,
              <Status {...getStatus({ locked_at, submitted_at })} />,
            ],
          }))}
        />
      )}
      {data && !data.length && !isFetching && (
        <div className='text-center mt-12'>
          No Payroll Inputs found. Please contact Teamed.
        </div>
      )}
      <div>
        {pagination && (
          <PaginationControl {...pagination} onPageChange={setPage} />
        )}
      </div>
    </>
  );
};

export default OfficerPayrollList;
