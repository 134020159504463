import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Heading } from '@tg/core/components';
import TextField from '../../Fields/TextField';
import PhoneField from '../../Fields/PhoneField';

const BankDetails = ({ getFieldProps, control, defaultValues }) => {
  const { t } = useTranslation(['forms']);

  const { name, phone } = defaultValues || {};

  return (
    <fieldset>
      <Heading element='legend' level='h4'>
        {t('forms:labels.emergency_contact.heading')}
      </Heading>

      <div className='grid gap-4 grid-cols-2'>
        <TextField
          {...getFieldProps({ id: 'emergency_contact', name: 'name' })}
          defaultValue={name}
          required
        />
        <PhoneField
          {...getFieldProps({ id: 'emergency_contact', name: 'phone' })}
          control={control}
          defaultValue={phone}
          required
        />
      </div>
    </fieldset>
  );
};

BankDetails.propTypes = {
  getFieldProps: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  defaultValues: PropTypes.shape({
    phone: PropTypes.shape({
      code: PropTypes.string,
      phone: PropTypes.string,
    }),
    name: PropTypes.string,
  }).isRequired,
};

BankDetails.defaultProps = {};

export default BankDetails;
