import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Heading,
  DisplayDetails,
  ButtonBar,
  Button,
  StaticAssetActions,
  List,
} from '@tg/core/components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectSessionAuthToken } from '@tg/core/store/modules/session';
import { capitalise } from '@tg/core/utils/stringHelpers';
import { formatDateRange } from '@tg/core/utils/datetimeHelpers';
import { isBefore } from 'date-fns';
import { callCancelRequest } from './eventDetailsHelper';

const EventDetailsModal = ({
  event,
  onClose,
  onEditClick,
  allowEditing,
  approveRejectButton,
  contractId,
  onSuccess,
}) => {
  const { t } = useTranslation(['time_off', 'forms']);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const token = useSelector(state => selectSessionAuthToken(state));

  const iff = (condition, then, otherwise) => (condition ? then : otherwise);

  const eventDetails = event
    ? [
        {
          title: t('time_off:event_details.status'),
          value: t(`time_off:event_status.${event.status}`),
          width: '25%',
        },
        {
          title: t('time_off:event_details.entitlement_used'),
          value: t(`time_off:days`, {
            count: iff(
              event.event_type === 'unpaid',
              0,
              iff(
                event?.entitlement_used,
                event.entitlement_used[event.event_type],
                '-',
              ),
            ),
          }),
          width: '25%',
        },
      ]
    : [];

  if (event) {
    const { start_perc, end_perc, status, rejection_note, note, documents } =
      event;
    const docList = documents?.length ? (
      <List
        items={documents.map(f => {
          const columnObj = [<div className='break-words'>{f.filename}</div>];
          if (f.url) {
            columnObj.push(<StaticAssetActions url={f.url} />);
          }
          return {
            id: f.file_id,
            name: f.filename,
            columns: columnObj,
          };
        })}
        type='ofcPopup'
      />
    ) : (
      []
    );
    if (start_perc === 50) {
      eventDetails.push({
        title: t('time_off:event_details.start_half'),
        value: 'Yes',
        width: '50%',
      });
    }
    if (end_perc === 50) {
      eventDetails.push({
        title: t('time_off:event_details.end_half'),
        value: 'Yes',
        width: '50%',
      });
    }
    if (status === 'rejected') {
      eventDetails.push({
        title: t('time_off:event_details.rejection_note'),
        value: rejection_note || '-',
        width: '100%',
      });
    }
    if (note) {
      eventDetails.push({
        title: t('time_off:event_details.note'),
        value: note || '-',
        width: '100%',
      });
    }
    if (documents?.length) {
      eventDetails.push({
        title: t('time_off:event_details.documents'),
        value: docList || '-',
        width: '100%',
      });
    }
  }
  const onClosePopup = () => {
    onClose();
    setShowConfirmationPopup(false);
  };

  const sendRequestCancellation = async () => {
    setIsFetching(true);
    const res = await callCancelRequest(
      `employees/contracts/${contractId}/planner/${event.planYear}/${event?.id}/cancellation_pending`,
      token,
    );

    if (res.statusText === 'Unauthorized') {
      onClosePopup();
      setIsFetching(false);
    } else {
      onSuccess(res.data.data);
      onClosePopup();
      setIsFetching(false);
    }
  };

  const openConfirmationPopup = val => {
    setShowConfirmationPopup(val);
  };
  const eventIsInPast =
    event &&
    isBefore(
      new Date(event.start_date).setHours(0, 0, 0, 0),
      new Date().setHours(0, 0, 0, 0),
    );

  const eventIsLocked = event && event.status === 'locked';

  return (
    <>
      <Modal isOpen={!!event} onClose={onClosePopup} width='xs'>
        {!showConfirmationPopup ? (
          <>
            <div className='flex px-6'>
              <div className='mr-4'>
                {event && (
                  <Heading level='h4' zeropad>
                    {capitalise(event.event_type)}
                  </Heading>
                )}
                <p>
                  {event &&
                    formatDateRange({
                      start_date: event.start_date,
                      end_date: event.end_date,
                    })}
                </p>
              </div>
              {typeof approveRejectButton === 'function' && (
                <div className='ml-auto'>{approveRejectButton(onClose)}</div>
              )}
            </div>
            <Modal.Content>
              <DisplayDetails details={eventDetails} />
            </Modal.Content>
            <ButtonBar>
              {allowEditing &&
                !eventIsInPast &&
                !eventIsLocked &&
                event?.status === 'pending' && (
                  <div className='mr-2'>
                    <Button
                      type='button'
                      color='secondary'
                      onClick={() => {
                        onEditClick();
                        onClose();
                      }}
                    >
                      {t('time_off:event_details.edit_button')}
                    </Button>
                  </div>
                )}
              {allowEditing && !eventIsInPast && event?.status === 'approved' && (
                <div className='mr-2'>
                  <Button
                    color='primary'
                    onClick={() => openConfirmationPopup(true)}
                  >
                    {t('time_off:event_details.cancel_request')}
                  </Button>
                </div>
              )}
              <Button type='button' onClick={onClosePopup} color='secondary'>
                {t('forms:buttons.modal.close')}
              </Button>
            </ButtonBar>
          </>
        ) : (
          <>
            <div className='flex px-6'>
              <div className='mr-4'>
                <Heading level='h4' zeropad>
                  {t('time_off:confirm_popup.heading')}
                </Heading>
                <Modal.Content>
                  <p>{t('time_off:confirm_popup.confirmation_question')}</p>
                </Modal.Content>
              </div>
            </div>
            <ButtonBar>
              <div className='mr-2'>
                <Button
                  type='button'
                  color='primary'
                  loading={isFetching}
                  onClick={e => sendRequestCancellation(e)}
                >
                  {t('forms:buttons.modal.yes')}
                </Button>
              </div>
              <Button
                type='button'
                color='secondary'
                onClick={() => openConfirmationPopup(false)}
              >
                {t('forms:buttons.modal.no')}
              </Button>
            </ButtonBar>
          </>
        )}
      </Modal>
    </>
  );
};

EventDetailsModal.propTypes = {
  event: PropTypes.shape({
    event_type: PropTypes.string,
    status: PropTypes.oneOf(['pending', 'approved', 'rejected']),
    entitlement_used: PropTypes.shape({
      holiday: PropTypes.number,
      sickday: PropTypes.number,
    }),
    start_date: PropTypes.string,
    start_perc: PropTypes.number,
    end_date: PropTypes.string,
    end_perc: PropTypes.number,
    id: PropTypes.string,
    planYear: PropTypes.number,
    year: PropTypes.number,
    rejection_note: PropTypes.string,
    note: PropTypes.string,
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        file_id: PropTypes.string,
        filename: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
  }),
  onClose: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  allowEditing: PropTypes.bool,
  approveRejectButton: PropTypes.func,
  contractId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

EventDetailsModal.defaultProps = {
  event: null,
  allowEditing: false,
  approveRejectButton: null,
};

export default EventDetailsModal;
