import { date, object, string } from 'yup';

export const salary = object().shape({
  currency_id: string().nullable().required(),
  gross_salary: string().test(
    'is-number',
    { key: 'number_required' },
    value => {
      if (
        typeof value === 'string' &&
        /^[+-]?([0-9]*[.])?[0-9]+$/.test(value)
      ) {
        return true;
      }
      return false;
    },
  ),
  effective_date: date().nullable().required(),
});

export const jobTitle = object().shape({
  job_title: string().required(),
  effective_date: date().nullable().required(),
});
