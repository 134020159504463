import React, { FC, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { ConfirmModal } from '@tg/core/components';
import { useTranslation } from 'react-i18next';

interface Props {
  when?: boolean | undefined;
  navigate: (path: string) => void;
  shouldBlockNavigation: boolean;
}

/**
 * Wraps React Routers <Prompt /> in our custom modal
 * See https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
 */
const RouteLeavingGuard: FC<Props> = ({
  when,
  navigate,
  shouldBlockNavigation,
}) => {
  const { t } = useTranslation(['confirm_navigation']);

  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />

      <ConfirmModal
        isOpen={modalVisible}
        onConfirm={handleConfirmNavigationClick}
        onClose={() => setModalVisible(false)}
      >
        <div className='-mt-4 block'>{t('confirm_navigation:description')}</div>
      </ConfirmModal>
    </>
  );
};
export default RouteLeavingGuard;
