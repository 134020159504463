import React, { FC } from 'react';
import classNames from 'classnames';

const fontStyles = {
  h1: 'text-7xl font-bold',
  h2: 'text-4xl font-bold',
  h3: 'text-2xl font-bold',
  h4: 'text-xl font-semibold',
  h5: 'text-base font-semibold',
  h6: 'text-sm font-semibold',
};

interface Props {
  level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  element?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'div' | 'span' | 'legend';
  centered?: boolean;
  zeropad?: boolean;
  inheritColor?: boolean;
}

const Heading: FC<Props> = ({
  level = 'h1',
  element: Element = 'div',
  centered,
  children,
  zeropad,
  inheritColor,
}) => {
  return (
    <Element
      className={classNames('leading-none m-0', fontStyles[level], {
        'mb-4': !zeropad,
        'text-center': centered,
        'text-current': inheritColor,
        'text-gray-800': !inheritColor,
      })}
    >
      {children}
    </Element>
  );
};

export default Heading;
